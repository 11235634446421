import { Table, TablePaginationConfig } from 'antd';
export interface TableColData {
	title: string;
	dataIndex?: string;
	width?: number;
	className?: string;
	render?: any;
	key?: string;
	pagination?: TablePaginationConfig;
}

const InfoTable = <T extends { key: string }>(props: {
	data: T[];
	columnData: any[];
	onChange: any;
	other?: any;
	expandable?: any;
	pagination?: TablePaginationConfig;
}): JSX.Element => {
	return (
		<>
			<Table<T[]>
				dataSource={props.data}
				columns={props.columnData}
				onChange={props.onChange}
				tableLayout={'fixed'}
				pagination={{
					showSizeChanger: true,
					showTotal: (total, range) =>
						`${range[0]}-${range[1]} of ${total} items`,
					...props.pagination,
					pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
				}}
				expandable={props.expandable}
				{...props.other}
			/>
		</>
	);
};
export default InfoTable;
