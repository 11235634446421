import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import PopupAdCard from 'src/components/ui/popup-ad/popup-ad-card';
import { ROUTES } from 'src/config/route';
import { PopupAdForm } from 'src/lib/form-interfaces';
import {
	CmsCreatePopupAdMutation,
	CmsCreatePopupAdMutationVariables,
} from 'src/lib/generated/_generated';

import { MutationCreatePopupAd } from 'src/lib/gql/mutations';

const CreatePopupAd = () => {
	const navigate = useNavigate();

	const [CreatePopupAd] = useMutation<
		CmsCreatePopupAdMutation,
		CmsCreatePopupAdMutationVariables
	>(MutationCreatePopupAd, {
		onError(error) {
			CustomNotification({
				pageName: 'Popup Ad',
				pagePrefix: 'PopupAd',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const createQry = useCallback(async (data: PopupAdForm) => {
		const rsp = await CreatePopupAd({
			variables: {
				dates: data.dates.map((date) => format(date, 'yyyy-MM-dd')),
				name: data.name,
				mediaId: data.mediaId,
				btnText: data.btnText,
				mediaLink: data.mediaLink,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Popup Ad',
				pagePrefix: 'PopupAd',
				notificationType: 'created',
			});

			navigate(`${ROUTES.EDIT_POPUP_AD.path}/${rsp.data.createPopupAd?.id}`);
		}
	}, []);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create Popup Ad</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				<Col span={24} lg={12}>
					{/* <MediaUpload /> */}
				</Col>

				<PopupAdCard onSave={createQry} />
			</Row>
		</Row>
	);
};

export default CreatePopupAd;
