import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Select, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';

import {
	CreateEventPhotographers,
	DeleteEventPhotographerId,
} from 'src/lib/gql/mutations';
import {
	QueryGetEventPhotographers,
	QueryGetPhotographers,
} from 'src/lib/gql/queries';
import { EventPhotographersTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import {
	CmsCreateEventPhotographersMutationMutation,
	CmsCreateEventPhotographersMutationMutationVariables,
	CmsDeleteEventPhotographerIdMutationMutation,
	CmsDeleteEventPhotographerIdMutationMutationVariables,
	CmsGetPaginatedPhotographerEventsQuery,
	CmsGetPaginatedPhotographerEventsQueryVariables,
	CmsGetPhotographersQuery,
} from 'src/lib/generated/_generated';

const PhotographerEventSelfAssign = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [eventName, setEventName] = useState('');
	const [eventVenueName, setEventVenueName] = useState<string>('');

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<
		CmsGetPaginatedPhotographerEventsQuery,
		CmsGetPaginatedPhotographerEventsQueryVariables
	>(QueryGetEventPhotographers, {
		fetchPolicy: 'cache-and-network',
		variables: {
			pageNumber: pageNumber,
			perPage: perPage,
			eventName: eventName,
			venueName: eventVenueName,
		},
	});

	const { data: qryPhotographers } = useQuery<CmsGetPhotographersQuery>(
		QueryGetPhotographers,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	const [createEventPhotographers] = useMutation<
		CmsCreateEventPhotographersMutationMutation,
		CmsCreateEventPhotographersMutationMutationVariables
	>(CreateEventPhotographers, {
		onError(error) {
			CustomNotification({
				pageName: 'Photographer',
				pagePrefix: 'Photographer',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [deleteEventPhotographerId] = useMutation<
		CmsDeleteEventPhotographerIdMutationMutation,
		CmsDeleteEventPhotographerIdMutationMutationVariables
	>(DeleteEventPhotographerId, {
		onError(error) {
			CustomNotification({
				pageName: 'Photographer',
				pagePrefix: 'Photographer',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const p_EventPhotographers: EventPhotographersTable[] = [];

	const deleteEventPhotographerIdFn = useCallback(
		async (photographerId: string, eventDateId: string) => {
			if (!confirm('Are you sure you want to delete?')) return;

			const rsp = await deleteEventPhotographerId({
				variables: {
					eventDateId: eventDateId,
					photographerId: photographerId,
				},
			});
			if (rsp.data)
				CustomNotification({
					pageName: 'Photographer',
					pagePrefix: 'Photographer',
					notificationType: 'deleted',
				});
			refetchQuery();
		},
		[],
	);

	const createEventPhotographersFn = useCallback(
		async (eventDateId: string, photographerIds: string[]) => {
			const rsp = await createEventPhotographers({
				variables: {
					data: {
						eventDateId: eventDateId,
						photographerIds: photographerIds,
					},
				},
			});
			if (rsp.data)
				CustomNotification({
					pageName: 'Photographer',
					pagePrefix: 'Photographer',
					notificationType: 'created',
				});
			refetchQuery();
		},
		[],
	);

	qryData?.pagination?.eventDates?.result?.map((event, i) => {
		return p_EventPhotographers.push({
			eventName: event.event?.name || '',
			id: event?.id?.toString() || '',
			key: (i + 1).toString() || '',
			eDate: format(parseISO(event?.eDate), 'LLL dd-yyyy'),
			parentName: event.eventParent?.parentName || '',
			venueName: event.eventParent?.venue?.name || '',
			eventPhotographers:
				event.photographers
					?.map((ph) => ({
						adminComment: '',
						id: ph.id || '',
						name:
							(ph.photographer?.fName || '') +
							' ' +
							(ph.photographer?.lName || ' '),
						photographerComment: ph.photographerComment || undefined,
						photographerId: ph.photographer?.id || '',
					}))
					.filter(
						(a) => a.photographerId == qryPhotographers?.photographers?.[0]?.id,
					) || [],
		});
	});

	const photographerOptions = qryPhotographers?.photographers?.map(
		(photographer) => {
			return (
				<Select.Option
					label={`${photographer?.fName} ${photographer?.lName}`}
					key={photographer?.id + '_' + photographer?.fName}
					value={photographer?.id || ''}
				>
					{photographer?.fName} {photographer?.lName}
				</Select.Option>
			);
		},
	);

	const photographerFilter: any = [];

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			render: (key: number) => {
				return (
					<Text style={{ display: 'block', textAlign: 'center' }}>{key}</Text>
				);
			},
		},
		{
			title: 'Event Name',
			dataIndex: 'eventName',
			key: 'eventName',
			width: 200,
			...tableColumnTextFilterConfig<EventPhotographersTable>(),
			onFilter: (value, record) => {
				return record.eventName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Venue Name',
			dataIndex: 'venueName',
			key: 'venueName',
			width: 200,
			// filters: qryData?.pagination?.eventDates?.result
			// 	?.map((ed) => {
			// 		return {
			// 			text: ed.eventParent?.venue?.name,
			// 			value: ed.eventParent?.venue?.name,
			// 		};
			// 	})
			// 	.filter(
			// 		(ed, index, self) =>
			// 			index ===
			// 			self.findIndex((o) => o.text === ed.text && o.value === ed.value),
			// 	),
			// onFilter: (value, record) => record.venueName.indexOf(value) === 0,
			...tableColumnTextFilterConfig<EventPhotographersTable>(),
			onFilter: (value, record) => {
				return record.venueName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Dates',
			dataIndex: 'eDate',
			key: 'eDate',
			width: 110,
			align: 'center',
			sorter: (a, b) => {
				return new Date(a.eDate).valueOf() - new Date(b.eDate).valueOf();
			},
			render: (date: string) => date,
		},
		{
			title: 'Photographer(s)',
			key: 'eventPhotographers',
			width: 200,

			render: (row: EventPhotographersTable) => (
				<Select
					mode="multiple"
					style={{ width: '100%' }}
					placeholder="Assign to event"
					onSelect={async (value: string) => {
						await createEventPhotographersFn(row.id, [value]);
					}}
					onDeselect={async (v) => {
						await deleteEventPhotographerIdFn(v, row.id);
					}}
					defaultValue={row.eventPhotographers.map((ph) => {
						return ph.photographerId;
					})}
					size="small"
				>
					{photographerOptions || (
						<Option value="" key="">
							<></>
						</Option>
					)}
				</Select>
			),
		},
	];

	const { Option } = Select;

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Events Availability</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				></Row>
				<Spin indicator={<LoadingOutlined />} spinning={loading}>
					<InfoTable
						data={p_EventPhotographers}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);

							setEventName(filters?.eventName?.toString().toLowerCase() || '');

							setEventVenueName(filters?.venueName?.toString() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.eventDates?.paginationInfo?.totalResult ||
								0,
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default PhotographerEventSelfAssign;
