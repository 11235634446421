import { useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useRef, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import EmbeddedInput from 'src/components/ui/embedded-input';
import MediaUpload from 'src/components/ui/media-upload';
import VenueSelectDropDown from 'src/components/venue-selector';
import { VideoForm } from 'src/lib/form-interfaces';
 
import { QueryGetVenues, QueryGetVideoTypes } from 'src/lib/gql/queries';
import { Media, MediaFoldersNames } from 'src/lib/interface';
import SelectTags from '../select-tags';
import { CmsGetVenuesQuery, CmsGetVenuesQueryVariables, CmsGetVideoTypesQuery, CmsGetVideoTypesQueryVariables } from 'src/lib/generated/_generated';

const { Option } = Select;

interface Props {
	venueVideoName?: string;
	venueVideoThumbnail?: Media;
	venueVideoUrl?: string;
	venueVideoWeight?: number;
	venueVideoTags?: string[];
	venueId?: string;
	videoTypeId?: string;
	canDelete?: boolean;
	onDelete?: React.MouseEventHandler<HTMLElement>;
	onSave: ({
		name,
		videoUrl,
		thumbnailId,
		venueId,
		weight,
		tags,
	}: VideoForm) => void;
}

const VideoCard = (props: Props) => {
	const [videoThumbnailId, setVideoThumbnailId] = useState<Media | undefined>(
		props.venueVideoThumbnail,
	);
	const selectedHTags = useRef<string[]>([]);

	const [venueName, setVenueName] = useState<string | undefined>(undefined);

	const { data: venuesQuery, loading: venueLoading } = useQuery<
		CmsGetVenuesQuery,
		CmsGetVenuesQueryVariables
	>(QueryGetVenues, {
		variables: {
			pageNumber: 1,
			perPage: 10,
			name: venueName,
		},
		notifyOnNetworkStatusChange: true,
	});

	const { data: videoTypeQuery } = useQuery<
		CmsGetVideoTypesQuery,
		CmsGetVideoTypesQueryVariables
	>(QueryGetVideoTypes, {
		variables: {
			showAll: true,
		},
	});

	const [form] = useForm<VideoForm>();

	const createVideo = (data: VideoForm) => {
		if (videoThumbnailId === null || videoThumbnailId === undefined) {
			CustomNotification({
				pageName: 'Videos',
				errorName: 'Video Thumbnail',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			name: data.name,
			thumbnailId: videoThumbnailId.id,
			videoUrl: data.videoUrl,
			weight: data.weight || null,
			venueId: data.venueId,
			videoTypeId: data.videoTypeId,
			tags: selectedHTags.current,
		});
	};

	const mediaCallback = useCallback((media: Media) => {
		setVideoThumbnailId(media);
	}, []);
	// console.log(venuesQuery);
	return (
		<Form
			form={form}
			onFinish={createVideo}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%' }} gutter={16}>
				<Col span={24}>
					<MediaUpload
						folder={MediaFoldersNames.misc}
						onMediaUploaded={mediaCallback}
						media={videoThumbnailId || undefined}
						accept="IMAGE"
						placeholderText={'Drag and drop a video thumbnail image'}
					/>
				</Col>

				<Col span={24} lg={8}>
					<label className="input-fields-label">Name / Title</label>

					<Form.Item
						rules={[{ required: true }]}
						name="name"
						hasFeedback
						initialValue={props.venueVideoName}
					>
						<Input
							type="text"
							placeholder="Name / Title"
							className="input-fields"
						/>
					</Form.Item>
				</Col>

				<Col span={24} lg={8}>
					<label className="input-fields-label">Video Category</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						hasFeedback
						name="videoTypeId"
						initialValue={props.videoTypeId}
					>
						<Select
							style={{ width: '100%' }}
							placeholder="Select a category"
							size="middle"
							className="bg-select"
						>
							{videoTypeQuery?.getVideoTypes?.map((type, i) => {
								return (
									<Option key={`videoTypeId${i}`} value={type?.id || '-1'}>
										{type?.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col span={24} lg={8}>
					<label className="input-fields-label">Venue</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						hasFeedback
						name="venueId"
						initialValue={props.venueId}
					>
						<VenueSelectDropDown />
						{/* <Select
							style={{ width: '100%' }}
							placeholder="Select a Venue"
							size="middle"
							onSearch={(s) => setVenueName(s)}
							showSearch
							loading={venueLoading}
							className="bg-select"
							filterOption={false}
							// options={venuesQuery?.pagination?.venues?.result?.map(
							// 	(venue, i) => {
							// 		return {
							// 			value: venue?.id || '-1',
							// 			key: `venueID${i}`,
							// 			label: venue?.name,
							// 		};
							// 	},
							// )}
						>
							{venuesQuery?.pagination?.venues?.result?.map((venue, i) => {
								return (
									<Option key={`venueID${i}`} value={venue?.id || '-1'}>
										{venue?.name}
									</Option>
								);
							})}
						</Select> */}
					</Form.Item>
				</Col>
				<Col span={24} lg={8}>
					<label className="input-fields-label">Position</label>

					<Form.Item
						name="weight"
						hasFeedback
						initialValue={props.venueVideoWeight}
						rules={[
							{
								validator: (rule, value: string, back) => {
									if (value !== undefined) {
										if (value.length > 4) {
											back('Too large');
											return;
										}
										if (value !== '' && parseInt(value) < 1) {
											back('Minimum value is 1');
											return;
										}
									}
									back(undefined);
									return;
								},
							},
						]}
					>
						<Input
							type="number"
							min={1}
							placeholder="Position / Sequence No."
							className="input-fields"
						/>
					</Form.Item>
				</Col>
				<Col
					span={24}
					lg={8}
					style={{
						position: 'relative',
					}}
				>
					<label className="input-fields-label">Page Tags (Optional)</label>

					<SelectTags
						// filter={'ThingsToDo'}
						placeholder="Select Tags"
						initialValues={props.venueVideoTags || []}
						onSelectionChange={(tags) => {
							selectedHTags.current = tags;
						}}
						onInit={(tags) => (selectedHTags.current = tags)}
					/>
				</Col>

				<Col span={24}>
					<Form.Item name="videoUrl">
						<EmbeddedInput
							link={props.venueVideoUrl}
							onChange={() => {
								//
							}}
						/>
					</Form.Item>
				</Col>

				<Col span={24}>
					<hr style={{ opacity: '0.25' }} />

					<Row justify={'end'}>
						<Space size="small">
							<Button className="btn-main save" htmlType={'submit'}>
								Save
							</Button>

							{!props.canDelete ? (
								<Button
									className="btn-main"
									onClick={() => {
										window.history.back();
									}}
								>
									Cancel
								</Button>
							) : (
								<Button className="btn-main delete" onClick={props.onDelete}>
									Delete
								</Button>
							)}
						</Space>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

export default VideoCard;
