import { useMutation } from '@apollo/client';
import { Button, Col, Form, Radio, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import DatePicker from 'src/components/ui/date-picker';
import { ROUTES } from 'src/config/route';
import { EventDateForm } from 'src/lib/form-interfaces';
import {
	CmsDeleteEventDateMutation,
	CmsDeleteEventDateMutationVariables,
	CmsUpdateEventDateMutation,
	CmsUpdateEventDateMutationVariables,
} from 'src/lib/generated/_generated';
import {
	MutationDeleteEventDate,
	MutationUpdateEventDate,
} from 'src/lib/gql/mutations';
import { EventDate } from 'src/lib/interface';

const { Option } = Select;

interface EventDateProps {
	eventDateId: string;
	eventPhotographer?: boolean;
	eventFeatured?: boolean;
	eventDates: EventDate[];
}

const EventDateCard = (props: EventDateProps) => {
	const navigate = useNavigate();

	const [form] = useForm<EventDateForm>();

	const [UpdateEventDate] = useMutation<
		CmsUpdateEventDateMutation,
		CmsUpdateEventDateMutationVariables
	>(MutationUpdateEventDate);

	const updateQry = useCallback(async (data: EventDateForm) => {
		const rsp = await UpdateEventDate({
			variables: {
				id: props.eventDateId,
				featured: data.featured,
				photographer: data.photographer,
				eDate: data.newDate ? format(data.newDate, 'yyyy-MM-dd') : undefined,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Event',
				pagePrefix: 'Event Date',
				notificationType: 'updated',
			});
		}
	}, []);

	const [deleteEvent] = useMutation<
		CmsDeleteEventDateMutation,
		CmsDeleteEventDateMutationVariables
	>(MutationDeleteEventDate);

	const deleteEventFn = async () => {
		if (!confirm('Confirm delete?')) return;
		const rsp = await deleteEvent({
			variables: {
				id: props.eventDateId,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Events',
				customDescription: 'Event selected date has been deleted.',
				notificationType: 'custom-success',
			});
			navigate(`${ROUTES.EVENTS.path}`);
		}
	};

	const [selectedEd, setSelectedEd] = useState<string>(props.eventDateId);

	/* Function to handle date selection when editing (Selected Event Date) */
	function onDateSelected(value: any) {
		// refetchQuery();
	}

	return (
		<Form
			form={form}
			onFinish={updateQry}
			style={{
				width: '100%',
			}}
		>
			<Row align="top" gutter={16}>
				<Col span={24} lg={12} xl={6}>
					<p
						style={{
							margin: 0,
							textTransform: 'uppercase',
							fontFamily: 'Avenir Heavy',
						}}
					>
						Selected Event Date
					</p>
					<p
						style={{
							margin: 0,
							fontFamily: 'Avenir Medium',
							opacity: 0.7,
							fontSize: '11px',
						}}
					>
						Choose an event date to modify
					</p>
					<Select
						style={{ width: '100%' }}
						placeholder="Choose a date to edit"
						onChange={onDateSelected}
						size="middle"
						className="bg-select"
						onSelect={(v) => {
							if (v !== props.eventDateId)
								navigate(`${ROUTES.EDIT_EVENT.path}/${v}`);
							// setSelectedEd(option.value);
						}}
						defaultValue={props.eventDateId}
					>
						{props?.eventDates?.map((ed, i) => {
							return (
								<Option key={`eventDate:${i}`} value={ed.id || ''}>
									{ed.eDate}
								</Option>
							);
						})}
					</Select>
				</Col>
				<Col span={24} lg={12} xl={6}>
					<p
						style={{
							margin: 0,
							textTransform: 'uppercase',
							fontFamily: 'Avenir Heavy',
						}}
					>
						SHIFT EVENT DATE
					</p>
					<p
						style={{
							margin: 0,
							fontFamily: 'Avenir Medium',
							opacity: 0.7,
							fontSize: '11px',
						}}
					>
						Shift {'"'}Selected event date{'"'} to another date
					</p>
					<Form.Item name="newDate">
						<DatePicker
							disabledDate={(day) => {
								let found = false;
								props.eventDates.forEach((item) => {
									if (item.eDate === format(day, 'yyyy-MM-dd')) found = true;
								});
								return found;
							}}
							className="bg-select"
						/>
					</Form.Item>
				</Col>

				<Col span={24} lg={6}>
					<Text
						style={{
							display: 'block',
							textTransform: 'uppercase',
							fontFamily: 'Avenir Heavy',
							fontSize: '12px',
						}}
					>
						Assign a Photographer
					</Text>
					<Form.Item name="photographer" initialValue={props.eventPhotographer}>
						<Radio.Group>
							<Radio value={true}>Yes</Radio>
							<Radio value={false}>No</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>

				<Col span={24} lg={6}>
					<Text
						style={{
							display: 'block',
							textTransform: 'uppercase',
							fontFamily: 'Avenir Heavy',
							fontSize: '12px',
						}}
					>
						Featured
					</Text>

					<Form.Item name="featured" initialValue={props.eventFeatured}>
						<Radio.Group>
							<Radio value={true}>Yes</Radio>
							<Radio value={false}>No</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>

				{/* Save Changes Button */}
				<Row
					gutter={16}
					align="middle"
					justify="end"
					style={{
						width: '100%',
					}}
				>
					<Button
						htmlType="submit"
						className="btn-main save"
						style={{
							marginTop: '0.5rem',
						}}
					>
						Save Changes
					</Button>
					<Button
						className="btn-main delete"
						onClick={() => {
							deleteEventFn();
						}}
						style={{
							marginLeft: '0.5rem',
							marginTop: '0.5rem',
						}}
					>
						Delete This Date
					</Button>
				</Row>
			</Row>
		</Form>
	);
};

export default EventDateCard;
