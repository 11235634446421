import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import VideoCategoryCard from 'src/components/ui/videos/video-category-card';
import { ROUTES } from 'src/config/route';
import { CategoryForm } from 'src/lib/form-interfaces';
import {
	CmsDeleteVideoTypeMutation,
	CmsDeleteVideoTypeMutationVariables,
	CmsGetVideoTypeQuery,
	CmsGetVideoTypeQueryVariables,
	CmsUpdateVideoTypeMutation,
	CmsUpdateVideoTypeMutationVariables,
} from 'src/lib/generated/_generated';
import {
	MutationDeleteVideoType,
	MutationUpdateVideoType,
} from 'src/lib/gql/mutations';
import { QueryGetVideoType } from 'src/lib/gql/queries';

const EditVideoCategory = () => {
	const params = useParams<{ id: string }>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetVideoTypeQuery, CmsGetVideoTypeQueryVariables>(
		QueryGetVideoType,
		{
			variables: {
				id: params.id ?? '',
			},
		},
	);

	const [UpdateCategory] = useMutation<
		CmsUpdateVideoTypeMutation,
		CmsUpdateVideoTypeMutationVariables
	>(MutationUpdateVideoType, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos Categories',
				pagePrefix: 'Video Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(
		async (data: CategoryForm) => {
			const rsp = await UpdateCategory({
				variables: {
					id: params.id || '',
					data: {
						name: data.name,
						weight: data.weight ? parseInt(data.weight.toString()) : null,
					},
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Videos Categories',
					pagePrefix: 'Video Category',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.getVideoType?.id],
	);

	const [DeleteVideoCategory] = useMutation<
		CmsDeleteVideoTypeMutation,
		CmsDeleteVideoTypeMutationVariables
	>(MutationDeleteVideoType, {
		onError(error) {
			CustomNotification({
				pageName: 'Videos Categories',
				pagePrefix: 'Video Category',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteType = useCallback(
		async (id: string) => {
			if (!confirm('Are you sure you want to delete?')) return;

			const rsp = await DeleteVideoCategory({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Videos Categories',
					pagePrefix: 'Video Category',
					notificationType: 'deleted',
				});

				navigate(`${ROUTES.VIDEOS_CATEGORY.path}`);
			}
		},
		[qryData?.getVideoType],
	);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit Video Category</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				{loading ? (
					<Skeleton active />
				) : (
					<VideoCategoryCard
						categoryName={qryData?.getVideoType?.name || ''}
						categoryWeight={qryData?.getVideoType?.weight || 1}
						onSave={updateQry}
						canDelete={true}
						onDelete={() => {
							deleteType(params.id ?? '');
						}}
					/>
				)}
			</Row>
		</Row>
	);
};

export default EditVideoCategory;
