import { Button, Card, Col, Input, Row } from 'antd';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { loginUser } from 'src/lib/firebase/fbClient';
import { StoreContext } from 'src/store/store';
import logo from '../logo.svg';

const LoginPage: React.FunctionComponent = () => {
	const hideLayout: any = useContext(StoreContext);

	const navigate = useNavigate();

	useEffect(() => {
		const userToken = localStorage.getItem('token');
		if (userToken !== null) {
			window.location.href = '/';
		}
	}, [navigate]);

	const fnLogin = async (data: { email: string; password: string }) => {
		const token = await loginUser(data);
		window.location.href = '/';
	};

	return (
		<Row
			gutter={16}
			justify="center"
			align="middle"
			style={{
				height: '100vh',
				textAlign: 'left',
			}}
		>
			<Col span={22} md={14} lg={7}>
				<Card
					style={{
						borderRadius: '10px',
						boxShadow: '2px 2px 6px rgba(0,0,0,0.2)',
					}}
				>
					<div
						style={{
							margin: '16px 0px 32px',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<img
							src={logo}
							alt="DubaiNight"
							style={{
								width: '100%',
								maxWidth: '250px',
							}}
						/>
					</div>

					<Form onFinish={fnLogin}>
						<FormItem
							name="email"
							style={{
								margin: '12px 0px',
							}}
							rules={[
								{
									required: true,
									message: 'Please enter your Email',
								},
							]}
						>
							<Input type="email" placeholder="Email" />
						</FormItem>

						<FormItem
							name="password"
							style={{
								margin: '12px 0px',
							}}
							rules={[
								{
									required: true,
									message: 'Please enter your password',
								},
							]}
						>
							<Input type="password" placeholder="Password" />
						</FormItem>

						<FormItem
							style={{
								textAlign: 'center',
								marginTop: '24px',
							}}
						>
							<Button
								type="default"
								htmlType="submit"
								style={{
									borderColor: 'black',
									textTransform: 'uppercase',
								}}
								onClick={() => {
									hideLayout.hideLayout = true;
								}}
							>
								Login
							</Button>
						</FormItem>
					</Form>
				</Card>
			</Col>
		</Row>
	);
};

export default LoginPage;
