import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import { NewsForm } from 'src/lib/form-interfaces';

import { MutationCreateArticle } from 'src/lib/gql/mutations';
import NewsCard from '../../components/ui/news/news-card';
import {
	CmsCreateArticleMutation,
	CmsCreateArticleMutationVariables,
} from 'src/lib/generated/_generated';

const CreateNewsArticle = () => {
	const navigate = useNavigate();

	const [CreateArticle] = useMutation<
		CmsCreateArticleMutation,
		CmsCreateArticleMutationVariables
	>(MutationCreateArticle, {
		onError(error) {
			CustomNotification({
				pageName: 'News/Articles',
				pagePrefix: 'Article',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const createQry = useCallback(async (data: NewsForm) => {
		const rsp = await CreateArticle({
			variables: {
				content: data.content,
				date: data.date,
				weight: data.weight ? parseInt(data.weight) : null,
				title: data.title,
				mediaId: data.mediaId,
				slug: data.slug,
				articleTypeId: data.articleTypeId,
				articleCategoryId: data.articleCategoryId,
				meta: data.meta,
				articleHTags: data.tags,
				scrapDate: data.scrapDate,
				featured: data.featured || false,
				enabled: data.enabled || false,
				publishTime: data.publishTime,
				articleJumpTos: data.jumpTo,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'News/Articles',
				pagePrefix: 'Article',
				notificationType: 'created',
			});

			navigate(`${ROUTES.EDIT_NEWS.path}/${rsp.data.createArticle?.id}`);
		}
	}, []);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header" style={{ display: 'block' }}>
					Create an Article
				</Text>
			</Col>

			{/* News Component - Props explained in the component */}
			<NewsCard onSave={createQry} />
		</Row>
	);
};

export default CreateNewsArticle;
