import { CheckOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
	pos: number;
	onSubmitChange: (newPos: number | null) => void;
}

const WeightChange = ({ pos, onSubmitChange }: Props) => {
	const [nPos, setPos] = useState<number | undefined>(pos);
	useEffect(() => {
		setPos(pos);
	}, [pos]);
	return (
		<>
			<Input
				type="number"
				value={nPos || undefined}
				onChange={(ele) => {
					if (ele.target.value.length > 4) {
						return;
					}
					if (ele.target.value !== '' && parseInt(ele.target.value) < 1) {
						setPos(1);
						return;
					}
					setPos(
						(ele.target.value && Number.parseInt(ele.target.value)) ||
							undefined,
					);
				}}
				style={{
					textAlign: 'center',
					padding: '6px 0',
					width: '40px',
					display: 'block',
					margin: '0 auto',
					position: 'relative',
				}}
				max={1000}
				// min={1}
			/>
			{pos !== nPos && (
				<Button
					onClick={() => onSubmitChange(nPos || null)}
					ghost
					shape="circle"
					size="small"
					title="Confirm"
					style={{
						position: 'absolute',
						top: '50%',
						right: '3px',
						backgroundColor: 'var(--valid)',
						color: 'white',
						transform: 'translateY(-50%)',
					}}
					icon={<CheckOutlined />}
				/>
			)}
		</>
	);
};

export default WeightChange;
