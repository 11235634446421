import { SettingOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card, Layout } from 'antd';
import React, { useState } from 'react';
import 'react-day-picker/lib/style.css';
import { Link } from 'react-router-dom';
import BreadCrumbComp from 'src/components/breadcrumb';
import { QueryMe } from 'src/lib/gql/queries';
import Navbar from '../components/navbar';
import { CmsMeQuery } from 'src/lib/generated/_generated';

const { Sider, Content } = Layout;

interface Props {
	children: any;
	title?: string;
	isPhotographer?: boolean;
}

const MainLayout = (props: Props) => {
	const [collapsed, setCollapsed] = useState(false);

	const { data: qryData } = useQuery<CmsMeQuery>(QueryMe);

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider
				collapsible
				theme="light"
				style={{
					border: '1px solid rgba(0,0,0,0.1)',
					position: 'fixed',
					height: 'calc(100% - 48px)',
					overflowY: 'scroll',
					overflowX: 'hidden',
				}}
				width={242}
				onCollapse={() => {
					setCollapsed(!collapsed);
				}}
				collapsed={collapsed}
			>
				<Navbar isPhotographer={props.isPhotographer}></Navbar>
			</Sider>

			<Layout>
				<Layout
					className="site-layout"
					style={{
						position: 'relative',
						backgroundColor: '#f8f8f8',
						width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 242px)',
						marginLeft: 'auto',
					}}
				>
					<Content
						className="site-layout-background"
						style={{
							margin: '24px 16px',
							padding: 24,
							minHeight: 280,
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-end',
							}}
						>
							<span
								style={{
									opacity: 0.3,
								}}
							>
								{qryData?.me?.userType}
							</span>
							<span
								style={{
									opacity: 0.3,
								}}
							>
								{qryData?.me?.email}
							</span>
							<Link to="/settings">
								<Button
									style={{
										display: 'inline-flex',
										alignItems: 'center',
										marginLeft: 'auto',
										padding: '1.2rem',
										textTransform: 'uppercase',
										background: 'black',
										color: 'white',
										borderRadius: '5px',
										fontSize: '0.8rem',
									}}
								>
									<SettingOutlined
										style={{
											transform: 'scale(1.5)',
											marginTop: '0.15rem',
										}}
									/>
									Region Settings
								</Button>
							</Link>
						</div>

						<div
							style={{
								margin: '18px 0',
							}}
						>
							<BreadCrumbComp />
						</div>

						<Card className="rounded-card">{props.children}</Card>
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
};

export default MainLayout;
