import LocalizedStrings from 'react-localization';

export const NOTIFICATION = new LocalizedStrings({
	en: {
		title: '[{pageName}]',
		duration: 2,

		/* Messages */
		deleted: '[{pagePrefix}] has been deleted successfully.',
		updated: '[{pagePrefix}] has been updated successfully.',
		created: '[{pagePrefix}] has been created successfully.',

		/* Errors */
		error: '[{pageError}] is invalid or missing.',
	},
});
