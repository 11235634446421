import { useMutation } from '@apollo/client';
import { Button, Card, Col, Image, Row, Tag } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Collapse from 'antd/lib/collapse/Collapse';
import React, { useRef, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import {
	CmsDeleteEventPhotosMutation,
	CmsDeleteEventPhotosMutationVariables,
	CmsUpdateEventPhotosMutationMutation,
	CmsUpdateEventPhotosMutationMutationVariables,
} from 'src/lib/generated/_generated';

import {
	MutationsDeleteEventPhotos,
	MutationUpdateEventPhotos,
} from 'src/lib/gql/mutations';

const { Panel } = Collapse;

interface Props {
	eventDate: any | undefined;
	refetchQry: () => void;
}

const ApproveCard = React.memo((props: Props) => {
	const checkedList = useRef<boolean[][]>([]);
	const [reRender, setReRender] = useState<boolean>(false);
	return (
		<Collapse key={'1'} expandIconPosition={'left'}>
			{props.eventDate?.photographers?.map((ph) => {
				return (
					<Panel
						header={`${ph.photographer?.fName} ${ph.photographer?.lName}`}
						key={ph.id || ''}
						extra={
							<ExtraButtons
								refetchQry={async () => {
									props.refetchQry();
									checkedList.current[ph.id || ''] = [];
									setReRender(!reRender);
								}}
								list={checkedList.current[ph.id || ''] || []}
								selectAllClick={(e) => {
									checkedList.current[ph.id || ''] = [];
									props.eventDate?.eventPhotos?.forEach((ep) => {
										if (ep.eventPhotographer?.id === ph.id) {
											checkedList.current[ph.id || ''][ep.id] = true;
										}
									});
									setReRender(!reRender);
									e.stopPropagation();
								}}
							/>
						}
					>
						<Row>
							<Col
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									minWidth: '250px',
								}}
							>
								{props.eventDate?.eventPhotos?.map((eventPhoto) => {
									if (eventPhoto.eventPhotographer?.id !== ph.id)
										return (
											<React.Fragment
												key={'undavilable' + eventPhoto.id + ph.id}
											></React.Fragment>
										);
									return (
										<Card
											key={'eventPhoto' + eventPhoto.id}
											className={[
												'mainItem',
												checkedList.current[ph.id || '']?.[eventPhoto.id || '']
													? 'checked'
													: '',
											].join(' ')}
											style={{
												padding: 0,
												height: '100%',
												width: '100%',
											}}
										>
											<Checkbox
												style={{
													position: 'absolute',
													right: 10,
													bottom: 10,
													zIndex: 4,
													padding: '0.4rem',
													borderRadius: '10px',
													userSelect: 'none',
													fontSize: '10px',
													border: '2px solid white',
													borderColor: checkedList.current[ph.id || '']?.[
														eventPhoto.id || ''
													]
														? '#2090ff'
														: 'white',
												}}
												checked={
													checkedList.current[ph.id || '']?.[
														eventPhoto.id || ''
													] || false
												}
												onChange={(e) => {
													if (checkedList.current[ph.id || ''] === undefined)
														checkedList.current[ph.id || ''] = [];
													if (e.target.checked)
														checkedList.current[ph.id || ''][
															eventPhoto.id || ''
														] = true;
													else {
														delete checkedList.current[ph.id || ''][
															eventPhoto.id || ''
														];
													}
													setReRender(!reRender);
												}}
											/>

											<Tag
												color={
													eventPhoto.approved
														? 'var(--valid)'
														: 'var(--invalid)'
												}
												style={{
													position: 'absolute',
													zIndex: 4,
													padding: '0.5rem',
													top: -1,
													left: -1,
													borderTopLeftRadius: 10,
													borderBottomRightRadius: 10,
													fontSize: '0.6rem',
													fontFamily: 'Avenir Black',
													lineHeight: '100%',
												}}
											>
												{eventPhoto.approved ? 'APPROVED' : 'DISAPPROVED'}
											</Tag>
											<Image
												width={'100%'}
												height={'100%'}
												style={{
													objectFit: 'cover',
													objectPosition: 'center',
													borderRadius: 10,
												}}
												preview={{
													maskClassName: 'mask',
												}}
												src={eventPhoto.media?.url}
											/>
										</Card>
									);
								})}
							</Col>
						</Row>
					</Panel>
				);
			})}
		</Collapse>
	);
});

export default ApproveCard;

const ExtraButtons = (props: {
	list: boolean[];
	refetchQry: () => void;
	selectAllClick: React.MouseEventHandler<HTMLElement>;
}) => {
	const [mutationUpdateEventPhotos] = useMutation<
		CmsUpdateEventPhotosMutationMutation,
		CmsUpdateEventPhotosMutationMutationVariables
	>(MutationUpdateEventPhotos);

	const [mutationsDeleteEventPhotos] = useMutation<
		CmsDeleteEventPhotosMutation,
		CmsDeleteEventPhotosMutationVariables
	>(MutationsDeleteEventPhotos);

	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'nowrap',
			}}
		>
			<Button
				type={'primary'}
				ghost
				size={'small'}
				style={{
					margin: '0 8px',
					textTransform: 'uppercase',
					fontSize: 'clamp(8px, 5vw, 12px)',
				}}
				// disabled={Object.keys(props.list).length === 0}
				onClick={props.selectAllClick}
			>
				Select All
			</Button>
			<Button
				type={'primary'}
				ghost
				size={'small'}
				style={{
					margin: '0 8px',
					textTransform: 'uppercase',
					fontSize: 'clamp(8px, 5vw, 12px)',
				}}
				disabled={Object.keys(props.list).length === 0}
				onClick={async (e) => {
					e.stopPropagation();
					const rsp = await mutationUpdateEventPhotos({
						variables: {
							data: {
								eventPhotoId: Object.keys(props.list),
								approved: true,
							},
						},
					});
					if (rsp.data)
						CustomNotification({
							pageName: 'Photos',
							pagePrefix: 'Photo',
							notificationType: 'updated',
						});
					props.refetchQry();
				}}
			>
				Approve Selected
			</Button>
			<Button
				type={'primary'}
				danger
				disabled={Object.keys(props.list).length === 0}
				size={'small'}
				style={{
					margin: '0 8px',
					textTransform: 'uppercase',
					fontSize: 'clamp(5px, 5vw, 12px)',
				}}
				onClick={async (e) => {
					e.stopPropagation();
					const rsp = await mutationUpdateEventPhotos({
						variables: {
							data: {
								eventPhotoId: Object.keys(props.list),
								approved: false,
							},
						},
					});

					if (rsp.data)
						CustomNotification({
							pageName: 'Photos',
							pagePrefix: 'Photo',
							notificationType: 'updated',
						});
					props.refetchQry();
				}}
			>
				Disapprove Selected
			</Button>

			<Button
				type={'primary'}
				danger
				disabled={Object.keys(props.list).length === 0}
				size={'small'}
				style={{
					margin: '0 8px',
					textTransform: 'uppercase',
					fontSize: 'clamp(5px, 5vw, 12px)',
				}}
				onClick={async (e) => {
					e.stopPropagation();
					const confirm = prompt("Enter 'CONFIRM' to delete.");
					if (confirm === 'CONFIRM') {
						const rsp = await mutationsDeleteEventPhotos({
							variables: {
								ids: Object.keys(props.list),
							},
						});

						if (rsp.data)
							CustomNotification({
								pageName: 'Photos',
								pagePrefix: 'Photos deleted',
								notificationType: 'deleted',
							});
						props.refetchQry();
					}
				}}
			>
				Delete Selected
			</Button>
		</div>
	);
};
