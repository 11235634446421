import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { CustomNotification } from 'src/components/custom-notification';
import MediaUpload from 'src/components/ui/media-upload';
import SlugInput from 'src/components/ui/slug-input';
import TagInput from 'src/components/ui/tag-input';
import { Media, MediaFoldersNames } from 'src/lib/interface';
interface Props {
	categoryMedia?: Media;
	categoryName?: string; // Story Name
	categoryLink?: string; //Optional - Lixnk for story
	categoryPosition?: number; //Position of Story
	categoryTag?: string; //Appears in  Pages
	categorySlug?: string; //Selected Dates
	categoryEventColor?: string; // Event Color
	categoryMetaDescription?: string;
	categoryMetaTitle?: string;
	categoryMetaKeywords?: string;
	canDelete?: boolean;
	onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
	onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
	onSave: ({
		id,
		name,
		htag,
		slug,
		coverImage,
		weight,
		color,
		metaDescription,
		metaTitle,
		metaKeywords,
	}: {
		id: string;
		name: string;
		htag: string;
		slug: string;
		coverImage: string;
		weight: number | null;
		color: string;
		metaDescription: string;
		metaTitle: string;
		metaKeywords: string;
	}) => void;
}

const ThingsToDoCard = React.memo((props: Props) => {
	const [mediaId, setMediaId] = useState<string | null>(null);
	const [CPicker, setCPicker] = useState<string | undefined>(
		props.categoryEventColor,
	);

	if (mediaId == null && props.categoryMedia?.id) {
		setMediaId(props.categoryMedia?.id);
	}

	const [form] = useForm();

	// useEffect(() => {
	// 	form.setFieldsValue({
	// 		name: props.categoryName,
	// 		weight: props.categoryPosition,
	// 		htag: props.categoryTag,
	// 		slug: props.categorySlug,
	// 	});
	// }, []);

	// Mutation

	const createCategory = async (data: any) => {
		if (mediaId === null || mediaId === undefined) {
			CustomNotification({
				pageName: 'Things to Do',
				errorName: 'Image',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			id: data.id,
			name: data.name,
			htag: data.htag,
			slug: data.slug,
			coverImage: mediaId,
			weight: data.weight ? parseInt(data.weight) : null,
			color: data.color,
			metaDescription: data.metaDescription,
			metaTitle: data.metaTitle ? data.metaTitle : data.name,
			metaKeywords: data.metaKeywords
				? data.metaKeywords
				: data.name.toLocaleLowerCase(),
		});
	};

	return (
		<>
			<Form
				form={form}
				onFinish={createCategory}
				style={{
					width: '100%',
				}}
			>
				<Row style={{ width: '100%' }} gutter={16}>
					<Col
						span={24}
						style={{
							marginBottom: '16px',
						}}
					>
						<MediaUpload
							folder={MediaFoldersNames.misc}
							onMediaUploaded={(media) => {
								setMediaId(media.id);
							}}
							media={props.categoryMedia || undefined}
							accept="IMAGE"
							placeholderText="Drag and drop a category image"
						/>
					</Col>

					{/* Input Fields */}
					<Col span={24} md={18}>
						<label className="input-fields-label">Name / Title</label>

						<Form.Item
							rules={[{ required: true }]}
							name="name"
							hasFeedback
							initialValue={props.categoryName}
							style={{
								marginBottom: 0,
							}}
						>
							<Input
								type="text"
								name="name"
								id="name"
								placeholder="Title"
								className="input-fields"
							/>
						</Form.Item>
					</Col>
					<Col span={24} md={6}>
						<label className="input-fields-label">Position</label>

						<Form.Item
							name="weight"
							style={{
								marginBottom: 0,
							}}
							rules={[
								{
									validator: (rule, value: string, back) => {
										if (value !== undefined) {
											if (value.length > 4) {
												back('Too large');
												return;
											}
											if (value !== '' && parseInt(value) < 1) {
												back('Minimum value is 1');
												return;
											}
										}
										back(undefined);
										return;
									},
								},
							]}
							initialValue={props.categoryPosition}
						>
							<Input
								type="number"
								placeholder="Position / Sequence No."
								className="input-fields"
							/>
						</Form.Item>
					</Col>
					<Col
						span={24}
						style={{
							marginTop: '1rem',
						}}
					>
						<label className="input-fields-label">Things To Do Tag</label>

						<Form.Item
							rules={[{ required: true }]}
							name="htag"
							hasFeedback
							initialValue={props.categoryTag}
							style={{
								marginBottom: '0',
							}}
						>
							<TagInput prefix={'ThingsToDo'} />
						</Form.Item>
						<label className="input-fields-label">Slug</label>

						<Form.Item
							rules={[{ required: true }]}
							name="slug"
							hasFeedback
							initialValue={props.categorySlug}
						>
							<SlugInput />
						</Form.Item>

						<label className="input-fields-label">META TITLE</label>

						<Form.Item
							rules={[{ required: true }]}
							name="metaTitle"
							hasFeedback
							initialValue={props.categoryMetaTitle}
						>
							<Input
								type="text"
								name="metaTitle"
								id="metaTitle"
								placeholder="Meta Title"
								className="input-fields"
							/>
						</Form.Item>

						<label className="input-fields-label">META KEYWORDS</label>

						<Form.Item
							rules={[{ required: true }]}
							name="metaKeywords"
							hasFeedback
							initialValue={props.categoryMetaKeywords}
						>
							<Input
								type="text"
								name="metaKeywords"
								id="metaKeywords"
								placeholder="Meta Keywords"
								className="input-fields"
							/>
						</Form.Item>

						<label className="input-fields-label">META DESCRIPTION</label>

						<Form.Item
							rules={[{ required: true }]}
							name="metaDescription"
							hasFeedback
							initialValue={props.categoryMetaDescription}
						>
							<Input.TextArea
								className="input-fields"
								placeholder="Meta Description (This is for the SEO)"
								rows={4}
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						<p
							style={{
								margin: 0,
								textTransform: 'uppercase',
								fontFamily: 'Avenir Heavy',
							}}
						>
							EVENT COLOR
						</p>
						<p
							style={{
								margin: 0,

								fontFamily: 'Avenir Medium',
								opacity: 0.7,
								fontSize: '11px',
							}}
						>
							Assign a color for events linked to this event type
						</p>
						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							initialValue={props.categoryEventColor}
							name="color"
						>
							<SketchPicker
								color={CPicker}
								onChange={(v) => {
									setCPicker(v.hex);
									form.setFieldsValue({
										color: v.hex,
									});
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row
					justify={'center'}
					style={{
						margin: '8px auto',
					}}
				>
					<Space size="small">
						<Button className="btn-main save" htmlType="submit">
							Save
						</Button>

						{props.canDelete ? (
							<Button className="btn-main delete" onClick={props.onDelete}>
								Delete
							</Button>
						) : (
							<Button
								className="btn-main"
								onClick={() => {
									window.history.back();
								}}
							>
								Cancel
							</Button>
						)}
					</Space>
				</Row>
			</Form>
		</>
	);
});

export default ThingsToDoCard;
