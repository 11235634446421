import React, { createContext, useReducer } from 'react';

interface State {
	isAuthenticated: boolean;
	hideLayout: boolean;
	authToken: string | null;
}
type Action =
	| { type: 'hide_layout'; status: boolean }
	| { type: 'set_auth'; token: string }
	| { type: 'remove_auth' };

const initState = {
	isAuthenticated: false,
	authToken: localStorage.getItem('_auth_token'),
	hideLayout: false,
};
const initDispatch: any = () => {
	//
};
export const StoreContext = createContext<State>(initState);
export const StoreDispatchContext =
	createContext<React.Dispatch<Action>>(initDispatch);

const reducerFn = (state: State, action: Action): State => {
	switch (action.type) {
		case 'hide_layout':
			return { ...state, hideLayout: action.status };
		case 'set_auth':
			localStorage.setItem('_auth_token', action.token);
			return { ...state, isAuthenticated: true, authToken: action.token };
		case 'remove_auth':
			localStorage.removeItem('_auth_token');
			return { ...state, isAuthenticated: false, authToken: null };
		default:
			throw new Error();
	}
};

const StoreContextProvider = (props: any) => {
	const [state, dispatch] = useReducer(reducerFn, initState);
	return (
		<StoreContext.Provider value={state}>
			<StoreDispatchContext.Provider value={dispatch}>
				{props.children}
			</StoreDispatchContext.Provider>
		</StoreContext.Provider>
	);
};

export default StoreContextProvider;
