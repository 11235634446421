import { Card, Col, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';

interface Props {
	link: string | undefined;
	height?: string;
	onChange?: (value: string) => void;
}

const EmbeddedInput = React.memo(
	({ height = '250px', link, onChange }: Props) => {
		const [videoLink, setVideoLink] = useState(link);

		return (
			<div>
				<Card
					style={{
						height: height,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Col
						style={{
							flex: 1,
							position: 'relative',
						}}
					>
						{videoLink ? (
							<iframe
								style={{
									width: '100%',
									height: '100%',
									border: '0px',
								}}
								id="ytplayer"
								allow="autoplay; encrypted-media"
								src={`https://www.youtube.com/embed/${videoLink}?rel=0&modestbranding=1`}
							></iframe>
						) : (
							<Text
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									opacity: '0.25',
									display: 'flex',
									width: '100%',
									height: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								strong={true}
							>
								Video preview will appear here
							</Text>
						)}
					</Col>
				</Card>
				<Input
					onChange={(e) => {
						if (onChange) {
							onChange(e.target.value);
						}
						setVideoLink(e.target.value);
					}}
					type="text"
					value={videoLink}
					placeholder="YouTube Video ID (E.G: 5qap5aO4i9A)"
					className="input-fields"
				/>
			</div>
		);
	},
);

export default EmbeddedInput;
