import { EyeOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import React from 'react';
import { CmsGetCurrentRegionQuery } from 'src/lib/generated/_generated';
import { QueryGetCurrentRegion } from 'src/lib/gql/queries';
export enum LiveViewContentType {
	NEWS = 'news/xxx',
}

interface Props {
	url: string;
	type: LiveViewContentType;
}

const LiveView = React.memo((props: Props) => {
	const { data: qryData } = useQuery<CmsGetCurrentRegionQuery>(
		QueryGetCurrentRegion,
	);
	const url = new URL(
		`${props.type}/${props.url}`,
		`https://${qryData?.getCurrentRegion?.domainName}`,
	);
	return (
		<a href={url.href} target="_blank" rel="noreferrer">
			<EyeOutlined
				style={{
					color: 'black',
					width: '12px',
					height: '12px',
				}}
			/>
		</a>
	);
});

export default LiveView;
