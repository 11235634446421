import { Button, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { compareAsc, format, parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

interface Props {
	initialDates: string[];
	disabledDays?: string[];
	listOfDates?: string;
	onChange: (selectedDates: Date[]) => void;
	onInit?: (initialDates: Date[]) => void;
}

const MultiDateSelector = React.memo((props: Props) => {
	const [selectedDays, setSelectedDays] = useState<Date[] | null>([]);
	const disabledDays = useRef<Date[]>([]);

	const modifiers = {
		highlighted: disabledDays.current,
	};

	const modifiersStyles = {
		highlighted: {
			color: 'white',
			backgroundColor: '#cc0000',
			cursor: 'not-allowed',
		},
	};
	useEffect(() => {
		const iDates =
			props.initialDates?.map((date) =>
				parseISO(format(parseISO(date), 'yyyy-MM-dd')),
			) || [];
		if (props.disabledDays) {
			disabledDays.current =
				props.disabledDays?.map((date) =>
					parseISO(format(parseISO(date), 'yyyy-MM-dd')),
				) || [];
		}

		setSelectedDays(iDates);
		if (props.onInit) props.onInit(iDates);
	}, []);

	function handleDayClick(day: Date) {
		const nDay = parseISO(format(day, 'yyyy-MM-dd'));
		let selected = false;
		const fDays = selectedDays?.filter((fDay) => {
			if (compareAsc(nDay, fDay) === 0) {
				selected = true;
				return false;
			}
			return true;
		});
		let days: Date[] = [];
		if (!selected && selectedDays !== null) {
			days = [...selectedDays, nDay];
		} else {
			days = fDays || [];
		}
		setSelectedDays(days);

		props.onChange(days);
	}

	const [from, setFrom] = useState<Date>();
	const [to, setTo] = useState<Date>();

	// Calculate dates in between
	const getDaysArray = function (start, end) {
		const arr: any = [];
		const dt = new Date(start);
		for (dt; dt <= end; dt.setDate(dt.getDate() + 1)) {
			arr.push(parseISO(format(new Date(dt), 'yyyy-MM-dd')));
		}
		return arr;
	};
	return (
		<>
			<DayPicker
				onDayClick={handleDayClick}
				selectedDays={selectedDays || []}
				initialMonth={selectedDays?.[0] || new Date()}
				showOutsideDays={true}
				className="margin-0"
				disabledDays={[...disabledDays.current]}
				modifiers={modifiers}
				modifiersStyles={modifiersStyles}
				fromMonth={new Date()}
			/>

			<div>
				<Text style={{ display: 'block', marginTop: '1em' }} strong={true}>
					Range Date Selection (optional)
				</Text>
				<Text style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}>
					Select a starting date and ending date for the specified module
				</Text>
				<Space>
					{/* From */}
					<DayPickerInput
						dayPickerProps={{
							fromMonth: new Date(),
							disabledDays: [...disabledDays.current],
						}}
						onDayChange={(day) => {
							setFrom(day);
						}}
					/>
					{/* To */}
					<DayPickerInput
						dayPickerProps={{
							fromMonth: new Date(),
							disabledDays: [
								{
									before: new Date(),
								},
								...disabledDays.current,
								from,
							],
						}}
						onDayChange={(day) => {
							setTo(day);
						}}
					/>
				</Space>

				<Space
					style={{
						width: '100%',
						justifyContent: 'flex-end',
						marginTop: '0.5rem',
					}}
				>
					<Button
						onClick={() => {
							setSelectedDays(getDaysArray(from, to));
						}}
					>
						Add
					</Button>
					<Button
						onClick={() => {
							setSelectedDays(null);
						}}
					>
						Clear
					</Button>
				</Space>
			</div>
		</>
	);
});

export default MultiDateSelector;

// {
// 	daysOfWeek: selectedDays?.map((sd) => sd.getDate() || 0) || [0],
// },
