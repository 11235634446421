import { useMutation } from '@apollo/client';
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	UniqueIdentifier,
} from '@dnd-kit/core';
import {
	arrayMove,
	rectSortingStrategy,
	SortableContext,
	sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Button, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
 
import { MutationUpdateEventPhotos } from 'src/lib/gql/mutations';
import { Item } from './item';
import { SortableItem } from './sortable-item';
import { CmsGetEventPhotosQuery, CmsUpdateEventPhotosMutationMutation, CmsUpdateEventPhotosMutationMutationVariables } from 'src/lib/generated/_generated';

interface Props {
	eventDate: any;
	refetchQry: () => void;
}

interface OrderPhoto extends CmsGetEventPhotosQuery {
	initialIndex: string;
}
interface OrderItem {
	[index: string]: OrderPhoto;
}

const SortableCard = React.memo((props: Props) => {
	const [items, setItems] = useState(
		props.eventDate?.eventPhotos
			?.filter((ep) => ep.approved || false)
			.map((ep) => ep.id || '') || [],
	);
	const orderItem = useMemo<OrderItem>(() => {
		const temp: OrderItem = {};
		props.eventDate?.eventPhotos?.forEach((item, i) => {
			if (item.approved)
				temp[item.id || ''] = {
					...item,
					initialIndex: (i + 1).toString(),
				};
		});
		return temp;
	}, [props.eventDate]);

	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const [mutationUpdateEventPhotos] = useMutation<
		CmsUpdateEventPhotosMutationMutation,
		CmsUpdateEventPhotosMutationMutationVariables
	>(MutationUpdateEventPhotos, {
		onError: (error) => {
			CustomNotification({
				pageName: 'Photos',
				pagePrefix: 'Photo',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (active.id !== over?.id) {
			setItems((items) => {
				const oldIndex = items.indexOf(active.id.toString());
				const newIndex = items.indexOf(over?.id.toString() || '');

				return arrayMove(items, oldIndex, newIndex);
			});
		}

		setActiveId(null);
	};

	return (
		<>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
			>
				<SortableContext items={items} strategy={rectSortingStrategy}>
					<Space
						size="small"
						style={{
							display: 'flex',
							flexWrap: 'wrap',
						}}
					>
						{items.map((id, i) => (
							<SortableItem
								key={id}
								id={id}
								imgSrc={(orderItem[id] as any)?.media?.url || ''}
								indexValue={orderItem[id]?.initialIndex || ''}
							/>
						))}
					</Space>
				</SortableContext>
				<DragOverlay>
					{activeId ? (
						<Item
							id={activeId}
							style={{
								border: '1px dashed black',
								height: '100%',
								borderRadius: '10px',
								background: 'hsla(0, 0%, 10%, 0.2)',
								boxShadow: '0px 0px 6px hsla(0, 0%, 10%, 0.8)',
							}}
						/>
					) : null}
				</DragOverlay>
			</DndContext>

			<Button
				ghost
				className="btn-main save"
				style={{
					marginLeft: 'auto',
				}}
				onClick={async () => {
					const rsp = await mutationUpdateEventPhotos({
						variables: {
							data: {
								eventPhotoId: items,
								changeOrder: true,
							},
						},
					});
					if (rsp.data)
						CustomNotification({
							pageName: 'Photos',
							pagePrefix: 'Photos order',
							notificationType: 'updated',
						});
					props.refetchQry();
				}}
			>
				UPDATE CHANGES
			</Button>
		</>
	);
});

export default SortableCard;
