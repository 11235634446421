import {
	CloseCircleFilled,
	LoadingOutlined,
	PlusCircleOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import { ROUTES } from 'src/config/route';

import {
	AddEventPhotographerComment,
	MutationDeleteEventAlbum,
} from 'src/lib/gql/mutations';
import { QueryGetEventPhotographersView } from 'src/lib/gql/queries';
import { EventTable, PhotographerEventsTable } from 'src/lib/interface';
import InfoTable from '../../components/info-table';
import {
	CmsAddEventPhotographerCommentMutationMutation,
	CmsAddEventPhotographerCommentMutationMutationVariables,
	CmsDeleteEventAlbumMutation,
	CmsDeleteEventAlbumMutationVariables,
	CmsGetPaginatedEventPhotographersQuery,
	CmsGetPaginatedEventPhotographersQueryVariables,
} from 'src/lib/generated/_generated';
const PhotographerHomeView = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [pageNumber2, setPageNumber2] = useState(1);
	const [perPage2, setPerPage2] = useState(10);
	const {
		data: qryWithData,
		loading: loadingWith,
		refetch: refetchQuery,
	} = useQuery<
		CmsGetPaginatedEventPhotographersQuery,
		CmsGetPaginatedEventPhotographersQueryVariables
	>(QueryGetEventPhotographersView, {
		fetchPolicy: 'cache-and-network',
		variables: {
			pageNumber: pageNumber,
			perPage: perPage,
			withPhotos: true,
		},
	});

	const { data: qryWithoutData, loading: loadingWithout } = useQuery<
		CmsGetPaginatedEventPhotographersQuery,
		CmsGetPaginatedEventPhotographersQueryVariables
	>(QueryGetEventPhotographersView, {
		fetchPolicy: 'cache-and-network',
		variables: {
			pageNumber: pageNumber2,
			perPage: perPage2,
			withPhotos: false,
		},
	});

	const p_EventsWith: PhotographerEventsTable[] = [];
	const p_EventsWithout: PhotographerEventsTable[] = [];

	qryWithData?.pagination?.eventPhotographers?.result?.forEach((pe, i) => {
		return p_EventsWith.push({
			eDate: format(parseISO(pe?.eventDate?.eDate), 'LLL dd-yyyy'),
			eventName: pe?.eventDate?.event?.name || '',
			id: pe?.id || '-1',
			key: (i + 1).toString() || '-1',
			venueName: pe?.eventDate?.eventParent?.venue?.name || '',
			adminComment: pe.adminComment || undefined,
			eventDateId: pe.eventDate?.id || '-1',
			photographerComment: pe.photographerComment || undefined,
			// eventPhotosCount: pe.eventPhotos?.length,
		});
	});

	qryWithoutData?.pagination?.eventPhotographers?.result?.forEach((pe, i) => {
		return p_EventsWithout.push({
			eDate: format(parseISO(pe?.eventDate?.eDate), 'LLL dd-yyyy'),
			eventName: pe?.eventDate?.event?.name || '',
			id: pe?.id || '-1',
			key: (i + 1).toString() || '-1',
			venueName: pe?.eventDate?.eventParent?.venue?.name || '',
			adminComment: pe.adminComment || undefined,
			eventDateId: pe.eventDate?.id || '-1',
			photographerComment: pe.photographerComment || undefined,
			// eventPhotosCount: pe.eventPhotos?.length,
		});
	});

	const [delEventAlbum] = useMutation<
		CmsDeleteEventAlbumMutation,
		CmsDeleteEventAlbumMutationVariables
	>(MutationDeleteEventAlbum, {
		onError: (error) => {
			CustomNotification({
				pageName: 'Photos',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteAlbum = async (id: string) => {
		const rsp = await delEventAlbum({
			variables: {
				eventDateId: id,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Photos',
				pagePrefix: 'Album',
				notificationType: 'deleted',
			});

			refetchQuery();
		}
	};

	const comments = useRef<string[]>([]);

	const [addEventPhotographerComment] = useMutation<
		CmsAddEventPhotographerCommentMutationMutation,
		CmsAddEventPhotographerCommentMutationMutationVariables
	>(AddEventPhotographerComment, {
		onError(error) {
			CustomNotification({
				pageName: 'Photographer',
				pagePrefix: 'Comment',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const addEventPhotographerCommentFn = useCallback(
		async (id: string, comment: string) => {
			const rsp = await addEventPhotographerComment({
				variables: {
					comment: comment,
					id: id,
				},
			});
			if (rsp.data)
				CustomNotification({
					pageName: 'Comment',
					pagePrefix: 'Comment',
					notificationType: 'updated',
				});
		},
		[],
	);
	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Venue',
			dataIndex: 'venueName',
			key: 'title',
			width: 200,
			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.venueName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Dates',
			dataIndex: 'eDate',
			key: 'eDate',
			width: 110,
			align: 'center',

			sorter: (a, b) => {
				return new Date(a.eDate).valueOf() - new Date(b.eDate).valueOf();
			},
			render: (date: string) => date,
		},
		{
			title: 'Event Name',
			dataIndex: 'eventName',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.eventName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: '',
			key: 'enabled',
			width: 80,
			align: 'center',

			render: (props: PhotographerEventsTable) => {
				return (
					<Space>
						<Link to={`${ROUTES.PHOTOGRAPHER_PHOTOS.path}/${props.id}`}>
							<PlusCircleOutlined />
						</Link>
						{props.eventPhotosCount && props?.eventPhotosCount > 0 ? (
							<CloseCircleFilled
								onClick={() => {
									deleteAlbum(props.eventDateId);
								}}
								style={{
									color: 'var(--invalid)',
									width: '12px',
									height: '12px',
								}}
							/>
						) : (
							<></>
						)}
					</Space>
				);
			},
		},
	];

	// function getListData(value: moment.Moment) {
	// 	let listData;

	// 	qryData?.getEventDates?.map((ed) => {
	// 		switch (format(parseISO(value.toISOString()), 'yyyy-MM-dd')) {
	// 			case ed.eDate: {
	// 				listData = [
	// 					{
	// 						type: 'success',
	// 						content: ed?.event?.name,
	// 						venue: ed?.eventParent?.venue?.name,
	// 					},
	// 				];
	// 			}
	// 		}
	// 	});

	// 	return listData || [];
	// }

	// function dateCellRender(value) {
	// 	const listData = getListData(value);
	// 	return (
	// 		<ul className="events">
	// 			{listData.map((item) => (
	// 				<li key={item.content}>
	// 					<p
	// 						style={{
	// 							fontFamily: 'Avenir Black',
	// 							fontSize: '0.6rem',
	// 							margin: 0,
	// 						}}
	// 					>
	// 						EVENT
	// 					</p>
	// 					<p>{item.content}</p>
	// 					<p
	// 						style={{
	// 							fontFamily: 'Avenir Black',
	// 							fontSize: '0.6rem',
	// 							margin: 0,
	// 						}}
	// 					>
	// 						VENUE
	// 					</p>
	// 					<p>{item.venue}</p>
	// 				</li>
	// 			))}
	// 		</ul>
	// 	);
	// }

	return (
		<>
			<Row>
				<Col span={24}>
					<Text className="sub-header">Events Calendar (Contains Media)</Text>
				</Col>

				<Col
					span={24}
					style={{
						margin: '16px 0',
					}}
				>
					{/* <Calendar dateCellRender={dateCellRender} /> */}

					<Spin
						indicator={<LoadingOutlined />}
						spinning={loadingWith ? true : false}
					>
						<InfoTable
							data={p_EventsWith}
							columnData={columns}
							onChange={(pagination, filters, sorter) => {
								setPerPage(pagination?.pageSize);
								setPageNumber(pagination?.current);
							}}
							pagination={{
								total:
									qryWithData?.pagination?.eventPhotographers?.paginationInfo
										?.totalResult || 0,
							}}
							expandable={{
								expandedRowRender: (record: PhotographerEventsTable) => {
									return (
										<React.Fragment>
											<Row
												style={{
													margin: '1rem 0 0.8rem 0',
													display: 'flex',
													alignItems: 'center',
												}}
											></Row>
											<label>
												Admin Comment
												<Input
													defaultValue={record.adminComment}
													disabled={true}
												/>
											</label>

											<label>
												Photographer Comment
												<Input
													style={{
														marginBottom: '1rem',
													}}
													defaultValue={record.photographerComment}
													onChange={(e) => {
														comments.current[record.id] = e.target.value;
													}}
													suffix={
														<Button
															ghost
															type="primary"
															size="small"
															style={{
																fontSize: 'clamp(9px, 1vw, 10px)',
																color: 'var(--valid)',
																borderColor: 'var(--valid)',
															}}
															onClick={async (e) => {
																e.stopPropagation();
																if (comments.current[record.id]) {
																	addEventPhotographerCommentFn(
																		record.id,
																		comments.current[record.id],
																	);
																}
															}}
														>
															UPDATE
														</Button>
													}
												/>
											</label>
										</React.Fragment>
									);
								},
								rowExpandable: (re) => true,
							}}
							other={{
								scroll: {
									x: 900,
								},
							}}
						/>
					</Spin>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Text className="sub-header">
						Events Calendar (No Media Uploaded)
					</Text>
				</Col>

				<Col
					span={24}
					style={{
						margin: '16px 0',
					}}
				>
					{/* <Calendar dateCellRender={dateCellRender} /> */}

					<Spin
						indicator={<LoadingOutlined />}
						spinning={loadingWithout ? true : false}
					>
						<InfoTable
							data={p_EventsWithout}
							columnData={columns}
							onChange={(pagination, filters, sorter) => {
								setPerPage2(pagination?.pageSize);
								setPageNumber2(pagination?.current);
							}}
							pagination={{
								total:
									qryWithoutData?.pagination?.eventPhotographers?.paginationInfo
										?.totalResult || 0,
							}}
							expandable={{
								expandedRowRender: (record: PhotographerEventsTable) => {
									return (
										<React.Fragment>
											<Row
												style={{
													margin: '1rem 0 0.8rem 0',
													display: 'flex',
													alignItems: 'center',
												}}
											></Row>
											<label>
												Admin Comment
												<Input
													defaultValue={record.adminComment}
													disabled={true}
												/>
											</label>

											<label>
												Photographer Comment
												<Input
													style={{
														marginBottom: '1rem',
													}}
													defaultValue={record.photographerComment}
													onChange={(e) => {
														comments.current[record.id] = e.target.value;
													}}
													suffix={
														<Button
															ghost
															type="primary"
															size="small"
															style={{
																fontSize: 'clamp(9px, 1vw, 10px)',
																color: 'var(--valid)',
																borderColor: 'var(--valid)',
															}}
															onClick={async (e) => {
																e.stopPropagation();
																if (comments.current[record.id]) {
																	addEventPhotographerCommentFn(
																		record.id,
																		comments.current[record.id],
																	);
																}
															}}
														>
															UPDATE
														</Button>
													}
												/>
											</label>
										</React.Fragment>
									);
								},
								rowExpandable: (re) => true,
							}}
							other={{
								scroll: {
									x: 900,
								},
							}}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};

export default PhotographerHomeView;
