import { MediaType, Role } from './generated/_generated';

export const DataRegionHostname = {
	'rhinosme-stagging': 'region_dubai',
	dubainight: 'region_dubai',
	localhost: 'region_dubai',
	abudhabinight: 'region_abudhabi',
	dohanight: 'region_doha',
};
export interface IntroVideoTable {
	id: string;
	key: string;
	name: string;
	dates?: string;
	link: string;
	enabled: boolean;
}

export interface PopupAdTable {
	id: string;
	key: string;
	name: string;
	dates?: string;
	enabled: boolean;
}

export interface ThingsToDoTable {
	id: string;
	key: string;
	name: string;
	tag: string;
	enabled?: boolean;
	weight: number;
}

export interface BestPlacesTable {
	id: string;
	key: string;
	name: string;
	weight: number;
	tag: string;
	enabled: boolean;
	slug: string;
}

export interface DiscoverCityTable {
	id: string;
	key: string;
	name: string;
	weight: number;
	tag: string;
	enabled: boolean;
	slug: string;
	featured: boolean;
}

export interface VenuesTable {
	id: string;
	key: string;
	name: string;
	enabled?: boolean;
	weight?: number | undefined;
	status?: string | undefined;
}

export interface Media {
	id: string;
	url?: string;
	type?: MediaType;
	thumbnail?: Thumbnail;
}

export interface Thumbnail {
	id: string;
	url: string;
}

export interface OffersTable {
	id: string;
	key: string;
	name: string;
	enabled: boolean;
	featured: boolean;
	weight: number;
	venueName: string;
	offerDesc: string;
	offerType: string;
}

export interface EventTable {
	id: string;
	key: string;
	eDate: string;
	featured: boolean;
	eventName: string;
	venueName: string;
	eventVenueId: string;
	enabled: boolean;
	parentName: string;
	weight: number | null;
	photographer?: boolean;
	eventPhotographers: EventPhotographer[];
}

export interface PhotographerEventsTable {
	id: string;
	key: string;
	eDate: string;
	eventDateId: string;
	eventName: string;
	venueName: string;
	adminComment: string | undefined;
	photographerComment: string | undefined;
	eventPhotosCount?: number;
}
export interface EventParentInput {
	meta?: string | null;
	eventTypeId: string[];
	venueId?: string | null;
	slug?: string | null;
	occurrenceTime?: string | null;
	parentName?: string | null;
	approved?: string | null;
	color?: string | null;
}

export interface ArticlesTable {
	id: string;
	key: string;
	name: string;
	date: string;
	slug: string;
	articleTypeSlug?: string;
	tags: string[];
	featured?: boolean;
	enabled?: boolean;
	weight: number | null;
	type?: string;
	counters?: any[];
}

export interface ArticlesCommentTable extends ArticlesTable {
	comments: ArticleComment[];
}

export interface ArticleComment {
	id: string;
	userName: string;
	userId: string;
	date: string;
	comment: string;
	replies?: ArticleComment[];
}
export interface EventPhotosTable {
	id: string;
	key: string;
	eDate: string;
	eventName: string;
	venueName: string;
	photographers: EventPhotographer[];
	mediaCount: number;
	hasAlbum: boolean;
	pendingCount: number;
}

export interface EventPhotographersTable {
	id: string;
	key: string;
	eDate: string;
	eventName: string;
	venueName: string;
	parentName: string;
	eventPhotographers: EventPhotographer[];
	// photographers: string[];
}

export interface EventPhotographer {
	id: string;
	adminComment: string | undefined;
	photographerComment: string | undefined;
	photographerId: string;
	name: string;
}

export interface VenueVideosTable {
	id: string;
	key: string;
	name: string;
	venueName: string;
	weight: number;
	videoUrl: string;
	enabled: boolean;
}

export interface EventDate {
	eDate: string;
	id: string;
}

export interface EventParentTable {
	id: string;
	key: string;
	name: string;
	eventName: string;
	venueName: string;
}

export interface UsersTable {
	id: string;
	key: string;
	name: string;
	email: string;
	phone?: string;
	dob: string;
	createDateTime: Date;
	enabled: boolean;
	about: string;
	userType?: string;
	gender: string;
}

export interface PhotographerRequestTable extends UsersTable {
	photographerRequestId: string;
	cameraType: string;
	drive: boolean;
	freeTime: string;
}

export interface User {
	id: string;
	fName: string;
	lName: string;
	type: Role | null;
}

export interface VideoCategoryTable {
	id: string;
	key: string;
	name: string;
	weight: number;
	enabled: boolean;
}

export enum MediaFoldersNames {
	article = 'article',
	event = 'event',
	venue = 'venue',
	story = 'story',
	slider = 'slider',
	misc = 'misc',
	venueMenu = 'venue-menu',
}

export interface EventGenreTable {
	id: string;
	key: string;
	name: string;
	enabled?: boolean;
}

export interface FeaturedListTable {
	id: string;
	weight: number;
	key: string;
	name: string;
	articleId: string;
}
