import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PictureOutlined,
	PlayCircleOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import { DeleteSlider, UpdateSlider } from 'src/lib/gql/mutations';
import { QuerySliders } from 'src/lib/gql/queries';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';
import {
	CmsDeleteSliderMutationMutation,
	CmsDeleteSliderMutationMutationVariables,
	CmsGetPaginatedSlidersQuery,
	CmsGetPaginatedSlidersQueryVariables,
	CmsUpdateSliderMutationMutation,
	CmsUpdateSliderMutationMutationVariables,
} from 'src/lib/generated/_generated';
interface StoryTable {
	id: string;
	key: string;
	weight: number | undefined;
	// name: string;
	tags: string[];
	sliderType: string;
	enabled: boolean;
	link: string;
}

const Sliders = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [title, setTitle] = useState('');
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<
		CmsGetPaginatedSlidersQuery,
		CmsGetPaginatedSlidersQueryVariables
	>(QuerySliders, {
		fetchPolicy: 'cache-and-network',
		variables: {
			pageNumber: pageNumber,
			perPage: perPage,
			title: title,
		},
	});

	const [updateSlider] = useMutation<
		CmsUpdateSliderMutationMutation,
		CmsUpdateSliderMutationMutationVariables
	>(UpdateSlider, {
		onError(error) {
			CustomNotification({
				pageName: 'Sliders',
				pagePrefix: 'Slider',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const fnUpdateSlider = async ({
		id,
		enabled,
		pos,
	}: {
		id: string;
		enabled?: boolean | undefined;
		pos?: number | null | undefined;
	}) => {
		const rsp = await updateSlider({
			variables: {
				id: id,
				enabled: enabled,
				weight: pos,
			},
		});
		if (rsp.data) {
			CustomNotification({
				pageName: 'Sliders',
				pagePrefix: 'Slider',
				notificationType: 'updated',
			});

			refetchQuery();
		}
	};

	const [deleteSlider] = useMutation<
		CmsDeleteSliderMutationMutation,
		CmsDeleteSliderMutationMutationVariables
	>(DeleteSlider, {
		onError(error) {
			CustomNotification({
				pageName: 'Sliders',
				pagePrefix: 'Slider',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const fnDeleteSlider = async (id: string) => {
		if (!confirm('Are you sure you want to delete?')) return;
		const rsp = await deleteSlider({
			variables: {
				id: id,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Sliders',
				pagePrefix: 'Slider',
				notificationType: 'deleted',
			});

			refetchQuery();
		}
	};

	// const tagFilter: any = [];

	// qryData?.pagination?.sliders?.result?.map((sliders) => {
	// 	return sliders?.sliderHTags?.map((tag) => {
	// 		tagFilter.push({
	// 			text: tag?.hTag,
	// 			value: tag?.hTag,
	// 		});
	// 	});
	// });

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 100,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Position',
			width: 100,
			sorter: (a, b) => a.weight - b.weight,
			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							fnUpdateSlider({
								id: id,
								pos: weight,
							});
						}}
					/>
				);
			},
		},
		{
			title: 'Title / Name',
			dataIndex: 'name',
			key: 'title',
			width: 235,
			ellipsis: true,
			...tableColumnTextFilterConfig<StoryTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Image Slider',
			dataIndex: 'sliderType',
			key: 'sliderType',
			width: 130,
			render: (sliderType: string) => {
				switch (sliderType.toLowerCase()) {
					case 'image': {
						return (
							<Space>
								<PictureOutlined />
								{sliderType}
							</Space>
						);
					}
					case 'video': {
						return (
							<Space>
								<PlayCircleOutlined />
								{sliderType}
							</Space>
						);
					}
					case 'gif': {
						return (
							<Space>
								<PlayCircleOutlined />
								{sliderType.toUpperCase()}
							</Space>
						);
					}

					default: {
						return <></>;
					}
				}
			},
		},

		{
			title: 'Link',
			dataIndex: 'link',
			key: 'link',
			ellipsis: true,
			width: 280,
			...tableColumnTextFilterConfig<StoryTable>(),
			onFilter: (value, record) => {
				return record.link
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		// {
		// 	title: 'Appears in Pages',
		// 	key: 'tags',
		// 	width: 180,
		// 	dataIndex: 'tags',
		// 	filters: tagFilter.filter(
		// 		(slider, index, self) =>
		// 			index ===
		// 			self.findIndex(
		// 				(s) => s.text === slider.text && s.value === slider.value,
		// 			),
		// 	),
		// 	onFilter: (value, record) => record.tags.includes(value),
		// 	render: (tags: string[]) => (
		// 		<div
		// 			style={{
		// 				maxHeight: '100px',
		// 				overflowY: 'scroll',
		// 			}}
		// 		>
		// 			{tags?.map((tag) => {
		// 				const color = tag.length > 5 ? 'geekblue' : 'green';

		// 				return (
		// 					<Tag
		// 						color={color}
		// 						key={tag}
		// 						style={{
		// 							margin: '2px',
		// 							display: 'block',
		// 						}}
		// 					>
		// 						{tag.toUpperCase()}
		// 					</Tag>
		// 				);
		// 			})}
		// 		</div>
		// 	),
		// },

		{
			title: 'Edit',
			key: 'edit',
			width: 70,
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => record.enabled === value,
			render: (data: StoryTable) => {
				return (
					<Space
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Link to={`${ROUTES.EDIT_SLIDER.path}/${data.id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								fnUpdateSlider({ id: data.id, enabled: !data.enabled });
							}}
							style={{
								color: data.enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								fnDeleteSlider(data.id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Sliders</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<Link to={ROUTES.CREATE_SLIDER.path}>
						<Button className="btn-main">
							Create New Slider
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable<StoryTable>
						data={
							qryData?.pagination?.sliders?.result?.map((slider, i) => ({
								id: slider?.id || '0',
								key: (i + 1).toString() || '0',
								link: slider?.link || '',
								sliderType: slider?.media?.type || '',
								name: slider?.title || '',
								weight: slider?.weight || undefined,
								tags: [],
								// slider?.sliderHTags?.map((tag) => tag?.hTag || '') || [],
								enabled: slider?.enabled || false,
							})) || []
						}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);
							setTitle(filters?.title?.toString()?.toLowerCase() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.sliders?.paginationInfo?.totalResult || 0,
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default Sliders;
