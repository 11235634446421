import { LinkOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useEffect, useRef, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification'; 
import { Media, MediaFoldersNames } from 'src/lib/interface';
import MediaUpload from '../media-upload';
import MultiDateSelector from '../multi-date';
import SelectTags from '../select-tags';
import { ImageOrEmbedded, MediaType } from 'src/lib/generated/_generated';

interface Props {
	sliderMedia?: Media;
	sliderVideoThumbnail?: string;
	sliderVideoLink?: string;
	sliderName?: string; // Story Name
	sliderLink?: string; //Optional - Link for story
	sliderPosition?: number | undefined; //Position of Story
	sliderAppearIn?: string[];
	sliderTags?: string[]; //Appears in  Pages
	sliderDates?: string[]; //Selected Dates
	canDelete?: boolean;
	onSave: (values: {
		dates: Date[];
		link: string;
		mediaId: string;
		position: number | null;
		name: string;
		tags: string[];
		mediaType: ImageOrEmbedded;
		videoLink: string | undefined;
	}) => void; //On Save Click
	onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
}
const SliderCard = (props: Props) => {
	const [reRender, setReRender] = useState(false);
	const selectedDays = useRef<Date[]>([]);
	const selectedHTags = useRef<string[]>([]);
	const uploadedMedia = useRef<Media | undefined>(undefined);

	const [selectedMediaType, setSelectedMediaType] = useState<ImageOrEmbedded>(
		ImageOrEmbedded.Image,
	);

	const callParentSubmit = (data) => {
		if (selectedDays.current.length === 0) {
			CustomNotification({
				pageName: 'Sliders',
				errorName: 'Date',
				notificationType: 'error',
			});
			return;
		}
		if (selectedMediaType === ImageOrEmbedded.Embedded && !data.videoLink) {
			CustomNotification({
				pageName: 'Sliders',
				errorName: 'Video Link',
				notificationType: 'error',
			});
			return;
		}

		if (selectedHTags.current.length === 0) {
			CustomNotification({
				pageName: 'Sliders',
				errorName: 'Tag',
				notificationType: 'error',
			});
			return;
		}
		if (uploadedMedia.current === undefined) {
			CustomNotification({
				pageName: 'Sliders',
				errorName: 'Image',
				notificationType: 'error',
			});
			return;
		}
		props.onSave({
			dates: selectedDays.current,
			link: data.link,
			mediaId: uploadedMedia.current?.id || '-1',
			position: data.weight ? Number.parseInt(data.weight) : null,
			mediaType: selectedMediaType,
			name: data.name,
			tags: selectedHTags.current,
			videoLink: data.videoLink,
		});
	};

	useEffect(() => {
		if (props.sliderMedia?.type === MediaType.Embedded) {
			uploadedMedia.current = props?.sliderMedia?.thumbnail || undefined;
			setSelectedMediaType(ImageOrEmbedded.Embedded);
		} else {
			uploadedMedia.current = props?.sliderMedia || undefined;
			setSelectedMediaType(ImageOrEmbedded.Image);
		}
		setReRender(!reRender);
	}, []);
	return (
		<Form
			onFinish={callParentSubmit}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%' }} gutter={16}>
				{/* canDelete would mean Edit Page is active */}
				{!props.canDelete && (
					<Col
						span={24}
						style={{
							margin: '8px 0',
						}}
					>
						<Row justify="end">
							<Space>
								<Button
									className={[
										'btn-main',
										selectedMediaType === ImageOrEmbedded.Image
											? 'active'
											: undefined,
									].join(' ')}
									onClick={() => {
										setSelectedMediaType(ImageOrEmbedded.Image);
									}}
								>
									Image
								</Button>
								<Button
									className={[
										'btn-main',
										selectedMediaType === ImageOrEmbedded.Embedded
											? 'active'
											: undefined,
									].join(' ')}
									onClick={() => {
										setSelectedMediaType(ImageOrEmbedded.Embedded);
									}}
								>
									Video
								</Button>
							</Space>
						</Row>
					</Col>
				)}

				{/* If Image Slider is True, show this */}
				{selectedMediaType === 'IMAGE' && (
					<Col span={24}>
						<MediaUpload
							folder={MediaFoldersNames.slider}
							onMediaUploaded={(media) => {
								uploadedMedia.current = media;
							}}
							media={uploadedMedia.current}
							accept="IMAGE"
							placeholderText="Drag and drop a slider image"
						/>
					</Col>
				)}

				{/* Input Fields */}
				<Col span={24} md={selectedMediaType === 'IMAGE' ? 24 : 16}>
					<label className="input-fields-label">Name / Title</label>

					<Form.Item
						initialValue={props.sliderName}
						rules={[{ required: true }]}
						hasFeedback
						name="name"
						style={{
							margin: '8px 0',
						}}
					>
						<Input
							type="text"
							placeholder="Name / Title"
							className="input-fields"
						/>
					</Form.Item>
					<label className="input-fields-label">Link</label>

					<Form.Item
						initialValue={props.sliderLink}
						name="link"
						style={{
							margin: '8px 0',
						}}
					>
						<Input
							prefix={<LinkOutlined />}
							type="text"
							placeholder="Link"
							className="input-fields"
						/>
					</Form.Item>
					<label className="input-fields-label">Position</label>

					<Form.Item
						initialValue={props.sliderPosition}
						name="weight"
						rules={[
							{
								validator: (rule, value: string, back) => {
									if (value !== undefined) {
										if (value.length > 4) {
											back('Too large');
											return;
										}
										if (value !== '' && parseInt(value) < 1) {
											back('Minimum value is 1');
											return;
										}
									}
									back(undefined);
									return;
								},
							},
						]}
						style={{
							margin: '8px 0',
						}}
					>
						<Input
							type="number"
							min="1"
							placeholder="Position / Sequence No."
							className="input-fields"
						/>
					</Form.Item>

					<label className="input-fields-label">Page Tags</label>

					<SelectTags
						initialValues={props.sliderTags || []}
						onSelectionChange={(tags) => {
							selectedHTags.current = tags;
						}}
						onInit={(tags) => (selectedHTags.current = tags)}
					/>

					<Text style={{ display: 'block', marginTop: '1em' }} strong={true}>
						Dates
					</Text>
					<Text style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}>
						You can click on multiple dates to select the days to run the video
						for that duration
					</Text>
					<MultiDateSelector
						initialDates={props.sliderDates || []}
						onInit={(dates) => {
							selectedDays.current = dates;
						}}
						onChange={(dates) => (selectedDays.current = dates)}
					/>
					<hr style={{ opacity: '0.25' }} />

					<Row justify={'end'}>
						<Space size="small">
							<Button className="btn-main save" htmlType="submit">
								Save
							</Button>

							{props.canDelete ? (
								<Button className="btn-main delete" onClick={props.onDelete}>
									Delete
								</Button>
							) : (
								<Button
									className="btn-main"
									onClick={() => {
										window.history.back();
									}}
								>
									Cancel
								</Button>
							)}
						</Space>
					</Row>
				</Col>

				{/* Video Slider */}
				{selectedMediaType === ImageOrEmbedded.Embedded && (
					<Col
						span={24}
						md={8}
						style={{
							marginTop: '0.6em',
							height: '350px',
						}}
					>
						<MediaUpload
							folder={MediaFoldersNames.misc}
							onMediaUploaded={(media) => {
								uploadedMedia.current = media;
							}}
							media={uploadedMedia.current}
							accept="IMAGE"
							height={'350px'}
							placeholderText="Drag and drop a video thumbnail"
						/>
						<Form.Item
							initialValue={props.sliderMedia?.url}
							name="videoLink"
							style={{
								margin: '8px 0',
							}}
						>
							<Input
								prefix={<LinkOutlined />}
								type="text"
								placeholder="YouTube Video ID"
								className="input-fields"
								value={props.sliderVideoLink}
							/>
						</Form.Item>
					</Col>
				)}
			</Row>
		</Form>
	);
};

export default SliderCard;
