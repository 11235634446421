import React from 'react';
import ReactDOM from 'react-dom/client';
import ApolloHOC from 'src/hoc/apolloClient';
import Recoil from 'src/hoc/recoil';
import { Root } from 'src/root';
// import { initFirebase } from './lib/firebase/fbClient';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container as HTMLElement);

root.render(
	<React.StrictMode>
		<Recoil>
			<ApolloHOC>
				<Root />
			</ApolloHOC>
		</Recoil>
	</React.StrictMode>,
);
