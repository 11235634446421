import { gql } from '@apollo/client';

export const CreateStoryItem = gql`
	mutation CmsCreateStoryItemMutation(
		$storyId: ID!
		$mediaId: ID!
		$link: String
		$weight: Int
		$storyDates: [DateTime!]!
		$hTags: [String!]!
	) {
		createStoryItem(
			venueStoryId: $storyId
			item: {
				mediaId: $mediaId
				link: $link
				weight: $weight
				storyDates: $storyDates
				hTags: $hTags
			}
		) {
			id
		}
	}
`;

export const CreateSlider = gql`
	mutation CmsCreateSliderMutation(
		$title: String!
		$weight: Int
		$mediaId: ID!
		$link: String
		$videoLink: String
		$mediaType: ImageOrEmbedded!
		$sliderHTags: [String!]!
		$sliderDates: [DateTime!]!
	) {
		createSlider(
			data: {
				title: $title
				weight: $weight
				mediaId: $mediaId
				link: $link
				videoLink: $videoLink
				mediaType: $mediaType
				sliderHTags: $sliderHTags
				sliderDates: $sliderDates
			}
		) {
			id
		}
	}
`;

export const UpdateSlider = gql`
	mutation CmsUpdateSliderMutation(
		$id: ID!
		$title: String
		$weight: Int
		$mediaId: ID
		$link: String
		$videoLink: String
		$mediaType: ImageOrEmbedded
		$sliderHTags: [String!]
		$sliderDates: [DateTime!]
		$enabled: Boolean
	) {
		updateSlider(
			id: $id
			data: {
				title: $title
				weight: $weight
				mediaId: $mediaId
				link: $link
				videoLink: $videoLink
				mediaType: $mediaType
				sliderHTags: $sliderHTags
				sliderDates: $sliderDates
				enabled: $enabled
			}
		) {
			id
		}
	}
`;

export const DeleteSlider = gql`
	mutation CmsDeleteSliderMutation($id: ID!) {
		deleteSlider(id: $id)
	}
`;

export const DeleteStoryItem = gql`
	mutation CmsDeleteStoryItemMutation($id: ID!) {
		deleteStoryItem(id: $id)
	}
`;

export const AddEventPhotographerComment = gql`
	mutation CmsAddEventPhotographerCommentMutation($id: ID!, $comment: String!) {
		addEventPhotographerComment(id: $id, comment: $comment) {
			id
		}
	}
`;

export const MutationCreateEventPhoto = gql`
	mutation CmsCreateEventPhoto($data: EventPhotoInput!) {
		createEventPhoto(data: $data) {
			id
		}
	}
`;

export const CreateEventPhotographers = gql`
	mutation CmsCreateEventPhotographersMutation(
		$data: EventPhotographersInput!
	) {
		createEventPhotographers(data: $data) {
			id
		}
	}
`;

export const DeleteEventPhotographerId = gql`
	mutation CmsDeleteEventPhotographerIdMutation(
		$photographerId: ID!
		$eventDateId: ID!
	) {
		deleteEventPhotographerId(
			photographerId: $photographerId
			eventDateId: $eventDateId
		)
	}
`;

export const DeleteArticleCommentMutation = gql`
	mutation CmsDeleteArticleComment($id: ID!) {
		deleteArticleComment(id: $id)
	}
`;

export const UpdateStoryItem = gql`
	mutation CmsUpdateStoryItemMutation(
		$id: ID!
		$mediaId: ID!
		$link: String
		$weight: Int
		$storyDates: [DateTime!]!
		$hTags: [String!]!
	) {
		updateStoryItem(
			id: $id
			item: {
				mediaId: $mediaId
				link: $link
				weight: $weight
				storyDates: $storyDates
				hTags: $hTags
			}
		) {
			id
		}
	}
`;

export const UpdateVenueStory = gql`
	mutation CmsUpdateVenueStoryMutation(
		$id: ID!
		$enabled: Boolean
		$weight: Int
		$hTags: [String!]
	) {
		updateVenueStory(
			id: $id
			weight: $weight
			enabled: $enabled
			hTags: $hTags
		) {
			id
		}
	}
`;

export const CreateVenueStory = gql`
	mutation CmsCreateVenueStoryMutation(
		$venueId: ID!
		$weight: Int
		$hTags: [String!]!
		$mediaId: ID!
		$link: String
		$itemWeight: Int
		$storyDates: [DateTime!]!
		$storyHTags: [String!]!
	) {
		createVenueStory(
			data: {
				venueId: $venueId
				weight: $weight
				hTags: $hTags
				item: {
					mediaId: $mediaId
					link: $link
					weight: $itemWeight
					storyDates: $storyDates
					hTags: $storyHTags
				}
			}
		) {
			id
		}
	}
`;

// [MUTATION]  - Create Things to Do (EventType)

export const MutationCreateThingsToDo = gql`
	mutation CmsCreateThingsToDo(
		$name: String!
		$hTag: String!
		$slug: String!
		$weight: Int
		$coverImage: ID
		$color: String!
		$metaDescription: String
		$metaTitle: String
		$metaKeywords: String
	) {
		createEventType(
			data: {
				name: $name
				hTag: $hTag
				slug: $slug
				weight: $weight
				coverImageId: $coverImage
				color: $color
				metaDescription: $metaDescription
				metaTitle: $metaTitle
				metaKeywords: $metaKeywords
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Update Things to Do (Event Type)
export const MutationUpdateThingsToDo = gql`
	mutation CmsUpdateThingsToDo(
		$id: ID!
		$name: String
		$hTag: String
		$slug: String
		$weight: Int
		$coverImageId: ID
		$enabled: Boolean
		$color: String
		$metaDescription: String
		$metaTitle: String
		$metaKeywords: String
	) {
		updateEventType(
			id: $id
			data: {
				name: $name
				hTag: $hTag
				slug: $slug
				weight: $weight
				coverImageId: $coverImageId
				enabled: $enabled
				color: $color
				metaDescription: $metaDescription
				metaTitle: $metaTitle
				metaKeywords: $metaKeywords
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Delete Things to Do (Event Type)
export const MutationDeleteThingsToDo = gql`
	mutation CmsDeleteThingsToDo($id: ID!) {
		deleteEventType(id: $id)
	}
`;

// [MUTATION] - Create Area
export const MutationCreateArea = gql`
	mutation CmsCreateArea(
		$name: String!
		$meta: String
		$hTag: String!
		$slug: String!
		$weight: Int
		$coverImageId: ID!
		$description: String!
	) {
		createArea(
			data: {
				name: $name
				meta: $meta
				hTag: $hTag
				slug: $slug
				weight: $weight
				coverImageId: $coverImageId
				description: $description
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Update Area
export const MutationUpdateArea = gql`
	mutation CmsUpdateArea(
		$name: String
		$meta: String
		$hTag: String
		$slug: String
		$weight: Int
		$coverImageId: ID
		$id: ID!
		$enabled: Boolean
		$featured: Boolean
	) {
		updateArea(
			id: $id
			data: {
				name: $name
				meta: $meta
				hTag: $hTag
				slug: $slug
				weight: $weight
				coverImageId: $coverImageId
				enabled: $enabled
				featured: $featured
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Delete Area
export const MutationDeleteArea = gql`
	mutation CmsDeleteArea($id: ID!) {
		deleteArea(id: $id)
	}
`;

// [MUTATION] - Create Intro Video
export const MutationCreateIntroVideo = gql`
	mutation CmsCreateIntroVideo(
		$name: String!
		$dates: [DateTime!]!
		$videoUrl: String!
		$thumbnailId: ID!
	) {
		createIntroVideo(
			data: {
				name: $name
				dates: $dates
				videoUrl: $videoUrl
				thumbnailId: $thumbnailId
			}
		) {
			id
		}
	}
`;
// [MUTATION] - Create Popup Ad
export const MutationCreatePopupAd = gql`
	mutation CmsCreatePopupAd(
		$name: String!
		$dates: [DateTime!]!
		$mediaId: String!
		$mediaLink: String
		$btnText: String
	) {
		createPopupAd(
			data: {
				name: $name
				dates: $dates
				mediaId: $mediaId
				mediaLink: $mediaLink
				btnText: $btnText
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Update Intro Video
export const MutationUpdateIntroVideo = gql`
	mutation CmsUpdateIntroVideo(
		$name: String
		$dates: [DateTime!]
		$videoUrl: String
		$thumbnailId: ID
		$enabled: Boolean
		$id: ID!
	) {
		updateIntroVideo(
			id: $id
			data: {
				name: $name
				dates: $dates
				videoUrl: $videoUrl
				thumbnailId: $thumbnailId
				enabled: $enabled
			}
		) {
			id
		}
	}
`;

export const MutationUpdatePopupAd = gql`
	mutation CmsUpdatePopupAd(
		$name: String
		$dates: [DateTime!]
		$mediaId: String
		$enabled: Boolean
		$mediaLink: String
		$btnText: String
		$id: ID!
	) {
		updatePopupAd(
			id: $id
			data: {
				name: $name
				dates: $dates
				mediaId: $mediaId
				enabled: $enabled
				mediaLink: $mediaLink
				btnText: $btnText
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Delete Intro Video
export const MutationDeleteIntroVideo = gql`
	mutation CmsDeleteIntroVideo($id: ID!) {
		deleteIntroVideo(id: $id)
	}
`;

// [MUTATION] - Delete Intro Video
export const MutationDeletePopupAd = gql`
	mutation CmsDeletePopupAd($id: ID!) {
		deletePopupAd(id: $id)
	}
`;

// [MUTATION] - Create Venue Type (Best Places)
export const MutationCreateVenueType = gql`
	mutation CmsCreateVenueType(
		$name: String!
		$description: String
		$hTag: String!
		$slug: String!
		$weight: Int
		$coverImage: ID
		$meta: String
	) {
		createVenueType(
			data: {
				name: $name
				description: $description
				hTag: $hTag
				slug: $slug
				weight: $weight
				coverImageId: $coverImage
				meta: $meta
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Update Venue Type (Best Places)
export const MutationUpdateVenueType = gql`
	mutation CmsUpdateVenueType(
		$id: ID!
		$name: String
		$description: String
		$hTag: String
		$slug: String
		$weight: Int
		$coverImage: ID
		$meta: String
		$enabled: Boolean
	) {
		updateVenueType(
			id: $id
			data: {
				name: $name
				description: $description
				hTag: $hTag
				slug: $slug
				weight: $weight
				coverImageId: $coverImage
				meta: $meta
				enabled: $enabled
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Delete Venue Type (Best Places)
export const MutationDeleteVenueType = gql`
	mutation CmsDeleteVenueType($id: ID!) {
		deleteVenueType(id: $id)
	}
`;

// [MUTATION] - Create Venue
export const MutationCreateVenue = gql`
	mutation CmsCreateVenue(
		$name: String!
		$dressCode: String
		$meta: String
		$map: String
		$coverImageId: ID
		$mediaIds: [ID!]
		$menuIds: [ID!]
		$logoId: ID
		$info: String
		$partner: Boolean!
		$verified: Boolean!
		$weight: Int
		$hTag: String!
		$areaId: ID!
		$slug: String!
		$ctaNumber: String
		$ctaLink: String
		$longitude: String!
		$latitude: String!
		$venueTypeIds: [ID!]!
		$dateTiming: String
		$famousFor: String
		$virtualTour: String
		$status: VenueStatus
		$properName: String
	) {
		createVenue(
			data: {
				name: $name
				weight: $weight
				meta: $meta
				coverImageId: $coverImageId
				mediaIds: $mediaIds
				menuIds: $menuIds
				logoId: $logoId
				partner: $partner
				hTag: $hTag
				areaId: $areaId
				slug: $slug
				ctaNumber: $ctaNumber
				ctaLink: $ctaLink
				longitude: $longitude
				latitude: $latitude
				venueTypeIds: $venueTypeIds
				verified: $verified
				status: $status
				properName: $properName
				details: {
					info: $info
					famousFor: $famousFor
					dateTiming: $dateTiming
					dressCode: $dressCode
					map: $map
					virtualTour: $virtualTour
				}
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Update Venue
export const MutationUpdateVenue = gql`
	mutation CmsUpdateVenue(
		$id: ID!
		$name: String
		$dressCode: String
		$meta: String
		$map: String
		$coverImageId: ID
		$mediaIds: [ID!]
		$menuIds: [ID!]
		$logoId: ID
		$info: String
		$partner: Boolean
		$hTag: String
		$areaId: ID
		$slug: String
		$ctaNumber: String
		$ctaLink: String
		$longitude: String
		$latitude: String
		$venueTypeIds: [ID!]
		$dateTiming: String
		$famousFor: String
		$enabled: Boolean
		$verified: Boolean
		$weight: Int
		$virtualTour: String
		$status: VenueStatus
		$properName: String
	) {
		updateVenue(
			id: $id
			data: {
				name: $name
				meta: $meta
				coverImageId: $coverImageId
				mediaIds: $mediaIds
				menuIds: $menuIds
				logoId: $logoId
				partner: $partner
				hTag: $hTag
				areaId: $areaId
				slug: $slug
				ctaNumber: $ctaNumber
				ctaLink: $ctaLink
				longitude: $longitude
				latitude: $latitude
				venueTypeIds: $venueTypeIds
				enabled: $enabled
				verified: $verified
				weight: $weight
				status: $status
				properName: $properName
				details: {
					info: $info
					famousFor: $famousFor
					dateTiming: $dateTiming
					dressCode: $dressCode
					map: $map
					virtualTour: $virtualTour
				}
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Delete Venue
export const MutationDeleteVenue = gql`
	mutation CmsDeleteVenue($id: ID!) {
		deleteVenue(id: $id)
	}
`;

// [MUTATION] - Delete Venue
export const MutationDeleteVenueStory = gql`
	mutation CmsDeleteVenueStory($id: ID!) {
		deleteVenueStory(id: $id)
	}
`;

// [MUTATION] - Create Offer
export const MutationCreateOffer = gql`
	mutation CmsCreateOffer(
		$title: String!
		$weight: Int
		$description: String
		$offerDates: [DateTime!]!
		$venueId: ID!
		$coverImageId: ID
		$offerTypeId: ID!
		$offerHTags: [String!]!
	) {
		createOffer(
			data: {
				title: $title
				weight: $weight
				description: $description
				offerDates: $offerDates
				venueId: $venueId
				coverImageId: $coverImageId
				offerTypeId: $offerTypeId
				offerHTags: $offerHTags
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Create Offer
export const MutationDisableUser = gql`
	mutation CmsDisableUser($userId: ID!, $enabled: Boolean!) {
		disableUser(userId: $userId, enabled: $enabled) {
			id
		}
	}
`;

// [MUTATION] - Update Offer
export const MutationUpdateOffer = gql`
	mutation CmsUpdateOffer(
		$id: ID!
		$title: String
		$weight: Int
		$description: String
		$offerDates: [DateTime!]
		$venueId: ID
		$coverImageId: ID
		$enabled: Boolean
		$featured: Boolean
		$offerTypeId: ID
		$offerHTags: [String!]
	) {
		updateOffer(
			id: $id
			data: {
				title: $title
				weight: $weight
				description: $description
				offerDates: $offerDates
				venueId: $venueId
				coverImageId: $coverImageId
				featured: $featured
				enabled: $enabled
				offerTypeId: $offerTypeId
				offerHTags: $offerHTags
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Delete Offer
export const MutationDeleteOffer = gql`
	mutation CmsDeleteOffer($id: ID!) {
		deleteOffer(id: $id)
	}
`;

// [MUTATIOn] - Create Article

export const MutationCreateArticle = gql`
	mutation CmsCreateArticle(
		$title: String!
		$weight: Int
		$articleTypeId: ID!
		$articleCategoryId: ID!
		$date: DateTime!
		$slug: String!
		$mediaId: ID!
		$content: String!
		$meta: String
		$articleHTags: [String!]!
		$scrapDate: DateTime
		$featured: Boolean!
		$enabled: Boolean!
		$publishTime: Int
		$articleJumpTos: [ArticleJumpToInput!]
	) {
		createArticle(
			data: {
				title: $title
				weight: $weight
				articleJumpTos: $articleJumpTos
				articleTypeId: $articleTypeId
				articleCategoryId: $articleCategoryId
				slug: $slug
				date: $date
				mediaId: $mediaId
				content: $content
				meta: $meta
				articleHTags: $articleHTags
				scrapDate: $scrapDate
				featured: $featured
				enabled: $enabled
				publishTime: $publishTime
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Update Article

export const MutationUpdateArticle = gql`
	mutation CmsUpdateArticle(
		$id: ID!
		$title: String
		$weight: Int
		$articleTypeId: ID
		$articleCategoryId: ID
		$date: DateTime
		$slug: String
		$mediaId: ID
		$content: String
		$meta: String
		$enabled: Boolean
		$featured: Boolean
		$articleHTags: [String!]
		$scrapDate: DateTime
		$publishTime: Int
		$articleJumpTos: [UpdateArticleJumpToInput!]
	) {
		updateArticle(
			id: $id
			data: {
				title: $title
				articleJumpTos: $articleJumpTos
				weight: $weight
				articleTypeId: $articleTypeId
				articleCategoryId: $articleCategoryId
				slug: $slug
				date: $date
				mediaId: $mediaId
				content: $content
				meta: $meta
				enabled: $enabled
				featured: $featured
				articleHTags: $articleHTags
				scrapDate: $scrapDate
				publishTime: $publishTime
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Delete Article
export const MutationDeleteArticle = gql`
	mutation CmsDeleteArticle($id: ID!) {
		deleteArticle(id: $id)
	}
`;

// [MUTATION] - Create Event
export const MutationCreateEvent = gql`
	mutation CmsCreateEvent($eventInput: EventInput!) {
		createEvent(data: $eventInput) {
			id
			eventDates {
				id
			}
		}
	}
`;

// [MUTATION] - Update Event Date
export const MutationUpdateEventDate = gql`
	mutation CmsUpdateEventDate(
		$id: ID!
		$featured: Boolean
		$enabled: Boolean
		$photographer: Boolean
		$eDate: DateTime
		$albumCoverId: ID
		$weight: Int
	) {
		updateEventDate(
			id: $id
			data: {
				featured: $featured
				enabled: $enabled
				eDate: $eDate
				photographer: $photographer
				albumCoverId: $albumCoverId
				weight: $weight
			}
		) {
			id
		}
	}
`;

// [MUTATION] - Delete Event
export const MutationDeleteEventDate = gql`
	mutation CmsDeleteEventDate($id: ID!) {
		deleteEventDate(id: $id)
	}
`;
// [MUTATION] - Delete Event
export const MutationApprovePhotographer = gql`
	mutation CmsApprovePhotographer($id: ID!) {
		approvePhotographer(userId: $id) {
			id
		}
	}
`;

// [MUTATION] - Delete Event
export const MutationDeleteEventParent = gql`
	mutation CmsDeleteEventParent($id: ID!) {
		deleteEventParent(id: $id)
	}
`;

// [MUTATION] - Update Event

export const MutationUpdateEvent = gql`
	mutation CmsUpdateEvent($id: ID!, $updateEventInput: UpdateEventInput!) {
		updateEvent(id: $id, data: $updateEventInput) {
			id
		}
	}
`;

export const MutationUpdateUserType = gql`
	mutation CmsUpdateUserType($userId: ID!, $type: Role!) {
		updateUserType(userId: $userId, type: $type)
	}
`;

export const MutationApproveEventParent = gql`
	mutation CmsApproveEventParent($id: ID!) {
		approveEventParent(id: $id) {
			id
		}
	}
`;

export const MutationsDeleteEventPhotos = gql`
	mutation CmsDeleteEventPhotos($ids: [ID!]!) {
		deleteEventPhotos(ids: $ids)
	}
`;

export const MutationUpdateEventPhotos = gql`
	mutation CmsUpdateEventPhotosMutation($data: UpdateEventPhotosInput!) {
		updateEventPhotos(data: $data) {
			id
		}
	}
`;

// [MUTATION] - Create Venue Video
export const MutationCreateVenueVideo = gql`
	mutation CmsCreateVenueVideo($venueVideoInput: VenueVideoInput!) {
		createVenueVideo(data: $venueVideoInput) {
			id
		}
	}
`;

// [MUTATION] - Update Event Parent
export const MutationUpdateEventParent = gql`
	mutation CmsUpdateEventParent(
		$id: ID!
		$updateEventParentInput: UpdateEventParentInput!
	) {
		updateEventParent(id: $id, data: $updateEventParentInput) {
			id
		}
	}
`;

// [MUTATION] - Update Venue Video
export const MutationUpdateVenueVideo = gql`
	mutation CmsUpdateVenueVideo(
		$id: ID!
		$updateVenueVideoInput: UpdateVenueVideoInput!
	) {
		updateVenueVideo(id: $id, data: $updateVenueVideoInput) {
			id
		}
	}
`;

// [MUTATION] - Delete Venue Video?
export const MutationDeleteVenueVideo = gql`
	mutation CmsDeleteVenueVideo($id: ID!) {
		deleteVenueVideo(id: $id)
	}
`;

// [MUtAtION] - Create Video Type

export const MutationCreateVideoType = gql`
	mutation CmsCreateVideoType($data: VideoTypeInput) {
		createVideoType(data: $data) {
			id
		}
	}
`;

// [MUTATION] - Update Video Type

export const MutationUpdateVideoType = gql`
	mutation CmsUpdateVideoType($id: ID!, $data: UpdateVideoTypeInput) {
		updateVideoType(id: $id, data: $data) {
			id
		}
	}
`;

// [MUTATION] - Delete Video Type

export const MutationDeleteVideoType = gql`
	mutation CmsDeleteVideoType($id: ID!) {
		deleteVideoType(id: $id)
	}
`;

// [MUTATION] - Create Article Type

export const MutationCreateArticleType = gql`
	mutation CmsCreateArticleType($data: ArticleTypeInput) {
		createArticleType(data: $data) {
			id
		}
	}
`;

// [MUTATION] - Update Article Type

export const MutationUpdateArticleType = gql`
	mutation CmsUpdateArticleType($id: ID!, $data: UpdateArticleTypeInput) {
		updateArticleType(id: $id, data: $data) {
			id
		}
	}
`;

// [MUTATION] - Delete Article Type

export const MutationDeleteArticleType = gql`
	mutation CmsDeleteArticleType($id: ID!) {
		deleteArticleType(id: $id)
	}
`;

// [MUTATION] - Create Event Genre

export const MutationCreateEventGenre = gql`
	mutation CmsCreateEventGenre($data: GenreInput!) {
		createGenre(data: $data) {
			id
		}
	}
`;

// [MUTATION] - Update Event Genre

export const MutationUpdateEventGenre = gql`
	mutation CmsUpdateEventGenre($id: ID!, $data: GenreInput!) {
		updateGenre(id: $id, data: $data) {
			id
		}
	}
`;

// [MUTATION] - Delete Event Genre

export const MutationDeleteEventGenre = gql`
	mutation CmsDeleteEventGenre($id: ID!) {
		deleteGenre(id: $id)
	}
`;

// [MUTATION] - Create Offer Category

export const MutationCreateOfferCategory = gql`
	mutation CmsCreateOfferCategory($data: OfferTypeInput!) {
		createOfferType(data: $data) {
			id
		}
	}
`;

// [MUTATION] - Update Offer Category

export const MutationUpdateOfferCategory = gql`
	mutation CmsUpdateOfferCategory($id: ID!, $data: OfferTypeInput!) {
		updateOfferType(id: $id, data: $data) {
			id
		}
	}
`;

// [MUTATION] - Delete Offer Category

export const MutationDeleteOfferCategory = gql`
	mutation CmsDeleteOfferCategory($id: ID!) {
		deleteOfferType(id: $id)
	}
`;

// [MUTATION] - Delete Event Photos Album

export const MutationDeleteEventAlbum = gql`
	mutation CmsDeleteEventAlbum($eventDateId: ID!) {
		deleteEventPhotosAlbum(eventDateId: $eventDateId)
	}
`;

// [MUTATION] - Update Region Details

export const MutationUpdateRegionDetails = gql`
	mutation CmsUpdateRegionDetails($data: RegionInput) {
		updateRegion(data: $data) {
			id
		}
	}
`;

// [MUTATION] - Update Ad Units

export const MutationUpdateAdUnits = gql`
	mutation CmsUpdateAdUnits($id: ID!, $code: String!) {
		updateAdUnit(id: $id, code: $code) {
			id
		}
	}
`;

export const MutationCreateFeaturedList = gql`
	mutation CmsCreateFeaturedList($data: FeaturedListInput!) {
		createFeaturedList(data: $data) {
			id
		}
	}
`;

export const MutationDeleteFeaturedList = gql`
	mutation CmsDeleteFeaturedList($id: ID!) {
		deleteFeaturedList(id: $id)
	}
`;
