import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import EditEventCard from 'src/components/ui/events/edit-event-card';
import { ROUTES } from 'src/config/route';
import {
	CmsApproveEventParentMutation,
	CmsApproveEventParentMutationVariables,
	CmsDeleteEventParentMutation,
	CmsDeleteEventParentMutationVariables,
	CmsGetEventParentsQuery,
	CmsGetEventQuery,
	CmsGetEventQueryVariables,
	CmsUpdateEventDateMutation,
	CmsUpdateEventMutation,
	CmsUpdateEventMutationVariables,
} from 'src/lib/generated/_generated';

import {
	MutationApproveEventParent,
	MutationDeleteEventParent,
	MutationUpdateEvent,
} from 'src/lib/gql/mutations';
import { QueryGetEvent } from 'src/lib/gql/queries';

const EditEvent = () => {
	const navigate = useNavigate();
	interface Params {
		id: string;
		[key: string]: string;
	}

	const params = useParams<Params>();

	const {
		data: qryData,
		refetch: refetchQuery,
		loading,
	} = useQuery<CmsGetEventQuery, CmsGetEventQueryVariables>(QueryGetEvent, {
		variables: {
			id: params.id ?? '',
		},
		fetchPolicy: 'cache-and-network',
	});

	const [updateEvent] = useMutation<
		CmsUpdateEventMutation,
		CmsUpdateEventMutationVariables
	>(MutationUpdateEvent, {
		onError(error) {
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [approveEventParent] = useMutation<
		CmsApproveEventParentMutation,
		CmsApproveEventParentMutationVariables
	>(MutationApproveEventParent, {
		onError(error) {
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [deleteEventParent] = useMutation<
		CmsDeleteEventParentMutation,
		CmsDeleteEventParentMutationVariables
	>(MutationDeleteEventParent, {
		onError(error) {
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
		onCompleted() {
			navigate(ROUTES.VIEW_PENDING_EVENTS.path);
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'custom-success',
				customDescription: 'Event deleted successfully',
			});
		},
	});

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header " style={{ display: 'block' }}>
					Edit Event
				</Text>
			</Col>

			{/* Event Component - Props explained in the component */}
			{/* 
				eventName?: string; // Event Name
				eventSlug?: string; //Optional - Link for story
				eventType?: string; // Select Event Type
				eventVenue?: string; // Select Venue for Event
				eventDesc?: string; // Event Information and Description
				eventMusicGenre?: GenreProps[];
				eventOffers?: string; // Event Offers (TinyCME)
				eventDates?: string[]; //Selected Dates
				eventLocation?: string; // How to get there - Google Map URL
				eventBookingURL?: string; // CTA Links -> Booking URL
				eventContactNumber?: string; // CTA Links -> Contact Number
				eventPoster?: string; // Event Poster / Banner
				eventVideoCover?: string; // Event Video Thumbnail
				eventVideoHref?: string; // Event Video Youtube ID
				eventPhotographerRequested?: boolean; // Photographer Requested
				eventFeatured?: boolean; // Event Featured
				canDelete?: boolean;
				onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
				onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
			*/}

			{loading ? (
				<Skeleton active />
			) : (
				<EditEventCard
					onApprove={async (id) => {
						const rsp = await approveEventParent({
							variables: {
								id: id,
							},
						});
						if (rsp.data) {
							refetchQuery();
							CustomNotification({
								pageName: 'Events',
								pagePrefix: 'Event',
								notificationType: 'custom-success',
								customDescription: 'Approved',
							});
						}
					}}
					onDelete={async (id) => {
						const rsp = await deleteEventParent({
							variables: {
								id: id,
							},
						});
					}}
					onSave={async (data) => {
						const rsp = await updateEvent({
							variables: {
								id: params.id ?? '',
								updateEventInput: {
									description: data.description,
									name: data.name,
									posterId: data.posterId || null,
									additionalDates: data?.additionalDates?.map((date) =>
										format(date, 'yyyy-MM-dd'),
									),
									applyChangesTo: data?.applyChangesTo,
									ctaLink: data.ctaLink,
									ctaNumber: data.ctaNumber,
									offers: data.offers,
									videoUrl: data.videoUrl,
								},
							},
						});

						if (rsp.data) {
							refetchQuery();
							CustomNotification({
								pageName: 'Events',
								pagePrefix: 'Event',
								notificationType: 'updated',
							});
						}
					}}
					eventParentId={qryData?.getEventDate?.eventParent?.id || ''}
					eventDateId={params.id}
					eventName={qryData?.getEventDate?.event?.name || ''}
					eventTag={qryData?.getEventDate?.eventParent?.hTag || ''}
					eventBookingURL={qryData?.getEventDate?.event?.ctaLink || undefined}
					eventContactNumber={
						qryData?.getEventDate?.event?.ctaNumber || undefined
					}
					eventDates={qryData?.getEventDate?.eventParent?.eventDates?.map(
						(date) => {
							return {
								id: date.id || '',
								eDate: date.eDate || '',
							};
						},
					)}
					eventFeatured={qryData?.getEventDate?.featured || false}
					eventMusicGenre={qryData?.getEventDate?.eventParent?.genres?.map(
						(genre) => {
							return genre.id;
						},
					)}
					eventOffers={qryData?.getEventDate?.event?.offers || ''}
					eventPhotographerRequested={
						qryData?.getEventDate?.photographer || false
					}
					eventPoster={qryData?.getEventDate?.event?.poster || undefined}
					eventSlug={qryData?.getEventDate?.eventParent?.slug || ''}
					eventVenue={qryData?.getEventDate?.eventParent?.venue?.id}
					eventVideoHref={qryData?.getEventDate?.event?.video?.url}
					eventType={qryData?.getEventDate?.eventParent?.eventTypes?.map(
						(type) => {
							return type.id;
						},
					)}
					eventOccurrenceTime={
						qryData?.getEventDate?.eventParent?.occurrenceTime || ''
					}
					eventDesc={qryData?.getEventDate?.event?.description || ''}
					eventParentName={qryData?.getEventDate?.eventParent?.parentName || ''}
					// eventColor={qryData?.getEventDate?.eventParent?.color || ''}
					canDelete={true}
					isCreatePage={false}
					eventApproved={qryData?.getEventDate?.eventParent?.approved || false}
					eventSource={qryData?.getEventDate?.eventParent?.source || ''}
					eventSourceName={
						(qryData?.getEventDate?.eventParent?.eventSource?.fName || '') +
						' ' +
						(qryData?.getEventDate?.eventParent?.eventSource?.lName || '')
					}
					eventSourceEmail={
						qryData?.getEventDate?.eventParent?.eventSource?.email || ''
					}
					eventSourcePhone={
						qryData?.getEventDate?.eventParent?.eventSource?.phone || ''
					}
					eventMeta={qryData?.getEventDate?.eventParent?.meta || ''}
				/>
			)}
		</Row>
	);
};

export default EditEvent;
