import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import { useCallback, useRef, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import EmbeddedInput from 'src/components/ui/embedded-input';
import MediaUpload from 'src/components/ui/media-upload';
import MultiDateSelector from 'src/components/ui/multi-date';
import { IntroForm } from 'src/lib/form-interfaces';
import { Media, MediaFoldersNames } from 'src/lib/interface';

interface Props {
	introName?: string;
	introThumbnail?: Media;
	introVideoUrl?: string;
	introDates?: string[];
	canDelete?: boolean;
	onDelete?: React.MouseEventHandler<HTMLElement>;
	onSave: ({ name, videoUrl, thumbnailId, dates }: IntroForm) => void;
}

const IntroCard = (props: Props) => {
	const [videoThumbnailId, setVideoThumbnailId] = useState<Media | undefined>(
		props.introThumbnail,
	);

	const selectedDays = useRef<Date[]>([]);

	const [form] = useForm<IntroForm>();

	const createIntro = (data: IntroForm) => {
		if (videoThumbnailId === null || videoThumbnailId === undefined) {
			CustomNotification({
				pageName: 'Intro Videos',
				errorName: 'Video Thumbnail',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			name: data.name,
			dates: selectedDays.current,
			thumbnailId: videoThumbnailId.id,
			videoUrl: data.videoUrl,
		});
	};

	const mediaCallback = useCallback((media: Media) => {
		setVideoThumbnailId(media);
	}, []);

	return (
		<Form
			form={form}
			onFinish={createIntro}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%' }} gutter={16}>
				<Col span={24} xl={12}>
					<MediaUpload
						folder={MediaFoldersNames.misc}
						onMediaUploaded={mediaCallback}
						media={props.introThumbnail || undefined}
						accept="IMAGE"
						placeholderText={'Drag and drop a video thumbnail image'}
						height={'100%'}
					/>
				</Col>

				<Col span={24} xl={12}>
					<label className="input-fields-label">Name / Title</label>

					<Form.Item
						name="name"
						initialValue={props.introName}
						rules={[{ required: true }]}
						hasFeedback
					>
						<Input
							type="text"
							placeholder="Name / Title"
							className="input-fields"
							style={{
								display: 'block',
							}}
						/>
					</Form.Item>

					<label className="input-fields-label">Youtube Video ID</label>

					<Form.Item
						name="videoUrl"
						initialValue={props.introVideoUrl}
						rules={[{ required: true }]}
					>
						{/* <Input
							type="text"
							placeholder="Youtube Video ID (E.G: 5qap5aO4i9A)"
							className="input-fields"
						/> */}
						<EmbeddedInput link={props.introVideoUrl} />
					</Form.Item>

					<Text style={{ display: 'block', marginTop: '1em' }} strong={true}>
						Dates
					</Text>
					<Text style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}>
						You can click on multiple dates to select the days to run the video
						for that duration
					</Text>

					<Form.Item name="dates" rules={[{ required: true }]}>
						<MultiDateSelector
							initialDates={props.introDates || []}
							onInit={(dates) => {
								selectedDays.current = dates;
							}}
							onChange={(dates) => {
								selectedDays.current = dates;
								// console.log(dates);
							}}
						/>
					</Form.Item>

					<hr style={{ opacity: '0.25' }} />

					<Row justify={'end'}>
						<Space size="small">
							<Button className="btn-main save" htmlType={'submit'}>
								Save
							</Button>

							{!props.canDelete ? (
								<Button
									className="btn-main"
									onClick={() => {
										window.history.back();
									}}
								>
									Cancel
								</Button>
							) : (
								<Button className="btn-main delete" onClick={props.onDelete}>
									Delete
								</Button>
							)}
						</Space>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

export default IntroCard;
