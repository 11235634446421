import { Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

const TagInput = React.memo((props: any) => {
	const { onChange, value, onInit, prefix } = props;
	const [tag, setTag] = useState<string>('');
	useEffect(() => {
		let newV: string = value || '';
		if (newV.startsWith(prefix)) {
			newV = newV.substr(prefix.length, value.length);
		}
		setTag(newV);
		if (newV.length > 0) onChange(stringToTag(prefix + stringToTag(newV)));
		// else onChange(undefined);
	}, []);

	const fnOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		let val = e.target.value;
		val = val.replace(/^\s+|\s+$/g, ''); // trim

		if (val.length != 0) {
			val = val.charAt(0).toUpperCase() + val.slice(1);

			val = val
				.replace(/[^0-9a-zA-Z ,-]/g, '') // remove invalid chars
				.replace(/\s+/g, '-') // collapse whitespace and replace by -
				.replace(/,+/g, '-')
				.replace(/-+/g, '-'); // collapse dashes
			onChange(stringToTag(prefix + stringToTag(val)));
		} else onChange(undefined);
		setTag(val);
	}, []);

	return (
		<Input
			onChange={fnOnChange}
			prefix={prefix}
			type="text"
			value={tag}
			placeholder="Tag (E.G Brunch)"
			className="input-fields things-to-do-prefix"
		/>
	);
});

export default TagInput;

function stringToTag(val: string) {
	if (val.length > 0) {
		val = val.replace(/^\s+|\s+$/g, ''); // trim

		val = val.charAt(0).toUpperCase() + val.slice(1);

		val = val
			.replace(/[^0-9a-zA-Z ,-]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/,+/g, '-')
			.replace(/-+/g, '-'); // collapse dashes
	}
	return val;
}
