import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { CategoryForm } from 'src/lib/form-interfaces';
import SlugInput from '../slug-input';

const { Option } = Select;

interface Props {
	categoryName?: string;
	categoryWeight?: number;
	categorySlug?: string;
	isNewsCategory?: boolean;

	canDelete?: boolean;
	onDelete?: React.MouseEventHandler<HTMLElement>;
	onSave: ({ name, weight }: CategoryForm) => void;
}

const CategoryCard = (props: Props) => {
	const [form] = useForm<CategoryForm>();

	const createCategory = (data: CategoryForm) => {
		props.onSave({
			name: data.name || '',
			weight: data.weight || null,
			slug: data.slug || '',
		});
	};

	return (
		<Form
			form={form}
			onFinish={createCategory}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%' }} gutter={16}>
				<Col span={24} lg={8}>
					<label className="input-fields-label">Name / Title</label>

					<Form.Item
						rules={[{ required: true }]}
						name="name"
						hasFeedback
						initialValue={props.categoryName}
					>
						<Input
							type="text"
							placeholder="Name / Title"
							className="input-fields"
						/>
					</Form.Item>
				</Col>

				<Col span={24} lg={8}>
					<label className="input-fields-label">Position</label>

					<Form.Item
						name="weight"
						hasFeedback
						initialValue={props.categoryWeight}
						rules={[
							{
								validator: (rule, value: string, back) => {
									if (value !== undefined) {
										if (value.length > 4) {
											back('Too large');
											return;
										}
										if (value !== '' && parseInt(value) < 1) {
											back('Minimum value is 1');
											return;
										}
									}
									back(undefined);
									return;
								},
							},
						]}
					>
						<Input
							type="number"
							min={1}
							placeholder="Position / Sequence No."
							className="input-fields"
						/>
					</Form.Item>
				</Col>

				{props.isNewsCategory && (
					<Col span={24}>
						<label className="input-fields-label">Slug</label>

						<Form.Item
							rules={[{ required: true }]}
							name="slug"
							hasFeedback
							initialValue={props.categorySlug}
						>
							<SlugInput />
						</Form.Item>
					</Col>
				)}

				<Col span={24}>
					<hr style={{ opacity: '0.25' }} />

					<Row justify={'end'}>
						<Space size="small">
							<Button className="btn-main save" htmlType={'submit'}>
								Save
							</Button>

							{!props.canDelete ? (
								<Button
									className="btn-main"
									onClick={() => {
										window.history.back();
									}}
								>
									Cancel
								</Button>
							) : (
								<Button className="btn-main delete" onClick={props.onDelete}>
									Delete
								</Button>
							)}
						</Space>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

export default CategoryCard;
