import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import { MutationCreateVenue } from 'src/lib/gql/mutations';
import { QueryGetVenues } from 'src/lib/gql/queries';
import VenueCard from '../../components/ui/venues/venue-card';
import {
	CmsCreateVenueMutation,
	CmsCreateVenueMutationVariables,
	CmsGetVenuesQuery,
} from 'src/lib/generated/_generated';

const CreateVenue = () => {
	const navigate = useNavigate();

	const [createV] = useMutation<
		CmsCreateVenueMutation,
		CmsCreateVenueMutationVariables
	>(MutationCreateVenue, {
		onError(error) {
			CustomNotification({
				pageName: 'Venues',
				pagePrefix: 'Venue',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const {
		data: qryData,
		refetch: refetchQuery,
		loading: qryLoading,
	} = useQuery<CmsGetVenuesQuery>(QueryGetVenues, {
		fetchPolicy: 'cache-and-network',
	});

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header" style={{ display: 'block' }}>
					Create a Venue
				</Text>
			</Col>

			{/* Venue Component - Props explained in the component */}
			{/* 
		venueName?: string; // Event Name
		venueSlug?: string; //Optional - Link for story
		venueType?: string; // Select Event Type
		venueInfo?: string; // Event Information and Description
		venueFamousFor?: string;
		venueLocation?: string; // How to get there - Google Map URL
		venueLocationLongitude?: string; // How to get there - Google Map URL
		venueLocationLatitude?: string; // How to get there - Google Map URL
		venueBookingURL?: string; // CTA Links -> Booking URL
		venueContactNumber?: string; // CTA Links -> Contact Number
		venueLogo?: string; // Event Poster / Banner
		venueImages?: string; // Event Video Thumbnail
		canDelete?: boolean;
		onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
		onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
			*/}
			{qryLoading ? (
				<Skeleton active />
			) : (
				<VenueCard
					onSave={async (data) => {
						const rsp = await createV({
							variables: {
								name: data.name,
								hTag: data.htag || '',
								slug: data.slug,
								ctaLink: data.ctaLink || null,
								ctaNumber: data.ctaNumber || null,
								logoId: data.logoId || null,
								coverImageId: data.coverImageId || null,
								mediaIds: data.mediaIds,
								menuIds: data.menuIds,
								map: data.map,
								dateTiming: data.dateTiming,
								dressCode: data.dressCode,
								info: data.info,
								latitude: data.latitude,
								longitude: data.longitude,
								meta: data.meta || '',
								partner: data.partner,
								venueTypeIds: data.venueTypeIds,
								areaId: data.areaId,
								verified: data.verified,
								famousFor: data.famousFor,
								weight: data.numberWight,
								virtualTour: data.virtualTour,
								status: data.status,
								properName: data.properName,
							},
						});

						if (rsp.data) {
							CustomNotification({
								pageName: 'Venues',
								pagePrefix: 'Venue',
								notificationType: 'created',
							});

							navigate(
								`${ROUTES.EDIT_VENUE.path}/${rsp.data?.createVenue?.id}`,
							);
						}
					}}
				/>
			)}
		</Row>
	);
};

export default CreateVenue;
