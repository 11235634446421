import { LinkOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

const SlugInput = React.memo((props: any) => {
	const { onChange, value, onInit } = props;
	const [slug, setSlug] = useState<string>('');

	useEffect(() => {
		setSlug(stringToSlug(value || ''));
	}, []);

	const fnOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		const nSlug = stringToSlug(e.target.value);
		setSlug(nSlug);
		onChange(nSlug);
	}, []);

	return (
		<Input
			prefix={<LinkOutlined />}
			type="text"
			onChange={fnOnChange}
			placeholder="Slug"
			className="input-fields"
			value={slug}
		/>
	);
});

function stringToSlug(str: string): string {
	str = str.replace(/^\s+|\s+$/g, ''); // trim
	str = str.toLowerCase();

	// remove accents, swap ñ for n, etc
	const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
	const to = 'aaaaaaeeeeiiiioooouuuunc------';

	for (let i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	str = str
		.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by -
		.replace(/-+/g, '-'); // collapse dashes

	return str;
}

export default SlugInput;
