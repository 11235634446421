import { useQuery } from '@apollo/client';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Space,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useRef, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import VenueSelectDropDown from 'src/components/venue-selector';
import { OffersForm } from 'src/lib/form-interfaces';
import { QueryGetOfferTypes } from 'src/lib/gql/queries';
import { Media, MediaFoldersNames } from 'src/lib/interface';
import MediaUpload from '../media-upload';
import MultiDateSelector from '../multi-date';
import SelectTags from '../select-tags';
import { CmsGetOfferTypesQuery } from 'src/lib/generated/_generated';

interface Props {
	offerImage?: Media; // Offer Image
	offerName?: string; // Offer Name
	offerDescription?: string; // Offer Description
	offerVenue?: string; // Offer Venue
	offerDates?: string[]; //Selected Dates
	offerPosition?: number | undefined;
	offerTypeId?: string;
	offerTags?: string[];
	offerApproved?: boolean;
	offerSourceEmail?: string;
	offerSourcePhone?: string;
	offerSourceName?: string;
	canDelete?: boolean;
	onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
	onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
	onSave: ({
		name,
		description,
		venueId,
		mediaId,
		weight,
		dates,
		tags,
		offerTypeId,
		enabled,
	}: {
		name: string;
		description: string;
		venueId: string;
		mediaId: string;
		weight: string;
		dates: Date[];
		tags: string[];
		offerTypeId: string;
		enabled?: boolean;
	}) => void;
	onApprove?: () => void;
}
const OfferCard = (props: Props) => {
	// const [selectedDay, setSelectedDay] = useState([]);
	const selectedDays = useRef<Date[]>([]);
	const [mediaId, setMediaId] = useState<string | undefined>(undefined);
	const selectedHTags = useRef<string[]>([]);

	const [form] = useForm<OffersForm>();
	const { Option } = Select;

	const { data: offerQryData } =
		useQuery<CmsGetOfferTypesQuery>(QueryGetOfferTypes);

	if (mediaId == null && props.offerImage?.id) {
		setMediaId(props.offerImage?.id);
	}

	const createOffer = (data: OffersForm) => {
		if (mediaId === null || mediaId === undefined) {
			CustomNotification({
				pageName: 'Offers',
				errorName: 'Image',
				notificationType: 'error',
			});
			return;
		}

		if (selectedDays.current.length === 0) {
			CustomNotification({
				pageName: 'Offers',
				errorName: 'Date',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			name: data.name,
			description: data.description,
			mediaId: mediaId,
			weight: data.weight,
			venueId: data.venueId,
			dates: selectedDays.current,
			tags: selectedHTags.current,
			offerTypeId: data.offerTypeId,
		});
	};

	const mediaCallback = useCallback((media) => {
		setMediaId(media.id);
	}, []);

	return (
		<Form
			form={form}
			onFinish={createOffer}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%' }} gutter={16}>
				{!props.offerApproved && (
					<>
						<Card style={{ width: '100%' }}>
							<Text className="sub-header">Approve Event</Text>
							<Col
								span={24}
								style={{
									marginBottom: '1em',
								}}
							>
								<Divider
									style={{
										margin: 0,
									}}
								/>
							</Col>

							<Col span={24} lg={12}>
								<Input
									type="text"
									placeholder="Name Name"
									className="input-fields"
									style={{
										padding: '1em',
										background: 'rgba(0,0,0,0.2)',
									}}
									value={props.offerSourceName}
									disabled
								/>
							</Col>

							<Col span={24} lg={12}>
								<Input
									type="text"
									placeholder="Email"
									className="input-fields"
									style={{
										padding: '1em',
										background: 'rgba(0,0,0,0.2)',
									}}
									value={props.offerSourceEmail}
									disabled
								/>
							</Col>

							<Col span={24} lg={12}>
								<Input
									type="text"
									placeholder="Telephone Number"
									className="input-fields"
									style={{
										padding: '1em',
										background: 'rgba(0,0,0,0.2)',
									}}
									value={props.offerSourcePhone}
									disabled
								/>
							</Col>
							<Row>
								<Button
									onClick={() => {
										if (!confirm('Confirm Approve?')) return;
										if (props.onApprove) {
											props.onApprove();
										}
									}}
									ghost
									type="primary"
									style={{
										textTransform: 'uppercase',
									}}
								>
									Approve
								</Button>
							</Row>
						</Card>
					</>
				)}
				<Col span={24}>
					<MediaUpload
						folder={MediaFoldersNames.misc}
						onMediaUploaded={mediaCallback}
						media={props.offerImage || undefined}
						accept="IMAGE"
						placeholderText={'Drag and drop an offer image here.'}
					/>
				</Col>

				{/* Input Fields */}
				<Col span={24} md={16}>
					<Row align="middle" gutter={15}>
						<Col span={24} md={16}>
							<label className="input-fields-label">Name / Title</label>

							<Form.Item
								name="name"
								initialValue={props.offerName}
								rules={[{ required: true }]}
								hasFeedback
							>
								<Input
									type="text"
									placeholder="Offer Name / Title"
									className="input-fields"
								/>
							</Form.Item>
						</Col>
						<Col span={24} md={8}>
							{/* Position */}
							<label className="input-fields-label">Position</label>

							<Form.Item
								name="weight"
								initialValue={props.offerPosition}
								rules={[
									{
										validator: (rule, value: string, back) => {
											if (value !== undefined) {
												if (value.length > 4) {
													back('Too large');
													return;
												}
												if (value !== '' && parseInt(value) < 1) {
													back('Minimum value is 1');
													return;
												}
											}
											back(undefined);
											return;
										},
									},
								]}
							>
								<Input
									type="number"
									min={1}
									placeholder="Position / Sequence No."
									className="input-fields"
								/>
							</Form.Item>
						</Col>
					</Row>
					<label className="input-fields-label">Description</label>

					<Form.Item
						name="description"
						initialValue={props.offerDescription}
						rules={[{ required: true }]}
						hasFeedback
					>
						<Input
							type="text"
							placeholder="Offer Description"
							className="input-fields"
						/>
					</Form.Item>
					<label className="input-fields-label">Venue</label>

					<Form.Item
						name="venueId"
						initialValue={props.offerVenue}
						rules={[{ required: true }]}
						hasFeedback
					>
						<VenueSelectDropDown />
						{/* <Select
							allowClear
							style={{ width: '100%' }}
							placeholder="Select a Venue"
							size="middle"
							className="bg-select"
							filterOption={false}
							showSearch
							onSearch={(s) => setVenueName(s)}
						>
							{qryData?.pagination?.venues?.result?.map((venue, i) => {
								return (
									<Option key={`venuname ${i}`} value={venue?.id || ''}>
										{venue?.name}
									</Option>
								);
							})}
						</Select> */}
					</Form.Item>
					<label className="input-fields-label">Offer Category</label>

					<Form.Item
						name="offerTypeId"
						initialValue={props.offerTypeId}
						rules={[{ required: true }]}
						hasFeedback
					>
						<Select
							allowClear
							style={{ width: '100%' }}
							placeholder="Select an offer category"
							size="middle"
							className="bg-select"
						>
							{offerQryData?.getOfferTypes?.map((types, i) => {
								return (
									<Option key={`offerType ${i}`} value={types?.id || ''}>
										{types?.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>

					<Col
						style={{
							position: 'relative',

							padding: '0',
						}}
					>
						<label className="input-fields-label">Page Tags (Optional)</label>

						<SelectTags
							filter={'ThingsToDo'}
							placeholder="Select Tags"
							initialValues={props.offerTags || []}
							onSelectionChange={(tags) => {
								selectedHTags.current = tags;
							}}
							onInit={(tags) => (selectedHTags.current = tags)}
						/>
					</Col>
				</Col>

				<Col span={8}>
					<Text style={{ display: 'block', marginTop: '1em' }} strong={true}>
						Dates
					</Text>
					<Text style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}>
						You can click on multiple dates to select the days to run the offer
						for that duration
					</Text>

					<MultiDateSelector
						initialDates={props.offerDates || []}
						onInit={(dates) => {
							selectedDays.current = dates;
						}}
						onChange={(dates) => (selectedDays.current = dates)}
					/>
				</Col>

				<Col span={24}>
					<hr style={{ opacity: '0.25' }} />

					<Row justify={'end'}>
						<Space size="small">
							<Button className="btn-main save" htmlType="submit">
								Save
							</Button>

							{props.canDelete ? (
								<Button className="btn-main delete" onClick={props.onDelete}>
									Delete
								</Button>
							) : (
								<Button
									className="btn-main"
									onClick={() => {
										window.history.back();
									}}
								>
									Cancel
								</Button>
							)}
						</Space>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

export default OfferCard;
