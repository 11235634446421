import { LinkOutlined } from '@ant-design/icons';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Space,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { UpdateEventsForm } from 'src/lib/form-interfaces';
import { EventDate, Media, MediaFoldersNames } from 'src/lib/interface';
import EditorComp from '../editor';
import EmbeddedInput from '../embedded-input';
import MediaUpload from '../media-upload';
import MultiDateSelector from '../multi-date';
import EventDateCard from './event-date-card';
import EventParentCard from './event-parent-card';

interface Props {
	eventParentId?: string;
	eventDateId?: string;
	eventName?: string; // Event Name
	eventParentName?: string; // Event Group Name
	eventSlug?: string; //Optional - Link for story
	eventTag?: string; // Event Tag
	eventType?: string[]; // Select Event Type
	eventVenue?: string; // Select Venue for Event
	eventDesc?: string; // Event Information and Description
	eventMeta?: string; // Event Information and Description
	eventMusicGenre?: string[];
	eventOffers?: string; // Event Offers (TinyCME)
	eventDates?: EventDate[]; //Selected Dates

	// eventLocation?: string; // How to get there - Google Map URL
	eventBookingURL?: string | undefined; // CTA Links -> Booking URL
	eventContactNumber?: string | undefined; // CTA Links -> Contact Number
	eventPoster?: Media; // Event Poster / Banner
	eventVideoHref?: string | undefined; // Event Video Youtube ID
	eventPhotographerRequested?: boolean; // Photographer Requested
	eventFeatured?: boolean; // Event Featured
	canDelete?: boolean;
	isCreatePage?: boolean;
	isActivationPage?: boolean; // Only for Event Activation
	parentFirstName?: string;
	parentLastName?: string;
	parentTel?: string;
	parentEmail?: string;
	eventOccurrenceTime?: string;
	eventColor?: string;
	onDelete?: (id: string) => void; // On Delete Click
	onSave: (data: {
		description: string;
		name: string;
		posterId?: string | undefined;
		additionalDates: Date[] | undefined;
		applyChangesTo: string[] | undefined;
		ctaLink: string | undefined;
		ctaNumber: string | undefined;
		offers: string | undefined;
		videoUrl: string | undefined;
	}) => void;

	///

	eventApproved?: boolean;
	eventSource?: string;
	eventSourceName?: string;
	eventSourceEmail?: string;
	eventSourcePhone?: string;

	onApprove?: (id: string) => void;
}

const EditEventCard = (props: Props) => {
	const [coverPhotoId, setCoverPhotoId] = useState<string | undefined>(
		undefined || props.eventPoster?.id,
	);

	const params = useParams<{ id: string }>();

	const [form] = useForm<UpdateEventsForm>();

	const selectedDays = useRef<Date[]>([]);

	const { Option } = Select;

	// const {
	// 	data: eventQuery,
	// 	loading: eventChangedLoading,
	// 	refetch: refetchQuery,
	// } = useQuery<GetEvent, GetEventVariables>(QueryGetEvent, {
	// 	variables: {
	// 		id: params.id,
	// 	},
	// 	fetchPolicy: 'cache-and-network',
	// });

	const [selectedEd, setSelectedEd] = useState<string[]>([]);

	/* Event Media handler */
	const mediaCallback = useCallback((media: Media) => {
		setCoverPhotoId(media.id);
	}, []);

	const submitCallBack = useCallback(
		async (data: UpdateEventsForm) => {
			// if (data.newDate) console.log(format(data.newDate, 'yyyy-MM-dd'));
			// const rsp = await UpdateEventDate({
			// 	variables: {
			// 		id: props.eventDateId,
			// 		featured: data.featured,
			// 		photographer: data.photographer,
			// 		eDate: data.newDate ? format(data.newDate, 'yyyy-MM-dd') : undefined,
			// 	},
			// });

			// if (coverPhotoId === null || coverPhotoId === undefined) {
			// 	CustomNotification({
			// 		pageName: 'Events',
			// 		errorName: 'Cover Image',
			// 		notificationType: 'error',
			// 	});

			// 	return;
			// }
			props.onSave({
				additionalDates: data.additionalDates,
				applyChangesTo: data.applyChangesTo,
				ctaLink: data.ctaLink,
				ctaNumber: data.ctaNumber,
				description: data.description || '',
				name: data.name || '',
				offers: data.offers,
				posterId: coverPhotoId || undefined,
				videoUrl: data.videoUrl,
			});
		},
		[coverPhotoId],
	);

	return (
		<>
			{!props.eventApproved && (
				<>
					<Card style={{ width: '100%' }}>
						<Text className="sub-header">Approve Event</Text>
						<Col
							span={24}
							style={{
								marginBottom: '1em',
							}}
						>
							<Divider
								style={{
									margin: 0,
								}}
							/>
						</Col>

						<Col span={24} lg={12}>
							<Input
								type="text"
								placeholder="Name Name"
								className="input-fields"
								style={{
									padding: '1em',
									background: 'rgba(0,0,0,0.2)',
								}}
								value={props.eventSourceName}
								disabled
							/>
						</Col>

						<Col span={24} lg={12}>
							<Input
								type="text"
								placeholder="Email"
								className="input-fields"
								style={{
									padding: '1em',
									background: 'rgba(0,0,0,0.2)',
								}}
								value={props.eventSourceEmail}
								disabled
							/>
						</Col>

						<Col span={24} lg={12}>
							<Input
								type="text"
								placeholder="Telephone Number"
								className="input-fields"
								style={{
									padding: '1em',
									background: 'rgba(0,0,0,0.2)',
								}}
								value={props.eventSourcePhone}
								disabled
							/>
						</Col>
						<Row>
							<Button
								onClick={() => {
									if (!confirm('Confirm Approve?')) return;
									if (props.onApprove) {
										props.onApprove(props.eventParentId || '');
									}
								}}
								ghost
								type="primary"
								style={{
									textTransform: 'uppercase',
								}}
							>
								Approve
							</Button>
							<Button
								onClick={() => {
									if (!confirm('Confirm Delete?')) return;
									if (props.onDelete) {
										props.onDelete(props.eventParentId || '');
									}
								}}
								ghost
								type="primary"
								danger
								style={{
									textTransform: 'uppercase',
								}}
							>
								Delete
							</Button>
						</Row>
					</Card>
				</>
			)}

			<Row
				style={{ width: '100%', marginTop: '1em' }}
				align="bottom"
				gutter={16}
			>
				<EventDateCard
					eventDateId={props.eventDateId || ''}
					eventDates={props.eventDates || []}
					eventFeatured={props.eventFeatured}
					eventPhotographer={props.eventPhotographerRequested}
				/>

				{/* Name / Title */}

				{!props.eventApproved && <></>}

				{/* DIVIDER - EVENT OFFERS / MUSIC GENRE */}
				<Col
					span={24}
					style={{
						marginBottom: '1em',
					}}
				>
					<Text className="sub-header">Event Parent Information</Text>
					<Divider
						style={{
							margin: 0,
						}}
					/>
				</Col>
				<EventParentCard
					eventParentId={props.eventParentId || ''}
					eventParentName={props.eventParentName}
					eventTag={props.eventTag}
					eventSlug={props.eventSlug}
					eventOccurrenceTime={props.eventOccurrenceTime}
					eventType={props.eventType}
					eventMusicGenre={props.eventMusicGenre}
					eventVenue={props.eventVenue}
					eventColor={props.eventColor}
					eventMeta={props.eventMeta}
				/>
				<Form
					form={form}
					onFinish={submitCallBack}
					style={{
						width: '100%',
					}}
				>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexWrap: 'wrap',
						}}
					>
						{/* DIVIDER - EVENT OFFERS / MUSIC GENRE */}
						<Col
							span={24}
							style={{
								marginBottom: '1em',
							}}
						>
							<Text className="sub-header">Event Information</Text>
						</Col>

						<Col span={24}>
							<label className="input-fields-label">Event Name</label>

							<Form.Item
								rules={[
									{
										required: true,
									},
								]}
								initialValue={props.eventName}
								name="name"
							>
								<Input
									type="text"
									placeholder="Event Name"
									className="input-fields"
									style={{
										padding: '1em',
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={24} lg={12}>
							<label className="input-fields-label">
								Event Info / Description
							</label>

							<Form.Item
								rules={[
									{
										required: true,
									},
								]}
								initialValue={props.eventDesc}
								name="description"
							>
								<EditorComp
									initialValue={props.eventDesc || 'Event Info / Description'}
								/>
							</Form.Item>
						</Col>

						<Col span={24} lg={12}>
							<Text
								style={{ display: 'block', marginTop: '1em' }}
								strong={true}
							>
								Dates
							</Text>
							<Text
								style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}
							>
								{props.canDelete ? (
									<>Run the event additionally for the selected dates</>
								) : (
									<>
										You can click on multiple dates to select the days to run
										the even for that duration
									</>
								)}
							</Text>
							<Form.Item name="additionalDates">
								<MultiDateSelector
									initialDates={[]}
									disabledDays={props.eventDates?.map((d) => d.eDate) || []}
									onChange={() => {
										//
									}}
								/>
							</Form.Item>

							<Col span={24}>
								<p
									style={{
										margin: 0,
										textTransform: 'uppercase',
										fontFamily: 'Avenir Heavy',
									}}
								>
									APPLY CHANGES TO
								</p>
								<p
									style={{
										margin: 0,

										fontFamily: 'Avenir Medium',
										opacity: 0.7,
										fontSize: '11px',
									}}
								>
									Modifications will apply to all selected dates
								</p>
								<Form.Item name="applyChangesTo">
									<Select
										suffixIcon={null}
										showSearch={false}
										mode="multiple"
										style={{ width: '100%' }}
										placeholder="Select date to change information for"
										size="middle"
										className="multiple-custom-select"
									>
										{props.eventDates?.map((eds, i) => {
											if (eds.id === params.id) return <></>;
											return (
												<Option key={`eventChanges:${i}`} value={eds.id || ''}>
													{eds.eDate}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
						</Col>

						{/* Offers / Music Genre */}

						{/* DIVIDER - EVENT OFFERS / MUSIC GENRE */}
						<Col
							span={24}
							style={{
								marginTop: '1.5em',
							}}
						>
							<Text className="sub-header">Event Offers</Text>
							<Divider
								style={{
									margin: 0,
								}}
							/>
						</Col>

						<Col
							span={24}
							style={{
								marginTop: '1em',
							}}
						>
							<Row
								className="input-fields"
								style={{
									marginBottom: '0',
								}}
							>
								<label className="input-fields-label">Offers</label>

								<Form.Item
									name="offers"
									initialValue={props.eventOffers}
									style={{
										width: '100%',
									}}
								>
									<EditorComp value={props.eventOffers || 'Offers'} />
								</Form.Item>
							</Row>
						</Col>

						{/* END - Offers / Music Genre */}

						{/* DIVIDER - EVENT ADDITIONAL INFO */}

						<Col
							span={24}
							style={{
								margin: '0.5em 0',
							}}
						>
							<Text className="sub-header">Event Additional Information</Text>
							<Divider
								style={{
									margin: 0,
								}}
							/>
						</Col>

						{/* Event Location */}

						<Col span={24} md={16}>
							<label className="input-fields-label">Booking URL</label>

							<Form.Item initialValue={props.eventBookingURL} name="ctaLink">
								<Input
									prefix={<LinkOutlined />}
									type="text"
									placeholder="Booking URL"
									className="input-fields"
									value={props.eventBookingURL}
								/>
							</Form.Item>
						</Col>

						<Col span={24} md={8}>
							<label className="input-fields-label">Contact Number</label>

							<Form.Item
								initialValue={props.eventContactNumber}
								name="ctaNumber"
							>
								<Input
									type="tel"
									placeholder="Contact Number"
									className="input-fields"
									value={props.eventContactNumber}
								/>
							</Form.Item>
						</Col>

						{/* Event Poster / Banner */}

						{/* DIVIDER - EVENT MEDIA */}
						<Col
							span={24}
							style={{
								margin: '0.5em 0',
							}}
						>
							<Text className="sub-header">Event Media</Text>
							<Divider
								style={{
									margin: 0,
								}}
							/>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								// rules={[
								// 	{
								// 		required: true,
								// 	},
								// ]}
								initialValue={props.eventPoster}
								name="poster"
							>
								<MediaUpload
									folder={MediaFoldersNames.misc}
									onMediaUploaded={mediaCallback}
									media={props.eventPoster}
									accept="IMAGE"
									placeholderText={'Drag and drop an Event Poster image'}
								/>
							</Form.Item>
						</Col>
						<Col span={24} lg={12}>
							<Form.Item initialValue={props.eventVideoHref} name="videoUrl">
								<EmbeddedInput link={props.eventVideoHref} height="350px" />
								{/* <Input
								className="input-fields"
								type="text"
								placeholder="Youtube Video ID"
							/> */}
							</Form.Item>
						</Col>

						<Col span={24}>
							<hr style={{ opacity: '0.25' }} />

							<Row justify={'center'}>
								<Space size="small">
									<Button className="btn-main save" htmlType="submit">
										Update
									</Button>
								</Space>
							</Row>
						</Col>
					</div>
				</Form>
				{/* )} */}
			</Row>
		</>
	);
};

export default EditEventCard;
