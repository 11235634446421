import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
	StarFilled,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Select, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import {
	AddEventPhotographerComment,
	CreateEventPhotographers,
	DeleteEventPhotographerId,
	MutationDeleteEventDate,
	MutationUpdateEventDate,
} from 'src/lib/gql/mutations';
import {
	QueryGetEvents,
	QueryGetPhotographers,
	VenueNames,
} from 'src/lib/gql/queries';
import { EventTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';
import {
	CmsAddEventPhotographerCommentMutationMutation,
	CmsAddEventPhotographerCommentMutationMutationVariables,
	CmsCreateEventPhotographersMutationMutation,
	CmsCreateEventPhotographersMutationMutationVariables,
	CmsDeleteEventDateMutation,
	CmsDeleteEventDateMutationVariables,
	CmsDeleteEventPhotographerIdMutationMutation,
	CmsDeleteEventPhotographerIdMutationMutationVariables,
	CmsGetPaginationEventDatesQuery,
	CmsGetPaginationEventDatesQueryVariables,
	CmsGetPhotographersQuery,
	CmsUpdateEventDateMutation,
	CmsUpdateEventDateMutationVariables,
	CmsVenueNamesQueryQuery,
} from 'src/lib/generated/_generated';

const Events = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [eventName, setEventName] = useState<string>('');
	const [eventVenueName, setEventVenueName] = useState<string>('');

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<
		CmsGetPaginationEventDatesQuery,
		CmsGetPaginationEventDatesQueryVariables
	>(QueryGetEvents, {
		fetchPolicy: 'cache-and-network',
		variables: {
			pageNumber: pageNumber,
			perPage: perPage,
			name: eventName,
			// venueId: venueId,
			venueName: eventVenueName,
		},
	});

	const { data: qryVenues } = useQuery<CmsVenueNamesQueryQuery>(VenueNames, {
		fetchPolicy: 'cache-and-network',
	});

	const { data: qryPhotographers } = useQuery<CmsGetPhotographersQuery>(
		QueryGetPhotographers,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	// const {
	// 	data: qryPhotographerData1,
	// 	loading: photographerLoading,
	// 	refetch: refetchPhotographerQuery,
	// } = useQuery<GetPhotographerEvents>(QueryGetEventPhotographers);

	// ! Photographer Module

	const comments = useRef<string[]>([]);

	const [addEventPhotographerComment] = useMutation<
		CmsAddEventPhotographerCommentMutationMutation,
		CmsAddEventPhotographerCommentMutationMutationVariables
	>(AddEventPhotographerComment, {
		onError(error) {
			CustomNotification({
				pageName: 'Photographer',
				pagePrefix: 'Photographer',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [createEventPhotographers] = useMutation<
		CmsCreateEventPhotographersMutationMutation,
		CmsCreateEventPhotographersMutationMutationVariables
	>(CreateEventPhotographers, {
		onError(error) {
			CustomNotification({
				pageName: 'Photographer',
				pagePrefix: 'Photographer',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [deleteEventPhotographerId] = useMutation<
		CmsDeleteEventPhotographerIdMutationMutation,
		CmsDeleteEventPhotographerIdMutationMutationVariables
	>(DeleteEventPhotographerId, {
		onError(error) {
			CustomNotification({
				pageName: 'Photographer',
				pagePrefix: 'Photographer',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteEventPhotographerIdFn = useCallback(
		async (photographerId: string, eventDateId: string) => {
			if (!confirm('Are you sure you want to delete?')) return;

			const rsp = await deleteEventPhotographerId({
				variables: {
					eventDateId: eventDateId,
					photographerId: photographerId,
				},
			});
			if (rsp.data)
				CustomNotification({
					pageName: 'Photographer',
					pagePrefix: 'Photographer',
					notificationType: 'deleted',
				});
			refetchQuery();
		},
		[],
	);

	const addEventPhotographerCommentFn = useCallback(
		async (id: string, comment: string) => {
			const rsp = await addEventPhotographerComment({
				variables: {
					comment: comment,
					id: id,
				},
			});
			if (rsp.data)
				CustomNotification({
					pageName: 'Comment',
					pagePrefix: 'Comment',
					notificationType: 'updated',
				});
		},
		[],
	);
	const createEventPhotographersFn = useCallback(
		async (eventDateId: string, photographerIds: string[]) => {
			const rsp = await createEventPhotographers({
				variables: {
					data: {
						eventDateId: eventDateId,
						photographerIds: photographerIds,
					},
				},
			});
			if (rsp.data)
				CustomNotification({
					pageName: 'Photographer',
					pagePrefix: 'Photographer',
					notificationType: 'created',
				});
			refetchQuery();
		},
		[],
	);

	const photographerOptions = qryPhotographers?.photographers?.map(
		(photographer) => {
			return (
				<Select.Option key={photographer?.id} value={photographer?.id || ''}>
					{photographer?.fName} {photographer?.lName}
				</Select.Option>
			);
		},
	);

	// const p_EventPh: EventPhotographersTable[] = [];
	// qryData?.pagination?.eventDates?.result?.map((event, i) => {
	// 	return p_EventPh.push({
	// 		eventName: event.event?.name || '',
	// 		id: event?.id?.toString() || '',
	// 		key: (i + 1).toString() || '',
	// 		eDate: format(parseISO(event?.eDate), 'LLL dd-yyyy'),
	// 		parentName: event.eventParent?.parentName || '',
	// 		venueName: event.eventParent?.venue?.name || '',
	// 		eventPhotographers:
	// 			event.photographers?.map((ph) => ({
	// 				adminComment: ph.adminComment || undefined,
	// 				id: ph.id || '',
	// 				name:
	// 					(ph.photographer?.fName || '') +
	// 					' ' +
	// 					(ph.photographer?.lName || ' '),
	// 				photographerComment: ph.photographerComment || undefined,
	// 				photographerId: ph.photographer?.id || '',
	// 			})) || [],
	// 	});
	// });
	// console.log(p_EventPh);
	// ! End Photographer Module

	const p_Events: EventTable[] = [];

	qryData?.pagination?.eventDates?.result?.forEach((event, i) => {
		return p_Events.push({
			eDate: format(parseISO(event?.eDate), 'LLL dd-yyyy'),
			eventName: event?.event?.name || '',
			featured: event?.featured || false,
			id: event?.id || '-1',
			key: (i + 1).toString() || '-1',
			parentName: event?.eventParent?.parentName || '',
			venueName: event?.eventParent?.venue?.name || '',
			eventVenueId: event?.eventParent?.venue?.id || '',
			enabled: event?.enabled || false,
			photographer: event?.photographer || false,
			weight: event.weight || null,
			eventPhotographers:
				event.photographers?.map((ph) => ({
					adminComment: ph.adminComment || undefined,
					id: ph.id || '',
					name:
						(ph.photographer?.fName || '') +
						' ' +
						(ph.photographer?.lName || ' '),
					photographerComment: ph.photographerComment || undefined,
					photographerId: ph.photographer?.id || '',
				})) || [],
		});
	});

	const [UpdateEventDate] = useMutation<
		CmsUpdateEventDateMutation,
		CmsUpdateEventDateMutationVariables
	>(MutationUpdateEventDate, {
		onError(error) {
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateEvent = useCallback(
		async (
			id: string,
			featured: boolean | undefined,
			enabled: boolean | undefined,
			weight: number | null | undefined,
		) => {
			const rsp = await UpdateEventDate({
				variables: {
					id: id,
					enabled: enabled,
					featured: featured,
					weight: weight,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Events',
					pagePrefix: 'Event',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.pagination?.eventDates?.result],
	);

	const [deleteEvent] = useMutation<
		CmsDeleteEventDateMutation,
		CmsDeleteEventDateMutationVariables
	>(MutationDeleteEventDate);

	const deleteEventFn = async (id: string) => {
		if (!confirm('Confirm delete?')) return;
		const rsp = await deleteEvent({
			variables: {
				id: id,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'deleted',
			});

			refetchQuery();
		}
	};

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => {
				return a.weight - b.weight;
			},

			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updateEvent(id, undefined, undefined, weight);
						}}
					/>
				);
			},
		},
		{
			title: 'Venue',
			dataIndex: 'venueName',
			key: 'venueName',
			width: 200,
			// filters: qryVenues?.getVenues?.map((ed) => {
			// 	return {
			// 		text: ed?.name,
			// 		value: ed?.id,
			// 	};
			// }),
			// filterMultiple: false,
			// onFilter: (value, record) => {
			// 	return record.eventVenueId.includes(value);
			// },

			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.venueName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Dates',
			dataIndex: 'eDate',
			key: 'eDate',
			width: 110,
			align: 'center',
			sorter: (a, b) => {
				return new Date(a.eDate).valueOf() - new Date(b.eDate).valueOf();
			},
			render: (date: string) => date,
		},
		{
			title: 'Parent Name',
			dataIndex: 'parentName',
			key: 'parentName',
			width: 200,
		},
		{
			title: 'Event Name',
			dataIndex: 'eventName',
			key: 'eventName',
			width: 200,
			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.eventName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
				{
					text: 'Featured',
					value: 'Featured',
				},
				{
					text: 'Photographer',
					value: 'Photographer',
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => {
				if (value === 'Featured') return record.featured;
				if (value === 'Photographer') return !record.photographer;
				else {
					return record.enabled === value;
				}
			},
			render: (props: {
				enabled: boolean | undefined;
				featured: boolean | undefined;
				id: string;
			}) => {
				const { enabled, featured, id } = props;

				return (
					<Space>
						<StarFilled
							onClick={() => {
								updateEvent(id, !featured, undefined, undefined);
							}}
							style={{
								color: featured ? 'var(--highlighted)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
							title="Featured?"
						/>
						<Link to={`${ROUTES.EDIT_EVENT.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateEvent(id, undefined, !enabled, undefined);
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteEventFn(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	].filter((col, i) => col.dataIndex !== 'id');

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Events</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<Link to={ROUTES.CREATE_EVENT.path}>
						<Button className="btn-main">
							Create New Event
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Events}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);

							setEventName(filters?.eventName?.toString().toLowerCase() || '');
							setEventVenueName(
								filters?.venueName?.toString().toLowerCase() || '',
							);

							// setVenueId(filters?.venueName?.toString() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.eventDates?.paginationInfo?.totalResult ||
								0,
						}}
						expandable={{
							expandedRowRender: (record: EventTable) => {
								return (
									<div>
										<Select
											mode="multiple"
											showSearch
											style={{ width: '100%' }}
											placeholder="Assign a Photographer"
											defaultValue={
												record.eventPhotographers
													.map((ph) => {
														return ph.photographerId;
													})
													.toString() || undefined
											}
											onSelect={async (value: string) => {
												await createEventPhotographersFn(record.id, [value]);
											}}
											onDeselect={async (v) => {
												await deleteEventPhotographerIdFn(v, record.id);
											}}
										>
											{photographerOptions || (
												<Select.Option value="" key="33s">
													<></>
												</Select.Option>
											)}
										</Select>
										{/* 
										{record?.eventPhotographers?.map((ph) => {
											return (
												<React.Fragment key={`eventP${ph.id}`}>
													<Row
														style={{
															margin: '1rem 0 0.8rem 0',
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<Text
															style={{
																textTransform: 'uppercase',
																border: '1px solid #85a6cc',
																borderRadius: '2px',
																background: 'rgb(133, 166, 204, 0.15)',
																padding: '0.35rem 1.25rem 0.25rem 1.25rem',
																fontSize: '0.7rem',
																letterSpacing: '0.05em',
																color: '#0560c7',
															}}
														>
															{ph.name}
														</Text>
													</Row>
													<label>
														Admin Comment
														<Input
															defaultValue={ph?.adminComment}
															onChange={(e) => {
																comments.current[ph.id] = e.target.value;
															}}
															suffix={
																<Button
																	ghost
																	type="primary"
																	size="small"
																	style={{
																		fontSize: 'clamp(9px, 1vw, 10px)',
																		color: 'var(--valid)',
																		borderColor: 'var(--valid)',
																	}}
																	onClick={async (e) => {
																		e.stopPropagation();
																		if (comments.current[ph.id]) {
																			addEventPhotographerCommentFn(
																				ph.id,
																				comments.current[ph.id],
																			);
																		}
																	}}
																>
																	UPDATE
																</Button>
															}
														/>
													</label>

													<label>
														Photographer Comment
														<Input
															style={{
																marginBottom: '1rem',
															}}
															defaultValue={ph.photographerComment}
															disabled={true}
														/>
													</label>
												</React.Fragment>
											);
										})} */}
									</div>
								);
							},
							rowExpandable: (record: EventTable) => {
								return record.photographer;
							},
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default Events;
