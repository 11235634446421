import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import CityCard from 'src/components/ui/discover-city/city-card';
import { ROUTES } from 'src/config/route';
import {
	CmsDeleteAreaMutation,
	CmsDeleteAreaMutationVariables,
	CmsGetAreaQuery,
	CmsUpdateAreaMutation,
	CmsUpdateAreaMutationVariables,
} from 'src/lib/generated/_generated';
import { MutationDeleteArea, MutationUpdateArea } from 'src/lib/gql/mutations';
import { QueryGetArea } from 'src/lib/gql/queries';

const EditCity = () => {
	interface Params {
		id: string;
		[key: string]: string;
	}

	const params = useParams<Params>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading: qryLoading,
		refetch: refetchQuery,
	} = useQuery<CmsGetAreaQuery>(QueryGetArea, {
		variables: {
			id: params.id,
		},
		fetchPolicy: 'cache-and-network',
	});

	const [UpdateArea] = useMutation<
		CmsUpdateAreaMutation,
		CmsUpdateAreaMutationVariables
	>(MutationUpdateArea, {
		variables: {
			id: params.id ?? '',
		},
		onError(error) {
			CustomNotification({
				pageName: 'Discover the City',
				pagePrefix: 'Area',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [DeleteArea] = useMutation<
		CmsDeleteAreaMutation,
		CmsDeleteAreaMutationVariables
	>(MutationDeleteArea, {
		variables: {
			id: params.id ?? '',
		},
		onError(error) {
			CustomNotification({
				pageName: 'Discover the City',
				pagePrefix: 'Area',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(
		async (data) => {
			const rsp = await UpdateArea({
				variables: {
					id: qryData?.getArea?.id || '-1',
					name: data.name,
					hTag: data.hTag,
					slug: data.slug,
					meta: data.meta,
					coverImageId: data.coverImageId,
					weight: data.weight || 0,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Discover the City',
					pagePrefix: 'City',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.getArea?.id],
	);

	const deleteQry = useCallback(
		async (data) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await DeleteArea({
				variables: {
					id: qryData?.getArea?.id || '-1',
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Discover the City',
					pagePrefix: 'City',
					notificationType: 'deleted',
				});

				navigate(`${ROUTES.DISCOVER_CITY.path}`);
			}
		},
		[qryData?.getArea?.id],
	);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit City</Text>
			</Col>

			{qryLoading ? (
				<Skeleton active />
			) : (
				<CityCard
					onSave={updateQry}
					cityMedia={qryData?.getArea?.coverImage || undefined}
					cityName={qryData?.getArea?.name || ''}
					cityPosition={qryData?.getArea?.weight || 0}
					citySlug={qryData?.getArea?.slug || ''}
					cityTag={qryData?.getArea?.hTag || ''}
					cityMetaDescription={qryData?.getArea?.meta || ''}
					canDelete={true}
					onDelete={deleteQry}
				/>
			)}
		</Row>
	);
};

export default EditCity;
