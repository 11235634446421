import { getApps, initializeApp } from 'firebase/app';
import React, { ReactNode } from 'react';
import { RecoilRoot, RecoilRootProps } from 'recoil';
import { firebaseConfig } from 'src/lib/firebase/fbConfig';

interface Props {
	children?: ReactNode;
}

const Recoil: React.FC<Props & RecoilRootProps> = ({ children, ...rest }) => {
	if (getApps().length === 0) initializeApp(firebaseConfig);
	return <RecoilRoot {...rest}>{children}</RecoilRoot>;
};

export default Recoil;
