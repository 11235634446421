import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useNavigate } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import CityCard from 'src/components/ui/discover-city/city-card';
import { ROUTES } from 'src/config/route';
import {
	CmsCreateAreaMutation,
	CmsCreateAreaMutationVariables,
} from 'src/lib/generated/_generated';

import { MutationCreateArea } from 'src/lib/gql/mutations';

const CreateCity = () => {
	const navigate = useNavigate();

	const [CreateCity] = useMutation<
		CmsCreateAreaMutation,
		CmsCreateAreaMutationVariables
	>(MutationCreateArea, {
		onError(error) {
			CustomNotification({
				pageName: 'Discoverr the City',
				pagePrefix: 'Area',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create a City</Text>
			</Col>

			{/* Props

        cityMedia?: string; // City Image
        cityName?: string; // City Name
        cityPosition?: number; //Position of City
        cityTag?: string; // City - City Tags
        citySlug?: string; // City - Slug URL
        cityMetaDescription?: string; // City - Meta Description
        canDelete?: boolean;
        onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
        onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
      */}
			<CityCard
				onSave={async (data) => {
					const rsp = await CreateCity({
						variables: {
							name: data.name || '',
							hTag: data.hTag || '',
							slug: data.slug || '',
							weight: data.weight,
							meta: data.meta || '',
							description: data.description || '',
							coverImageId: data.coverImageId,
						},
					});

					if (rsp.data) {
						CustomNotification({
							pageName: 'Discover the City',
							pagePrefix: 'Area',
							notificationType: 'created',
						});

						navigate(
							`${ROUTES.EDIT_DISCOVER_CITY.path}/${rsp.data?.createArea?.id}`,
						);
					}
				}}
			/>
		</Row>
	);
};

export default CreateCity;
