import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import { CityForm } from 'src/lib/form-interfaces';
import { Media, MediaFoldersNames } from 'src/lib/interface';
import MediaUpload from '../media-upload';
import SlugInput from '../slug-input';
import TagInput from '../tag-input';

interface Props {
	cityMedia?: Media; // City Image
	cityName?: string; // City Name
	cityPosition?: number; //Position of City
	cityTag?: string; // City - City Tags
	citySlug?: string; // City - Slug URL
	cityMetaDescription?: string; // City - Meta Description
	canDelete?: boolean;
	onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
	onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
	onSave: ({
		name,
		meta,
		description,
		hTag,
		slug,
		weight,
		coverImageId,
	}: {
		name: string;
		meta: string;
		description: string;
		hTag: string;
		slug: string;
		weight: number | null;
		coverImageId: string;
	}) => void;
}

const CityCard = (props: Props) => {
	const [media, setMedia] = useState<Media | null>(props.cityMedia || null);

	const [form] = useForm<CityForm>();

	const mediaCallback = useCallback((media) => {
		setMedia(media);
	}, []);

	const createAreas = async (data: CityForm) => {
		if (media === null || media === undefined) {
			CustomNotification({
				pageName: 'Discover the City',
				errorName: 'Image',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			name: data.name,
			meta: data.meta,
			description: '',
			hTag: data.hTag,
			slug: data.slug,
			weight: data.weight ? parseInt(data.weight) : null,
			coverImageId: media.id,
		});
	};

	return (
		<Form
			form={form}
			onFinish={createAreas}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%' }} gutter={16}>
				<Col
					span={24}
					style={{
						marginBottom: '16px',
					}}
				>
					<MediaUpload
						folder={MediaFoldersNames.misc}
						onMediaUploaded={mediaCallback}
						media={media || undefined}
						accept="IMAGE"
						placeholderText={'Drag and drop a city image'}
					/>
				</Col>

				{/* Input Fields */}
				<Col span={24} md={18}>
					<label className="input-fields-label">Title</label>

					<Form.Item
						rules={[{ required: true }]}
						name="name"
						hasFeedback
						initialValue={props.cityName}
					>
						<Input type="text" placeholder="Title" className="input-fields" />
					</Form.Item>
				</Col>
				<Col span={24} md={6}>
					<label className="input-fields-label">Position</label>

					<Form.Item
						name="weight"
						rules={[
							{
								validator: (rule, value: string, back) => {
									if (value !== undefined) {
										if (value.length > 4) {
											back('Too large');
											return;
										}
										if (value !== '' && parseInt(value) < 1) {
											back('Minimum value is 1');
											return;
										}
									}
									back(undefined);
									return;
								},
							},
						]}
						hasFeedback
						initialValue={props.cityPosition}
					>
						<Input
							type="number"
							placeholder="Position / Sequence No."
							className="input-fields"
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={24}>
					<label className="input-fields-label">City Tag</label>

					<Form.Item
						rules={[{ required: true }]}
						name="hTag"
						hasFeedback
						initialValue={props.cityTag}
					>
						<TagInput prefix={'City'} />
					</Form.Item>
					<label className="input-fields-label">Slug</label>

					<Form.Item
						rules={[{ required: true }]}
						name="slug"
						hasFeedback
						initialValue={props.citySlug}
					>
						<SlugInput />
					</Form.Item>
					<label className="input-fields-label">SEO Meta Description</label>

					<Form.Item name="meta" initialValue={props.cityMetaDescription}>
						<Input.TextArea
							className="input-fields"
							placeholder="Meta Description (This is for the SEO)"
							rows={4}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row
				justify={'center'}
				style={{
					margin: '8px auto',
				}}
			>
				<Space size="small">
					<Button className="btn-main save" htmlType={'submit'}>
						Save
					</Button>

					{props.canDelete ? (
						<Button className="btn-main delete" onClick={props.onDelete}>
							Delete
						</Button>
					) : (
						<Button
							className="btn-main"
							onClick={() => {
								window.history.back();
							}}
						>
							Cancel
						</Button>
					)}
				</Space>
			</Row>
		</Form>
	);
};

export default CityCard;
