import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import MediaUpload from 'src/components/ui/media-upload';
import { BestPlacesForm } from 'src/lib/form-interfaces';
import { Media, MediaFoldersNames } from 'src/lib/interface';
import SlugInput from '../slug-input';
import TagInput from '../tag-input';

interface Props {
	bestMedia?: Media; // Best places Image
	bestName?: string; // Best places Name
	bestPosition?: number; //Position of Best places
	bestTag?: string; // Best places - City Tags
	bestSlug?: string; // Best places - Slug URL
	bestMetaDescription?: string; // City - Meta Description
	canDelete?: boolean; // Can Delete
	onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
	onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
	onSave: ({
		name,
		hTag,
		slug,
		weight,
		coverImageId,
		meta,
	}: BestPlacesForm) => void;
}
const BestPlacesCard = (props: Props) => {
	const [mediaId, setMediaId] = useState<string | undefined>(undefined);
	const [form] = useForm<BestPlacesForm>();

	const mediaCallback = useCallback((media) => {
		setMediaId(media.id);
	}, []);

	const createPlaces = async (data: BestPlacesForm) => {
		if (mediaId === null || mediaId === undefined) {
			CustomNotification({
				pageName: 'Best Places',
				errorName: 'Image',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			name: data.name,
			hTag: data.hTag,
			slug: data.slug,
			weight: data.weight,
			coverImageId: mediaId,
			meta: data.meta,
		});
	};

	return (
		<Form
			form={form}
			onFinish={createPlaces}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%' }} gutter={16}>
				<Col
					span={24}
					style={{
						marginBottom: '16px',
					}}
				>
					<MediaUpload
						onMediaUploaded={mediaCallback}
						media={props.bestMedia || undefined}
						folder={MediaFoldersNames.misc}
						accept="IMAGE"
						placeholderText={'Drag and drop a cover photo'}
					/>
				</Col>

				{/* Input Fields */}
				<Col span={24} md={18}>
					<label className="input-fields-label">Title</label>

					<Form.Item
						rules={[{ required: true }]}
						name="name"
						hasFeedback
						initialValue={props.bestName}
					>
						<Input type="text" placeholder="Title" className="input-fields" />
					</Form.Item>
				</Col>
				<Col span={24} md={6}>
					<label className="input-fields-label">Position</label>

					<Form.Item
						name="weight"
						rules={[
							{
								validator: (rule, value: string, back) => {
									if (value !== undefined) {
										if (value.length > 4) {
											back('Too large');
											return;
										}
										if (value !== '' && parseInt(value) < 1) {
											back('Minimum value is 1');
											return;
										}
									}
									back(undefined);
									return;
								},
							},
						]}
						hasFeedback
						initialValue={props.bestPosition}
					>
						<Input
							type="number"
							placeholder="Position / Sequence No."
							className="input-fields"
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={24}>
					<label className="input-fields-label">Best Places Tag</label>

					<Form.Item
						rules={[{ required: true }]}
						name="hTag"
						hasFeedback
						initialValue={props.bestTag}
					>
						<TagInput prefix={'BestPlaces'} />
					</Form.Item>
					<label className="input-fields-label">Slug</label>

					<Form.Item
						rules={[{ required: true }]}
						name="slug"
						hasFeedback
						initialValue={props.bestSlug}
					>
						<SlugInput />
					</Form.Item>

					<label className="input-fields-label">SEO Meta Description</label>

					<Form.Item name="meta" initialValue={props.bestMetaDescription}>
						<Input.TextArea
							className="input-fields"
							placeholder="Meta Description (This is for the SEO)"
							rows={4}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row
				justify={'center'}
				style={{
					margin: '8px auto',
				}}
			>
				<Space size="small">
					<Button className="btn-main save" htmlType="submit">
						Save
					</Button>

					{props.canDelete ? (
						<Button className="btn-main delete" onClick={props.onDelete}>
							Delete
						</Button>
					) : (
						<Button
							className="btn-main"
							onClick={() => {
								window.history.back();
							}}
						>
							Cancel
						</Button>
					)}
				</Space>
			</Row>
		</Form>
	);
};

export default BestPlacesCard;
