import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import VenueSelectDropDown from 'src/components/venue-selector';
import { ROUTES } from 'src/config/route';
import { EventParentForm } from 'src/lib/form-interfaces';
import {
	MutationDeleteEventParent,
	MutationUpdateEventParent,
} from 'src/lib/gql/mutations';
import {
	QueryGetGenres,
	QueryGetVenues,
	QueryThingsToDo,
} from 'src/lib/gql/queries';
import SlugInput from '../slug-input';
import TagInput from '../tag-input';
import {
	CmsDeleteEventParentMutation,
	CmsDeleteEventParentMutationVariables,
	CmsGetGenresQuery,
	CmsGetThingsToDoQuery,
	CmsGetVenuesQuery,
	CmsGetVenuesQueryVariables,
	CmsUpdateEventParentMutation,
	CmsUpdateEventParentMutationVariables,
	OccurrenceTime,
} from 'src/lib/generated/_generated';

const { Option } = Select;

export interface EventParentCard {
	eventParentId: string;
	eventParentName?: string;
	eventTag?: string;
	eventSlug?: string;
	eventOccurrenceTime?: string;
	eventType?: string[];
	eventVenue?: string;
	eventMusicGenre?: string[];
	eventColor?: string;
	eventMeta?: string;
}

const EventParentCard = React.memo((props: EventParentCard) => {
	const navigate = useNavigate();
	const { data: eventTypeQuery } =
		useQuery<CmsGetThingsToDoQuery>(QueryThingsToDo);
	const [venueName, setVenueName] = useState<string | undefined>(undefined);
	const { data: venuesQuery } = useQuery<
		CmsGetVenuesQuery,
		CmsGetVenuesQueryVariables
	>(QueryGetVenues, {
		variables: {
			pageNumber: 1,
			perPage: 10,
			name: venueName,
		},
		notifyOnNetworkStatusChange: true,
	});
	const { data: genreQuery } = useQuery<CmsGetGenresQuery>(QueryGetGenres);
	const [form] = useForm<EventParentForm>();

	const [updateEventParent] = useMutation<
		CmsUpdateEventParentMutation,
		CmsUpdateEventParentMutationVariables
	>(MutationUpdateEventParent);

	const updateQry = useCallback(async (data: EventParentForm) => {
		const rsp = await updateEventParent({
			variables: {
				id: props.eventParentId,
				updateEventParentInput: {
					eventTypeIds: data.eventTypeIds,
					genreIds: data.genreIds,
					hTag: data.hTag,
					meta: data.meta,
					occurrenceTime: data.occurrenceTime,
					parentName: data.parentName,
					slug: data.slug,
					venueId: data.venueId,
				},
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Event',
				pagePrefix: 'Event Date',
				notificationType: 'updated',
			});
		}
	}, []);

	const [deleteEventParent] = useMutation<
		CmsDeleteEventParentMutation,
		CmsDeleteEventParentMutationVariables
	>(MutationDeleteEventParent);

	const deleteEventFn = async () => {
		if (!confirm('Confirm delete?')) return;
		const rsp = await deleteEventParent({
			variables: {
				id: props.eventParentId,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Events',
				customDescription: 'Event has been deleted.',
				notificationType: 'custom-success',
			});
			navigate(`${ROUTES.EVENTS.path}`);
		}
	};

	return (
		<Form
			form={form}
			onFinish={updateQry}
			style={{
				width: '100%',
			}}
		>
			<Row align="bottom" gutter={16}>
				<Col span={24} md={8}>
					<label className="input-fields-label">Event Group Name</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						initialValue={props.eventParentName}
						name="parentName"
					>
						<Input
							type="text"
							placeholder="Event Group Name"
							className="input-fields"
							style={{
								padding: '1em',
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={8}>
					<label className="input-fields-label">Event Tag</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						initialValue={props.eventTag}
						name="hTag"
					>
						<TagInput prefix={'Events'} />
					</Form.Item>
				</Col>

				<Col
					span={24}
					lg={8}
					style={{
						marginTop: '1.5em',
					}}
				>
					<Row style={{ width: '100%' }}>
						<Col span={24}>
							<Form.Item
								rules={[
									{
										required: true,
									},
								]}
								name="genreIds"
								initialValue={props.eventMusicGenre}
							>
								<Select
									suffixIcon={null}
									allowClear
									mode="multiple"
									style={{ width: '100%' }}
									placeholder="Select Genre"
									size="middle"
									className="multiple-custom-select"
									filterOption={false}
									showSearch
								>
									{genreQuery?.getGenres?.map((genre, i) => {
										return (
											<Option key={genre?.id} value={genre?.id || ''}>
												{genre?.name}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Col>
				<Col span={24} md={12}>
					<label className="input-fields-label">SEO Meta Description</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						hasFeedback
						name="meta"
						initialValue={props.eventMeta}
					>
						<Input.TextArea
							className="input-fields"
							style={{
								height: '100%',
							}}
							placeholder="Meta Description"
							rows={16}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<label className="input-fields-label">Slug</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						initialValue={props.eventSlug}
						name="slug"
					>
						<SlugInput />
					</Form.Item>
				</Col>
				<Col span={24} md={8}>
					<label className="input-fields-label">Event Timing</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						name="occurrenceTime"
						initialValue={props.eventOccurrenceTime}
					>
						<Select
							style={{ width: '100%' }}
							placeholder="Event Timing"
							size="middle"
							className="bg-select"
						>
							<Option value={OccurrenceTime.All}>All</Option>
							<Option value={OccurrenceTime.Day}>Day</Option>
							<Option value={OccurrenceTime.Night}>Night</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col span={24} lg={8}>
					<label className="input-fields-label">Event Type</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						name="eventTypeIds"
						initialValue={props.eventType}
					>
						<Select
							allowClear
							suffixIcon={null}
							style={{ width: '100%' }}
							placeholder="Event Type"
							size="middle"
							mode="multiple"
							className="multiple-custom-select"
						>
							{eventTypeQuery?.getEventTypes?.map((type, i) => {
								return (
									<Option key={`eventType ${i}`} value={type?.id || '-1'}>
										{type?.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col span={24} lg={8}>
					<label className="input-fields-label">Venue</label>

					<Form.Item
						rules={[
							{
								required: true,
							},
						]}
						name="venueId"
						initialValue={props.eventVenue}
					>
						<VenueSelectDropDown />
					</Form.Item>
				</Col>

				<Col
					span={24}
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
					}}
				>
					<Button
						className="btn-main save"
						style={{
							marginRight: '0.5rem',
						}}
						htmlType="submit"
					>
						Save Changes
					</Button>

					<Button
						onClick={() => {
							deleteEventFn();
						}}
						className="btn-main delete"
					>
						Delete Entire Event
					</Button>
				</Col>
			</Row>
		</Form>
	);
});

export default EventParentCard;
