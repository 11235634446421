import {
	AimOutlined,
	FacebookOutlined,
	InstagramOutlined,
	LoadingOutlined,
	MailOutlined,
	PhoneOutlined,
	TwitterOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Skeleton, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import InfoTable from 'src/components/info-table';
import EditorComp from 'src/components/ui/editor';
import { RegionSettingsForm } from 'src/lib/form-interfaces';
import {
	CmsGetAdUnitsQuery,
	CmsGetCurrentRegionQuery,
	CmsUpdateAdUnitsMutation,
	CmsUpdateAdUnitsMutationVariables,
	CmsUpdateRegionDetailsMutation,
	CmsUpdateRegionDetailsMutationVariables,
} from 'src/lib/generated/_generated';
import {
	MutationUpdateAdUnits,
	MutationUpdateRegionDetails,
} from 'src/lib/gql/mutations';
import { QueryGetAdUnits, QueryGetCurrentRegion } from 'src/lib/gql/queries';

const RegionSettings = () => {
	const [form] = useForm();

	const [adCode, setAdCode] = useState('');

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetCurrentRegionQuery>(QueryGetCurrentRegion);

	const {
		data: qryAdUnitData,
		loading: adUnitLoading,
		refetch: refetchAdQuery,
	} = useQuery<CmsGetAdUnitsQuery>(QueryGetAdUnits);

	const [updateAdCode] = useMutation<
		CmsUpdateAdUnitsMutation,
		CmsUpdateAdUnitsMutationVariables
	>(MutationUpdateAdUnits, {
		onError: (error) => {
			CustomNotification({
				pageName: 'Settings',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateCode = async (id: string, code: string) => {
		const rsp = await updateAdCode({
			variables: {
				id: id,
				code: code,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Settings',
				pagePrefix: 'Ad Unit',
				notificationType: 'updated',
			});

			refetchAdQuery();
		}
	};

	const [updateRegionSettings] = useMutation<
		CmsUpdateRegionDetailsMutation,
		CmsUpdateRegionDetailsMutationVariables
	>(MutationUpdateRegionDetails, {
		onError: (error) => {
			CustomNotification({
				pageName: 'Settings',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});
	const updateRegion = async (data: RegionSettingsForm) => {
		const rsp = await updateRegionSettings({
			variables: {
				data: {
					brandName: data.brandName,
					tagLine: data.tagLine,
					address: data.address,
					email: data.email,
					phone: data.phone,
					privacy: data.prviacy,
					socialFb: data.socialFb,
					socialInstagram: data.socialInstagram,
					socialTwitter: data.socialTwitter,
					terms: data.terms,
				},
			},
		});
		if (rsp.data) {
			CustomNotification({
				pageName: 'Settings',
				pagePrefix: 'Region',
				notificationType: 'updated',
			});

			refetchQuery();
		}
	};

	return (
		<>
			<h1 className="sub-header">Region Settings</h1>
			{loading ? (
				<Skeleton active />
			) : (
				<Form form={form} onFinish={updateRegion}>
					<Row style={{ width: '100%', marginTop: '1em' }} gutter={16}>
						<Col span={24} lg={12}>
							<label className="input-fields-label">Brand Name</label>

							<Form.Item
								name="brandName"
								initialValue={qryData?.getCurrentRegion?.details?.brandName}
							>
								<Input
									type="text"
									placeholder="Brand Name"
									className="input-fields"
								/>
							</Form.Item>
						</Col>

						<Col span={24} lg={12}>
							<label className="input-fields-label">Tag Line</label>

							<Form.Item
								name="tagLine"
								initialValue={qryData?.getCurrentRegion?.details?.tagLine}
							>
								<Input
									type="text"
									placeholder="Tag Line"
									className="input-fields"
								/>
							</Form.Item>
						</Col>

						<Col span={24}>
							<label className="input-fields-label">Address (Footer)</label>

							<Form.Item
								name="address"
								initialValue={qryData?.getCurrentRegion?.details?.address}
							>
								<Input
									type="text"
									placeholder="Address"
									className="input-fields"
									prefix={<AimOutlined />}
								/>
							</Form.Item>
						</Col>
						<Col span={24} lg={12}>
							<label className="input-fields-label">Email (Footer)</label>

							<Form.Item
								name="email"
								initialValue={qryData?.getCurrentRegion?.details?.email}
							>
								<Input
									type="email"
									placeholder="Contact Email Address"
									className="input-fields"
									prefix={<MailOutlined />}
								/>
							</Form.Item>
						</Col>
						<Col span={24} lg={12}>
							<label className="input-fields-label">Phone (Footer)</label>

							<Form.Item
								name="phone"
								initialValue={qryData?.getCurrentRegion?.details?.phone}
							>
								<Input
									type="phone"
									placeholder="Contact Phone Number"
									className="input-fields"
									prefix={<PhoneOutlined />}
								/>
							</Form.Item>
						</Col>
						<Col span={24} lg={12}>
							<label className="input-fields-label">Terms and Conditions</label>

							<Row className="input-fields">
								<Form.Item
									name="terms"
									style={{
										width: '100%',
									}}
									initialValue={
										qryData?.getCurrentRegion?.details?.terms ||
										'<p>Terms and Conditions</p>'
									}
								>
									<EditorComp value={'<p>Terms and Conditions</p>'} />
								</Form.Item>
							</Row>
						</Col>
						<Col span={24} lg={12}>
							<label className="input-fields-label">Privacy Policy</label>

							<Row className="input-fields">
								<Form.Item
									style={{
										width: '100%',
									}}
									name="privacy"
									initialValue={
										qryData?.getCurrentRegion?.details?.privacy ||
										'<p>Privacy Policy</p>'
									}
								>
									<EditorComp value={'<p>Privacy Policy</p>'} />
								</Form.Item>
							</Row>
						</Col>

						<Col span={24} lg={8}>
							<Form.Item
								name="socialInstagram"
								initialValue={
									qryData?.getCurrentRegion?.details?.socialInstagram
								}
							>
								<Input
									type="text"
									placeholder="Instagram Social Link"
									className="input-fields"
									prefix={<InstagramOutlined />}
								/>
							</Form.Item>
						</Col>
						<Col span={24} lg={8}>
							<Form.Item
								name="socialFb"
								initialValue={qryData?.getCurrentRegion?.details?.socialFb}
							>
								<Input
									type="text"
									placeholder="Facebook Social Link"
									className="input-fields"
									prefix={<FacebookOutlined />}
								/>
							</Form.Item>
						</Col>
						<Col span={24} lg={8}>
							<Form.Item
								name="socialTwitter"
								initialValue={qryData?.getCurrentRegion?.details?.socialTwitter}
							>
								<Input
									type="text"
									placeholder="Twitter Social Link"
									className="input-fields"
									prefix={<TwitterOutlined />}
								/>
							</Form.Item>
						</Col>

						<Col span={24}>
							<Row justify={'end'}>
								<Button className="btn-main save" htmlType={'submit'}>
									Update
								</Button>
							</Row>
						</Col>
					</Row>
				</Form>
			)}

			<Col span={24}>
				<h2 className="sub-header">Google DFP Ad Units</h2>
				<hr style={{ opacity: '0.25' }} />
			</Col>

			<Col span={24}>
				<Spin indicator={<LoadingOutlined />} spinning={adUnitLoading}>
					<InfoTable<any>
						data={
							qryAdUnitData?.getAdUnits?.map((ads, i) => {
								return {
									key: ads.id || '',
									type: ads.type || '',
									code: ads.code || '',
								};
							}) || []
						}
						columnData={[
							{
								title: 'Type',
								dataIndex: 'type',
							},
							{
								title: 'Code',
								render: (props: { key: string; code: string }) => {
									const { key, code } = props;
									return (
										<div
											style={{
												position: 'relative',
											}}
										>
											<Input
												type="text"
												defaultValue={code}
												onChange={(e) => {
													setAdCode(e.target.value);
												}}
											/>
											<Button
												ghost
												size="small"
												style={{
													position: 'absolute',
													right: 5,
													top: '50%',
													transform: 'translateY(-50%)',
													background: 'transparent',
													borderColor: 'var(--valid)',
													fontSize: '12px',
													color: 'var(--valid)',
												}}
												onClick={() => {
													updateCode(key, adCode);
												}}
											>
												SAVE
											</Button>
										</div>
									);
								},
							},
						]}
						onChange={() => {
							//
						}}
					/>
				</Spin>
			</Col>
		</>
	);
};

export default RegionSettings;
