import {
	ApolloClient,
	ApolloProvider,
	createHttpLink,
	InMemoryCache,
	from,
	Observable,
	FetchResult,
	NextLink,
	Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import React, { useEffect } from 'react';
import { getToken, watchAuth, watchToken } from 'src/lib/firebase/fbClient';
import { DataRegionHostname } from 'src/lib/interface';

interface Props {
	children?: React.ReactNode;
}

const ApolloHOC = (props: Props) => {
	let crDataRegion: string | undefined = undefined;
	const hNames = location.hostname.split('.');
	if (hNames.length == 1) {
		crDataRegion = DataRegionHostname[hNames[0]];
	} else if (hNames.length > 1) {
		crDataRegion = DataRegionHostname[hNames[hNames.length - 2]];
	}

	useEffect(() => {
		const updateToken = (token: string | null) => {
			if (token) {
				localStorage.setItem('token', token);
			} else {
				localStorage.removeItem('token');
			}
		};

		watchAuth(updateToken);
		watchToken(updateToken);
	}, []);

	const userToken = localStorage.getItem('token');
	const httpLink = createHttpLink({
		uri: process.env.REACT_APP_GQL_ENDPOINT,
	});

	const authLink = setContext(async (_, { headers }) => {
		let finalToken = userToken;
		finalToken = localStorage.getItem('token');
		return {
			headers: {
				...headers,
				...(crDataRegion && {
					'x-source-region': crDataRegion,
				}),
				...(finalToken && {
					Authorization: finalToken,
				}),
			},
		};
	});

	const handleToken = (
		operation: Operation,
		forward: NextLink,
	): Observable<FetchResult> => {
		return new Observable<FetchResult>((observer) => {
			(async () => {
				try {
					const token = await getToken();
					if (token) {
						operation.setContext(({ headers }) => ({
							headers: {
								...headers,
								authorization: token,
							},
						}));
						localStorage.setItem('token', token);
					}

					const observable = forward(operation);
					observable.subscribe({
						next: observer.next.bind(observer),
						error: observer.error.bind(observer),
						complete: observer.complete.bind(observer),
					});
				} catch (error) {
					observer.error(error);
				}
			})();
		});
	};

	const errorLink = onError(
		({ graphQLErrors, networkError, operation, forward }) => {
			if (graphQLErrors) {
				for (const err of graphQLErrors) {
					if (
						err.extensions?.code === 'UNAUTHENTICATED' ||
						err.extensions?.code === 'FORBIDDEN'
					) {
						return handleToken(operation, forward);
					}
				}
			}
			if (networkError && networkError.message.includes('403')) {
				return handleToken(operation, forward);
			}
			return;
		},
	);

	const cache = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					pagination: {
						merge(existing = {}, incoming) {
							return { ...existing, ...incoming };
						},
					},
				},
			},
		},
	});

	const gqlClient = new ApolloClient({
		link: from([errorLink, authLink, httpLink]),
		cache,
	});

	if (!crDataRegion) return <div>Host name not recognized.</div>;
	return <ApolloProvider client={gqlClient}>{props.children}</ApolloProvider>;
};

export default ApolloHOC;
