import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { ROUTES } from 'src/config/route';
import { NewsForm } from 'src/lib/form-interfaces';

import {
	MutationDeleteArticle,
	MutationUpdateArticle,
} from 'src/lib/gql/mutations';
import { QueryGetArticle } from 'src/lib/gql/queries';
import NewsCard from '../../components/ui/news/news-card';
import {
	CmsDeleteArticleCommentMutation,
	CmsDeleteArticleMutationVariables,
	CmsGetArticleQuery,
	CmsGetArticleQueryVariables,
	CmsUpdateArticleMutation,
	CmsUpdateArticleMutationVariables,
} from 'src/lib/generated/_generated';

const EditArticle = () => {
	interface Params {
		id: string;
		[key: string]: string;
	}

	const params = useParams<Params>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetArticleQuery, CmsGetArticleQueryVariables>(
		QueryGetArticle,
		{
			variables: {
				id: params.id ?? '',
			},
			fetchPolicy: 'cache-and-network',
		},
	);

	const [UpdateArticle] = useMutation<
		CmsUpdateArticleMutation,
		CmsUpdateArticleMutationVariables
	>(MutationUpdateArticle, {
		onError(error) {
			CustomNotification({
				pageName: 'News/Articles',
				pagePrefix: 'Article',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(
		async (data: NewsForm) => {
			const rsp = await UpdateArticle({
				variables: {
					id: qryData?.getArticle?.id || '-1',
					content: data.content,
					articleHTags: data.tags,
					date: data.date,
					mediaId: data.mediaId,
					meta: data.meta,
					slug: data.slug,
					title: data.title,
					articleTypeId: data.articleTypeId,
					articleCategoryId: data.articleCategoryId,
					articleJumpTos: data.jumpTo,
					weight: data.weight ? parseInt(data.weight) : null,
					scrapDate: data.scrapDate,
					featured: data.featured,
					enabled: data.enabled,
					publishTime: data.publishTime,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'News/Articles',
					pagePrefix: 'Article',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.getArticle?.id],
	);

	const [deleteArticle] = useMutation<
		CmsDeleteArticleCommentMutation,
		CmsDeleteArticleMutationVariables
	>(MutationDeleteArticle, {
		onError(error) {
			CustomNotification({
				pageName: 'News/Articles',
				pagePrefix: 'Article',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header" style={{ display: 'block' }}>
					Edit Article
				</Text>
			</Col>

			{/* News Component - Props explained in the component */}

			{loading ? (
				<Skeleton active />
			) : (
				<NewsCard
					onSave={updateQry}
					newsName={qryData?.getArticle?.title || ''}
					newsSlug={qryData?.getArticle?.slug || ''}
					newsContent={qryData?.getArticle?.content || ''}
					newsDate={qryData?.getArticle?.date}
					newsMeta={qryData?.getArticle?.meta || ''}
					newsMedia={qryData?.getArticle?.media || undefined}
					newsPosition={qryData?.getArticle?.weight || undefined}
					newsTags={qryData?.getArticle?.articleHTags?.map(
						(tag) => tag.hTag || '',
					)}
					jumpTo={qryData?.getArticle?.articleJumpTos?.map((jump) => ({
						articleId: qryData?.getArticle?.id,
						jumpToLink: jump.jumpToLink,
						jumpToText: jump.jumpToText,
					}))}
					scrapDate={qryData?.getArticle?.scrapDate}
					newsType={qryData?.getArticle?.articleType?.id || undefined}
					newsCategory={qryData?.getArticle?.articleCategory?.id || undefined}
					enabled={qryData?.getArticle?.enabled || undefined}
					publishTime={qryData?.getArticle?.publishTime || undefined}
					canDelete={true}
					featured={
						qryData?.getArticle?.featured === undefined
							? undefined
							: qryData?.getArticle?.featured || undefined
					}
					onDelete={async () => {
						if (!confirm('Are you sure you want to delete?')) return;
						const rsp = await deleteArticle({
							variables: {
								id: params.id ?? '',
							},
						});

						if (rsp.data) {
							CustomNotification({
								pageName: 'News/Articles',
								pagePrefix: 'Article',
								notificationType: 'deleted',
							});
							navigate(`${ROUTES.NEWS.path}`);
						}
					}}
				/>
			)}
		</Row>
	);
};

export default EditArticle;
