import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import BestPlacesCard from 'src/components/ui/best-places/best-places-card';
import { ROUTES } from 'src/config/route';
import { CmsDeleteVenueTypeMutation, CmsDeleteVenueTypeMutationVariables, CmsGetVenueTypeQuery, CmsUpdateVenueTypeMutation, CmsUpdateVenueTypeMutationVariables, MediaType } from 'src/lib/generated/_generated';

import {
	MutationDeleteVenueType,
	MutationUpdateVenueType,
} from 'src/lib/gql/mutations';
import { QueryGetVenueType } from 'src/lib/gql/queries';

const EditBestPlaces = () => {
	interface Params {
		id: string;
		[key: string]: string;
	}

	const params = useParams<Params>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading: qryLoading,
		refetch: refetchQuery,
	} = useQuery<CmsGetVenueTypeQuery>(QueryGetVenueType, {
		variables: {
			id: params.id,
		},
		fetchPolicy: 'cache-and-network',
	});

	const [UpdateBestPlaces] = useMutation<
		CmsUpdateVenueTypeMutation,
		CmsUpdateVenueTypeMutationVariables
	>(MutationUpdateVenueType, {
		variables: {
			id: params.id ?? '',
		},
		onError(error) {
			CustomNotification({
				pageName: 'Best Places',
				pagePrefix: 'Venue Type',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [DeleteBestPlace] = useMutation<
		CmsDeleteVenueTypeMutation,
		CmsDeleteVenueTypeMutationVariables
	>(MutationDeleteVenueType, {
		variables: {
			id: params.id ?? '',
		},
		onError(error) {
			CustomNotification({
				pageName: 'Best Places',
				pagePrefix: 'Venue Type',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deletePlace = useCallback(
		async (data) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await DeleteBestPlace({
				variables: {
					id: qryData?.getVenueType?.id || '-1',
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Best Places',
					pagePrefix: 'Area',
					notificationType: 'deleted',
				});

				refetchQuery();
				navigate(`${ROUTES.BEST_PLACES.path}`);
			}
		},
		[qryData?.getVenueType?.id],
	);

	const bMedia = useMemo(() => {
		return qryData?.getVenueType?.coverImage
			? {
					id: qryData.getVenueType.coverImage.id,
					url: qryData.getVenueType.coverImage.url || '',
					type: MediaType.Image,
			  }
			: undefined;
	}, [qryData?.getVenueType?.coverImage?.id]);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit Place</Text>
			</Col>

			{qryLoading ? (
				<Skeleton active />
			) : (
				<BestPlacesCard
					onSave={async (data) => {
						const rsp = await UpdateBestPlaces({
							variables: {
								id: qryData?.getVenueType?.id || '-1',
								name: data.name,
								hTag: data.hTag,
								slug: data.slug,
								coverImage: data.coverImageId,
								weight: data.weight ? parseInt(data.weight) : null,
								meta: data.meta,
							},
						});

						if (rsp.data) {
							CustomNotification({
								pageName: 'Best Places',
								pagePrefix: 'Area',
								notificationType: 'updated',
							});

							refetchQuery();
						}
					}}
					bestName={qryData?.getVenueType?.name || ''}
					bestMetaDescription={qryData?.getVenueType?.meta || ''}
					bestSlug={qryData?.getVenueType?.slug || ''}
					bestPosition={qryData?.getVenueType?.weight || 0}
					bestTag={qryData?.getVenueType?.hTag || ''}
					bestMedia={bMedia}
					canDelete={true}
					onDelete={deletePlace}
				/>
			)}
		</Row>
	);
};

export default EditBestPlaces;
