import {
	CloseCircleFilled,
	EyeOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Space, Spin, notification } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import InfoTable from 'src/components/info-table';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import SelectTags from 'src/components/ui/select-tags';
import { ROUTES } from 'src/config/route';
import {
	CmsCreateFeaturedListMutation,
	CmsCreateFeaturedListMutationVariables,
	CmsDeleteFeaturedListMutation,
	CmsDeleteFeaturedListMutationVariables,
	CmsFeaturedListQuery,
	CmsFeaturedListQueryVariables,
} from 'src/lib/generated/_generated';

import {
	MutationCreateFeaturedList,
	MutationDeleteFeaturedList,
} from 'src/lib/gql/mutations';
import { QueryFeaturedList } from 'src/lib/gql/queries';
import { EventTable, FeaturedListTable } from 'src/lib/interface';

const FeaturedList = () => {
	const [tag, setTag] = useState<string>('');
	const [newItemWeight, setNewItemWeight] = useState<number | undefined>(
		undefined,
	);
	const [newItemArticleId, setNewItemArticleId] = useState<string | undefined>(
		undefined,
	);

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsFeaturedListQuery, CmsFeaturedListQueryVariables>(
		QueryFeaturedList,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				hTag: tag,
			},
		},
	);
	const p_Category: FeaturedListTable[] = [];

	qryData?.getFeaturedListByHTag?.map((item, i) => {
		return p_Category.push({
			id: item?.id || '',
			key: (i + 1).toString() || '',
			name: item?.article?.title || '',
			weight: item?.weight || 0,
			articleId: item?.article?.id || '',
		});
	});

	const [CmsCreateFeaturedList] = useMutation<
		CmsCreateFeaturedListMutation,
		CmsCreateFeaturedListMutationVariables
	>(MutationCreateFeaturedList, {
		onError(error) {
			CustomNotification({
				pageName: 'Featured List',
				pagePrefix: 'Featured List',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const createItem = async () => {
		notification['info']({
			message: 'Please wait',
			description: 'Creating Featured List',
			duration: 2,
		});
		const rsp = await CmsCreateFeaturedList({
			variables: {
				data: {
					articleId: newItemArticleId || '',
					weight: newItemWeight,
					hTag: tag,
				},
			},
		});

		if (rsp.data) {
			setNewItemArticleId(undefined);
			setNewItemWeight(undefined);
			CustomNotification({
				pageName: 'Featured List',
				pagePrefix: 'Featured List',
				notificationType: 'created',
			});
			refetchQuery();
		}
	};

	const [DeleteFeaturedList] = useMutation<
		CmsDeleteFeaturedListMutation,
		CmsDeleteFeaturedListMutationVariables
	>(MutationDeleteFeaturedList);

	const deleteItem = async (id: string) => {
		if (!confirm('Are you sure you want to delete?')) return;
		const rsp = await DeleteFeaturedList({
			variables: {
				id: id,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Featured List',
				pagePrefix: 'Featured List',
				notificationType: 'deleted',
			});
			refetchQuery();
		}
	};

	// Fields
	const columns = [
		{
			title: '#',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Weight',
			dataIndex: 'weight',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Article',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 40,
			align: 'center',

			render: (props: {
				id: string;
				name: string;
				enabled: boolean;
				articleId: string;
			}) => {
				return (
					<Space>
						<Link
							target="_blank"
							to={`${ROUTES.EDIT_NEWS.path}/${props.articleId}`}
						>
							<EyeOutlined
								style={{
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CloseCircleFilled
							onClick={() => {
								deleteItem(props.id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Featured List</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={20}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<SelectTags
						singleSelect
						initialValues={[]}
						onSelectionChange={(tags) => {
							if (tags.length > 0) {
								setTag(tags[0]);
							}
						}}
					/>
				</Row>
				<Text
					style={{
						textDecorationLine: 'underline',
					}}
				>
					Add Article
				</Text>
				<Row
					style={{
						margin: '16px 0',
					}}
					gutter={16}
				>
					<Col span={8}>
						<Input
							value={newItemWeight}
							type="number"
							placeholder="Weight"
							onChange={(e) => {
								setNewItemWeight(e.target.valueAsNumber);
							}}
						/>
					</Col>
					<Col span={8}>
						<Input
							value={newItemArticleId}
							placeholder="Article ID"
							onChange={(e) => {
								setNewItemArticleId(e.target.value);
							}}
						/>
					</Col>
					<Col span={4}>
						<Button
							ghost
							type="primary"
							style={{
								fontSize: 'clamp(9px, 1vw, 10px)',
								width: '100%',
							}}
							onClick={async (e) => {
								e.stopPropagation();
								if (newItemArticleId && newItemWeight && tag && tag !== '') {
									createItem();
								} else {
									CustomNotification({
										pageName: 'Featured List',
										pagePrefix: 'Featured List',
										notificationType: 'custom-error',
										customDescription: 'Please fill all the fields',
									});
								}
							}}
						>
							CREATE
						</Button>
					</Col>
				</Row>
				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Category}
						columnData={columns}
						onChange={() => {
							//
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default FeaturedList;
