import {
	CheckCircleFilled,
	CloseCircleFilled,
	LoadingOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import InfoTable from 'src/components/info-table';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import {
	CmsCreateEventGenreMutation,
	CmsCreateEventGenreMutationVariables,
	CmsDeleteEventGenreMutation,
	CmsDeleteEventGenreMutationVariables,
	CmsGetGenresQuery,
	CmsUpdateEventGenreMutation,
	CmsUpdateEventGenreMutationVariables,
	GenreInput,
} from 'src/lib/generated/_generated';

import {
	MutationCreateEventGenre,
	MutationDeleteEventGenre,
	MutationUpdateEventGenre,
} from 'src/lib/gql/mutations';
import { QueryGetGenres } from 'src/lib/gql/queries';
import { EventGenreTable, EventTable } from 'src/lib/interface';

const EventGenre = () => {
	const [category, setCategory] = useState<string | undefined>(undefined);

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetGenresQuery>(QueryGetGenres, {
		fetchPolicy: 'cache-and-network',
	});

	const p_Category: EventGenreTable[] = [];

	const p_Category_Query = qryData?.getGenres?.map((genre, i) => {
		return p_Category.push({
			id: genre?.id || '',
			key: (i + 1).toString() || '',
			name: genre?.name || '',
			enabled: genre?.enabled || false,
		});
	});

	const [CreateEventCategory] = useMutation<
		CmsCreateEventGenreMutation,
		CmsCreateEventGenreMutationVariables
	>(MutationCreateEventGenre, {
		onError(error) {
			CustomNotification({
				pageName: 'Events Genres',
				pagePrefix: 'Genre',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const createType = async (data: GenreInput) => {
		const rsp = await CreateEventCategory({
			variables: {
				data: {
					name: data.name,
				},
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Events Genres',
				pagePrefix: 'Genre',
				notificationType: 'created',
			});

			refetchQuery();
		}
	};

	const [UpdateEventCategory] = useMutation<
		CmsUpdateEventGenreMutation,
		CmsUpdateEventGenreMutationVariables
	>(MutationUpdateEventGenre, {
		onError(error) {
			CustomNotification({
				pageName: 'Events Genres',
				pagePrefix: 'Genre',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateType = async (id: string, name: string, enabled: boolean) => {
		const rsp = await UpdateEventCategory({
			variables: {
				id: id,
				data: {
					name: name,
					enabled: enabled,
				},
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Events Categories',
				pagePrefix: 'Genre',
				notificationType: 'updated',
			});

			refetchQuery();
		}
	};

	const [DeleteEventCategory] = useMutation<
		CmsDeleteEventGenreMutation,
		CmsDeleteEventGenreMutationVariables
	>(MutationDeleteEventGenre);

	const deleteType = useCallback(
		async (id: string) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await DeleteEventCategory({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Events Categories',
					pagePrefix: 'Genre',
					notificationType: 'deleted',
				});

				refetchQuery();
			}
		},
		[qryData?.getGenres],
	);

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<EventTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 40,
			align: 'center',

			render: (props: { id: string; name: string; enabled: boolean }) => {
				const { id, name, enabled } = props;

				return (
					<Space>
						<CheckCircleFilled
							onClick={() => {
								updateType(id, name, !enabled);
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteType(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Events Genres</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<label
						style={{
							width: '100%',
						}}
					>
						<Text
							style={{
								textTransform: 'uppercase',
								border: '1px solid black',
								borderRadius: '2px',
								background: '#eaeaea',
								padding: '0.35rem 1.25rem 0.25rem 1.25rem',
								fontSize: '0.7rem',
								marginBottom: '0.5rem',
								letterSpacing: '0.05em',
								display: 'inline-block',
								color: 'black',
							}}
						>
							Create Genre
						</Text>
						<Input
							onChange={(e) => {
								setCategory(e.target.value);
							}}
							suffix={
								<Button
									ghost
									type="primary"
									size="small"
									style={{
										fontSize: 'clamp(9px, 1vw, 10px)',
									}}
									onClick={async (e) => {
										e.stopPropagation();
										if (category !== undefined) {
											createType({ name: category });
										}
									}}
								>
									CREATE
								</Button>
							}
						/>
					</label>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Category}
						columnData={columns}
						onChange={() => {
							//
						}}
						expandable={{
							expandedRowRender: (record) => (
								<label>
									<Text
										style={{
											textTransform: 'uppercase',
											border: '1px solid #85a6cc',
											borderRadius: '2px',
											background: 'rgb(133, 166, 204, 0.15)',
											padding: '0.35rem 1.25rem 0.25rem 1.25rem',
											fontSize: '0.7rem',
											marginBottom: '0.5rem',
											letterSpacing: '0.05em',
											display: 'inline-block',
											color: '#0560c7',
										}}
									>
										Update Genre
									</Text>
									<Input
										defaultValue={record.name}
										onChange={(e) => {
											record.name = e.target.value;
										}}
										suffix={
											<Button
												ghost
												type="primary"
												size="small"
												style={{
													fontSize: 'clamp(9px, 1vw, 10px)',
													color: 'var(--valid)',
													borderColor: 'var(--valid)',
												}}
												onClick={async (e) => {
													e.stopPropagation();
													updateType(record.id, record.name, record.enabled);
												}}
											>
												UPDATE
											</Button>
										}
									/>
								</label>
							),
							rowExpandable: (record) => record.name !== 'Not Expandable',
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default EventGenre;
