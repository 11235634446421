import { SelectOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Col, Image, Pagination, Spin, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { QueryMediaPagination } from 'src/lib/gql/queries';
import { MediaFoldersNames } from 'src/lib/interface';
import MediaUpload from './media-upload';
import {
	CmsMediaPaginationQuery,
	CmsMediaPaginationQueryVariables,
} from 'src/lib/generated/_generated';

const { TabPane } = Tabs;

interface Props {
	callBack: (url: string | undefined) => void;
}

const MediaPicker = React.memo((props: Props) => {
	const [perPage, setPerPage] = useState(20);
	const [pageNumber, setPageNumber] = useState(1);
	const totalResult = useRef(1);
	const [uploadKey, setUploadKey] = useState(-1);
	const [mediaName, setMediaName] = useState<string | undefined>(undefined);
	const [imageUploaded, setImageUploaded] = useState(false);
	const [activeTab, setActiveTab] = useState('1');
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsMediaPaginationQuery, CmsMediaPaginationQueryVariables>(
		QueryMediaPagination,
		{
			fetchPolicy: 'cache-and-network',
			notifyOnNetworkStatusChange: true,
			variables: {
				pageNumber: pageNumber,
				perPage: perPage,
				name: mediaName,
			},
		},
	);
	useEffect(() => {
		if (!loading) {
			totalResult.current =
				qryData?.pagination?.medias?.paginationInfo?.totalResult || 1;

			setUploadKey(Math.random());
		}
	}, [qryData?.pagination?.medias?.paginationInfo?.totalResult, loading]);
	return (
		<Tabs
			defaultActiveKey="1"
			onChange={(tab) => {
				setActiveTab(tab);
			}}
			tabPosition="left"
			activeKey={activeTab}
		>
			<TabPane tab="Image Gallery" key="1">
				<div>
					<Spin spinning={loading}>
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								height: '400px',
								overflowY: 'scroll',
								margin: '1rem 0',
							}}
						>
							{qryData?.pagination?.medias?.result.map((media) => {
								return (
									<Col
										key={media.id}
										span={24}
										lg={8}
										style={{
											position: 'relative',
										}}
									>
										<Image
											width={200}
											src={media.url}
											height={200}
											style={{
												objectFit: 'cover',
												objectPosition: 'center',
											}}
										/>
										<Button
											onClick={(e) => {
												e.stopPropagation();
												props.callBack(media.s3Url || '');
											}}
											style={{
												position: 'absolute',
												bottom: 15,
												right: 20,
												border: 'none',
												boxShadow: '0 0 6px rgba(0,0,0,0.4)',
											}}
										>
											<SelectOutlined />
										</Button>
									</Col>
								);
							})}
						</div>

						<Pagination
							pageSizeOptions={['20', '50']}
							disabled={loading}
							showSizeChanger
							onChange={(page) => {
								setPageNumber(page);
							}}
							onShowSizeChange={(current, size) => {
								setPerPage(size);
							}}
							defaultPageSize={perPage}
							defaultCurrent={pageNumber}
							total={totalResult.current}
						/>
					</Spin>
				</div>
			</TabPane>
			<TabPane tab="Upload Image" key="2">
				<MediaUpload
					folder={MediaFoldersNames.article}
					key={uploadKey}
					accept={'IMAGE'}
					onMediaUploaded={(val) => {
						if (pageNumber === 1 && perPage === 20) {
							refetchQuery();
						} else {
							setPageNumber(1);
							setPerPage(20);
						}
						setUploadKey(Math.random());
						setImageUploaded(true);
						setActiveTab('1');
					}}
					placeholderText={
						imageUploaded
							? 'Image uploaded. Please check the image gallery to select it.'
							: 'Drag and drop a photo here to add it to the gallery.'
					}
				/>
			</TabPane>
		</Tabs>
	);
});

export default MediaPicker;
