import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './item';
import { Button, Image } from 'antd';
import { DragOutlined } from '@ant-design/icons';

interface Props {
	id: string;
	style?: any;
	imgSrc?: string;
	indexValue?: string;
	onDelete?:
		| ((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
		| undefined;
}

export const SortableItem = (props: Props) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({
			id: props.id,
			transition: {
				duration: 150,
				easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
			},
		});

	const style: any = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<Item ref={setNodeRef} style={style} className={'mainItem'}>
			<Button
				{...attributes}
				{...listeners}
				icon={<DragOutlined />}
				className={'dragBtn'}
			/>

			<span className="index">{props.indexValue}</span>

			<Image
				width={'100%'}
				height={'100%'}
				style={{
					objectFit: 'cover',
					objectPosition: 'center',
					borderRadius: 10,
				}}
				preview={{
					maskClassName: 'mask',
				}}
				src={props.imgSrc}
			/>
		</Item>
	);
};
