import { LinkOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	Radio,
	Row,
	Select,
	Space,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import VenueSelectDropDown from 'src/components/venue-selector';
import { EventsForm } from 'src/lib/form-interfaces';
import {
	QueryGetGenres,
	QueryGetVenues,
	QueryThingsToDo,
} from 'src/lib/gql/queries';
import { Media, MediaFoldersNames } from 'src/lib/interface';
import EditorComp from '../editor';
import EmbeddedInput from '../embedded-input';
import MediaUpload from '../media-upload';
import MultiDateSelector from '../multi-date';
import SlugInput from '../slug-input';
import TagInput from '../tag-input';
import {
	CmsGetGenresQuery,
	CmsGetThingsToDoQuery,
	CmsGetVenuesQuery,
	CmsGetVenuesQueryVariables,
	OccurrenceTime,
} from 'src/lib/generated/_generated';

interface Props {
	// eventName?: string; // Event Name
	// eventParentName?: string; // Event Group Name
	// eventSlug?: string; //Optional - Link for story
	// eventTag?: string; // Event Tag
	// eventType?: string[]; // Select Event Type
	// eventVenue?: string; // Select Venue for Event
	// eventDesc?: string; // Event Information and Description
	// eventMusicGenre?: string[];
	// eventOffers?: string; // Event Offers (TinyCME)
	// eventDates?: string[]; //Selected Dates

	// // eventLocation?: string; // How to get there - Google Map URL
	// eventBookingURL?: string; // CTA Links -> Booking URL
	// eventContactNumber?: string; // CTA Links -> Contact Number
	// eventPoster?: Media; // Event Poster / Banner
	// eventVideoCover?: string; // Event Video Thumbnail
	// eventVideoHref?: string; // Event Video Youtube ID
	// eventPhotographerRequested?: boolean; // Photographer Requested
	// eventFeatured?: boolean; // Event Featured
	// canDelete?: boolean;
	// isCreatePage?: boolean;
	// isActivationPage?: boolean; // Only for Event Activation
	// eventOccurrenceTime?: string;
	onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
	onSave: ({
		name,
		description,
		offers,
		ctaLink,
		ctaNumber,
		photographer,
		eventParentId,
		posterId,
		videoUrl,
		genreId,
		slug,
		occurrenceTime,
		venueId,
		eventTypeIds,
		eventDates,
		parentName,
		featured,
		color,
		meta,
	}: {
		name: string;
		description: string;
		offers: string;
		ctaLink: string;
		ctaNumber: string;
		photographer: boolean;
		eventParentId: string;
		slug: string;
		occurrenceTime: OccurrenceTime;
		venueId: string;
		eventTypeIds: string[];
		posterId: string | null;
		videoUrl: string;
		hTag: string;
		genreId: string[];
		eventDates: Date[];
		parentName: string;
		featured: boolean;
		color: string;
		meta: string;
	}) => void;
}

const CreateEventCard = (props: Props) => {
	const [coverPhotoId, setCoverPhotoId] = useState<string | null>(null);
	// const [CPicker, setCPicker] = useState<string | undefined>(undefined);
	const params = useParams<{ id: string }>();

	const [form] = useForm<EventsForm>();

	const selectedDays = useRef<Date[]>([]);

	const { Option } = Select;
	const [venueName, setVenueName] = useState<string | undefined>(undefined);
	const { data: eventTypeQuery } =
		useQuery<CmsGetThingsToDoQuery>(QueryThingsToDo);
	const { data: venuesQuery, loading: venueLoading } = useQuery<
		CmsGetVenuesQuery,
		CmsGetVenuesQueryVariables
	>(QueryGetVenues, {
		variables: {
			pageNumber: 1,
			perPage: 10,
			name: venueName,
		},
		notifyOnNetworkStatusChange: true,
	});
	const { data: genreQuery } = useQuery<CmsGetGenresQuery>(QueryGetGenres);

	/* Event Media handler */
	const mediaCallback = useCallback((media: Media) => {
		setCoverPhotoId(media.id || null);
	}, []);

	/* Call this function on saving the form to create event */
	const createEvent = async (data: EventsForm) => {
		// if (coverPhotoId === null || coverPhotoId === undefined) {
		// 	CustomNotification({
		// 		pageName: 'Events',
		// 		errorName: 'Cover Image',
		// 		notificationType: 'error',
		// 	});
		// 	return;
		// }

		if (selectedDays.current.length === 0) {
			CustomNotification({
				pageName: 'Events',
				errorName: 'Date',
				notificationType: 'error',
			});
			return;
		}

		props.onSave({
			name: data.name,
			parentName: data.parentName,
			description: data.description,
			offers: data.offers,
			ctaLink: data.ctaLink,
			ctaNumber: data.ctaNumber,
			photographer: data.photographer || false,
			eventParentId: data.eventParentId,
			slug: data.slug,
			eventTypeIds: data.eventTypeIds,
			venueId: data.venueId,
			occurrenceTime: data.occurrenceTime,
			posterId: coverPhotoId || null,
			videoUrl: data.videoUrl,
			genreId: data.genreId,
			eventDates: selectedDays.current,
			hTag: data.hTag,
			featured: data.featured,
			color: data.color,
			meta: data.meta,
		});
	};

	/* 
	
	Event Date Comp

	-Selected Event Date
	-Shift Event Date
	-Featured
	-Photographer

	Event Parent Comp

	-Parent Name
	-Event Tag
	-Slug
	-Occurrence
	-Event Type
	-Venue ID/Type
	-Genre
	-Color
	-Meta


	Event Comp

	-Event Name
	-Event Info/Desc
	-Event Offers
	-Booking URL
	-Contact Number
	-Event Media
	-Event Video
	-Apply Changes to (Date)
	-Add more dates (Run the event additional for selected new dates)
	
	
	*/

	return (
		<Form
			form={form}
			onFinish={createEvent}
			style={{
				width: '100%',
			}}
		>
			<Row style={{ width: '100%', marginTop: '1em' }} gutter={16}>
				{/* Name / Title */}

				<div
					style={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					{/* DIVIDER - EVENT OFFERS / MUSIC GENRE */}
					<Col
						span={24}
						style={{
							marginBottom: '1em',
						}}
					>
						<Text className="sub-header">Event Information</Text>
					</Col>
					<Col span={24} md={8}>
						<label className="input-fields-label">Event Group Name</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="parentName"
						>
							<Input
								type="text"
								placeholder="Event Group Name"
								className="input-fields"
								style={{
									padding: '1em',
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={24} md={8}>
						<label className="input-fields-label">Event Name</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="name"
						>
							<Input
								type="text"
								placeholder="Event Name"
								className="input-fields"
								style={{
									padding: '1em',
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24} md={8}>
						<label className="input-fields-label">Event Tag</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="hTag"
						>
							<TagInput prefix={'Events'} />
						</Form.Item>
					</Col>

					<Col span={24}>
						<label className="input-fields-label">Slug</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="slug"
						>
							<SlugInput />
						</Form.Item>
					</Col>

					{/* Event Type / Select a Venue */}
					<Col span={24} md={8}>
						<label className="input-fields-label">Event Timing</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="occurrenceTime"
						>
							<Select
								style={{ width: '100%' }}
								placeholder="Event Timing"
								size="middle"
								className="bg-select"
							>
								{/* Event timing */}
								<Option value={OccurrenceTime.All}>All</Option>
								<Option value={OccurrenceTime.Day}>Day</Option>
								<Option value={OccurrenceTime.Night}>Night</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24} md={8}>
						<label className="input-fields-label">Event Type</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="eventTypeIds"
						>
							<Select
								allowClear
								suffixIcon={null}
								style={{ width: '100%' }}
								placeholder="Event Type"
								size="middle"
								mode="multiple"
								className="multiple-custom-select"
							>
								{eventTypeQuery?.getEventTypes?.map((type, i) => {
									return (
										<Option key={`eventType${i}`} value={type?.id || '-1'}>
											{type?.name}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>

					<Col span={24} md={8}>
						<label className="input-fields-label">Venue</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="venueId"
						>
							<VenueSelectDropDown />
							{/* <Select
								style={{ width: '100%' }}
								placeholder="Select a Venue"
								onChange={(v: string) => {
									//
								}}
								loading={venueLoading}
								onSearch={(s) => setVenueName(s)}
								size="middle"
								className="bg-select"
								filterOption={false}
								showSearch
							>
								{venuesQuery?.pagination?.venues?.result?.map((venue, i) => {
									return (
										<Option key={`venueID${i}`} value={venue?.id || '-1'}>
											{venue?.name}
										</Option>
									);
								})}
							</Select> */}
						</Form.Item>
					</Col>

					<Col span={24} md={12}>
						<label className="input-fields-label">
							Event Info / Description
						</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="description"
						>
							<EditorComp initialValue={'Event Info / Description'} />
						</Form.Item>
					</Col>

					<Col span={24} md={12}>
						<label className="input-fields-label">SEO Meta Description</label>

						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							hasFeedback
							name="meta"
						>
							<Input.TextArea
								className="input-fields"
								style={{
									height: '100%',
								}}
								placeholder="Meta Description"
								rows={16}
							/>
						</Form.Item>
					</Col>
					<Col span={24} md={12}>
						<Text style={{ display: 'block', marginTop: '1em' }} strong={true}>
							Dates
						</Text>
						<Text
							style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}
						>
							Run the event additionally for the selected dates
						</Text>

						<MultiDateSelector
							initialDates={[]}
							onInit={(dates) => {
								selectedDays.current = dates;
							}}
							onChange={(dates) => (selectedDays.current = dates)}
						/>
						{/* <DayPicker
								onDayClick={handleDayClick}
								selectedDays={selectedDays || []}
								showOutsideDays={true}
								className="margin-0"
								disabledDays={{
									before: new Date(),
								}}
							/> */}
					</Col>

					{/* Offers / Music Genre */}

					{/* DIVIDER - EVENT OFFERS / MUSIC GENRE */}
					<Col
						span={24}
						style={{
							marginTop: '1.5em',
						}}
					>
						<Text className="sub-header">Event Offers / Music Genre</Text>
						<Divider
							style={{
								margin: 0,
							}}
						/>
					</Col>

					<Col
						span={24}
						md={12}
						style={{
							marginTop: '1em',
						}}
					>
						<Row
							className="input-fields"
							style={{
								marginBottom: '0',
							}}
						>
							<label className="input-fields-label">Offers</label>

							<Form.Item
								name="offers"
								style={{
									width: '100%',
								}}
							>
								<EditorComp initialValue={'Offers'} />
							</Form.Item>
						</Row>
					</Col>

					<Col
						span={24}
						md={12}
						style={{
							marginTop: '1.5em',
						}}
					>
						<Card
							bordered={false}
							style={{
								background: '#f4f4f4',
								height: '100%',
							}}
						>
							<Row style={{ width: '100%' }}>
								<Text
									className="sub-header"
									style={{
										fontSize: '14px',
									}}
								>
									Music Genre
								</Text>

								<Row
									wrap={true}
									style={{
										width: '100%',
									}}
								>
									<Col span={24}>
										<Form.Item
											rules={[
												{
													required: true,
												},
											]}
											hasFeedback
											name="genreId"
										>
											{/* Genres */}
											<Select
												suffixIcon={null}
												allowClear
												mode="multiple"
												style={{ width: '100%' }}
												placeholder="Select Genre"
												// onChange={(v: string[]) => {
												// 	setGenreIds(v);
												// }}
												size="middle"
												className="multiple-custom-select"
											>
												{genreQuery?.getGenres?.map((genre, i) => {
													return (
														<Option
															key={`genreID${genre?.id}`}
															value={genre?.id || ''}
														>
															{genre?.name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Row>
						</Card>
					</Col>

					{/* END - Offers / Music Genre */}

					{/* DIVIDER - EVENT ADDITIONAL INFO */}

					<Col
						span={24}
						style={{
							margin: '0.5em 0',
						}}
					>
						<Text className="sub-header">Event Additional Information</Text>
						<Divider
							style={{
								margin: 0,
							}}
						/>
					</Col>

					{/* Event Location */}

					<Col span={24} md={16}>
						<label className="input-fields-label">Booking URL</label>

						<Form.Item name="ctaLink">
							<Input
								prefix={<LinkOutlined />}
								type="text"
								placeholder="Booking URL"
								className="input-fields"
							/>
						</Form.Item>
					</Col>

					<Col span={24} md={8}>
						<label className="input-fields-label">Contact Number</label>

						<Form.Item name="ctaNumber">
							<Input
								type="tel"
								placeholder="Contact Number"
								className="input-fields"
							/>
						</Form.Item>
					</Col>

					{/* Event Poster / Banner */}

					{/* DIVIDER - EVENT MEDIA */}
					<Col
						span={24}
						style={{
							margin: '0.5em 0',
						}}
					>
						<Text className="sub-header">Event Media (Poster)</Text>
						<Divider
							style={{
								margin: 0,
							}}
						/>
					</Col>

					<Col span={24} md={12}>
						<MediaUpload
							folder={MediaFoldersNames.misc}
							onMediaUploaded={mediaCallback}
							accept="IMAGE"
							placeholderText={'Drag and drop an Event Poster image'}
						/>
					</Col>
					<Col
						span={24}
						md={12}
						style={{
							marginBottom: '4em',
						}}
					>
						<Form.Item name="videoUrl">
							<EmbeddedInput link="" />
							{/* <Input
									className="input-fields"
									type="text"
									placeholder="Youtube Video ID"
								/> */}
						</Form.Item>
					</Col>

					{/* DIVIDER - OTHER */}

					<Col
						span={24}
						style={{
							margin: '0.5em 0',
						}}
					>
						<Text className="sub-header">Other</Text>
						<Divider
							style={{
								margin: 0,
							}}
						/>
					</Col>

					{/* <Col span={24}>
							<Form.Item name="photographer">
								<Checkbox
									style={{
										margin: '0.5em 0',
									}}
									disabled={true}
								>
									Request for DubaiNight{"'"}s photographer / As far as
									availability
								</Checkbox>
							</Form.Item>
						</Col> */}

					<Col span={24} lg={12}>
						<Text
							style={{
								display: 'block',
								textTransform: 'uppercase',
								fontFamily: 'Avenir Heavy',
								fontSize: '12px',
							}}
						>
							Assign a Photographer
						</Text>
						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							name="photographer"
						>
							<Radio.Group>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>
					</Col>

					<Col
						span={24}
						lg={12}
						style={{
							marginTop: '1em',
						}}
					>
						<Text
							style={{
								display: 'block',
								textTransform: 'uppercase',
								fontFamily: 'Avenir Heavy',
								fontSize: '12px',
							}}
						>
							Featured
						</Text>
						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							name="featured"
						>
							<Radio.Group>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>
					</Col>

					{/* <Col span={24} lg={12}>
						<p
							style={{
								margin: 0,
								textTransform: 'uppercase',
								fontFamily: 'Avenir Heavy',
							}}
						>
							EVENT COLOR
						</p>
						<p
							style={{
								margin: 0,

								fontFamily: 'Avenir Medium',
								opacity: 0.7,
								fontSize: '11px',
							}}
						>
							Assign a color for event
						</p>
						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							name="color"
						>
							<SketchPicker
								color={CPicker}
								onChange={(v) => {
									setCPicker(v.hex);
									form.setFieldsValue({
										color: v.hex,
									});
								}}
							/>
						</Form.Item>
					</Col> */}

					<Col span={24}>
						<hr style={{ opacity: '0.25' }} />

						<Row justify={'center'}>
							<Space size="small">
								<Button className="btn-main save" htmlType="submit">
									Save
								</Button>
							</Space>
						</Row>
					</Col>
				</div>
			</Row>
		</Form>
	);
};

export default CreateEventCard;
