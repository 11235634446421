import { CloseCircleFilled, FormOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Link } from 'react-router-dom';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import { QueryPendingGetEventParents } from 'src/lib/gql/queries';
import { EventParentTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';
import { MutationDeleteEventParent } from 'src/lib/gql/mutations';
import { CustomNotification } from 'src/components/custom-notification';
import {
	CmsDeleteEventParentMutation,
	CmsDeleteEventParentMutationVariables,
	CmsGetPendingEventParentsQuery,
} from 'src/lib/generated/_generated';

const EventsActivation = () => {
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetPendingEventParentsQuery>(QueryPendingGetEventParents);

	const [deleteEventParent] = useMutation<
		CmsDeleteEventParentMutation,
		CmsDeleteEventParentMutationVariables
	>(MutationDeleteEventParent, {
		onError(error) {
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
		onCompleted() {
			refetchQuery();
			CustomNotification({
				pageName: 'Events',
				pagePrefix: 'Event',
				notificationType: 'custom-success',
				customDescription: `Event deleted successfully`,
			});
		},
	});

	const handleDelete = (id: string) => {
		if (!window.confirm('Are you sure you want to delete this event?')) return;

		deleteEventParent({
			variables: {
				id: id,
			},
			// Optional: Directly manipulate the cache to remove the deleted event
			update: (cache) => {
				cache.modify({
					fields: {
						getEventParents(existingEventParentsRefs, { readField }) {
							return existingEventParentsRefs.filter(
								(eventParentRef: any) => id !== readField('id', eventParentRef),
							);
						},
					},
				});
			},
		});
	};

	// Clear the array before repopulating
	const p_EventParents: EventParentTable[] = [];

	qryData?.getEventParents?.forEach((ep, i) => {
		return p_EventParents.push({
			id: ep.eventDates?.[0].id || '',
			name:
				'' +
				(ep.eventSource?.fName || '') +
				' ' +
				(ep.eventSource?.lName || ''),
			eventName: ep.parentName || '',
			key: '' + (i + 1),
			venueName: ep.venue?.name || '',
		});
	});

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Event Name',
			dataIndex: 'eventName',
			key: 'eventName',
		},

		{
			title: 'Venue Name',
			dataIndex: 'venueName',
			key: 'venueName',
			...tableColumnTextFilterConfig<EventParentTable>(),
			onFilter: (value, record) => {
				return record.venueName
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},

		{
			title: 'Edit',
			width: 80,
			align: 'center',

			render: (props: { id: string }) => {
				const { id } = props;
				return (
					<Space>
						<Link to={`${ROUTES.EDIT_EVENT.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>

						<CloseCircleFilled
							onClick={() => {
								handleDelete(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
							hidden
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Events Activation</Text>
			</Col>

			<Col
				span={24}
				style={{
					marginTop: '1em',
				}}
			>
				<InfoTable
					data={p_EventParents}
					columnData={columns}
					onChange={(pagination, filters, sorter) => {
						//
					}}
					other={{
						scroll: {
							x: 900,
						},
					}}
				/>
			</Col>
		</Row>
	);
};

export default EventsActivation;
