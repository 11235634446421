import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const BreadCrumbComp = () => {
	const location = useLocation();

	const breadCrumbView = () => {
		const { pathname } = location;
		const pathnames = pathname.split('/').filter((item) => item);

		const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

		const items =
			pathnames.length > 0
				? [{ title: <Link to="/">Home</Link>, key: 'home' }]
				: [{ title: 'Home', key: 'home' }];

		pathnames.forEach((name, index) => {
			const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
			let isLast = index === pathnames.length - 1;
			if (name.includes('edit') || name.includes('photographer')) {
				isLast = index === pathnames.length - 2;
			}
			items.push({
				title: isLast ? (
					capatilize(name)
				) : (
					<Link to={`${routeTo}`}>{capatilize(name)}</Link>
				),
				key: `route${index}`,
			});
		});

		return (
			<div>
				<Breadcrumb items={items} />
			</div>
		);
	};

	return <>{breadCrumbView()}</>;
};

export default BreadCrumbComp;
