import { useLazyQuery, useQuery } from '@apollo/client';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	CmsGetOneVenueNameQuery,
	CmsGetOneVenueNameQueryVariables,
	CmsGetVenuesQuery,
	CmsGetVenuesQueryVariables,
} from 'src/lib/generated/_generated';

import { QueryGetOneVenueName, QueryGetVenues } from 'src/lib/gql/queries';

const VenueSelectDropDown = (props: any) => {
	const [venueName, setVenueName] = useState<string | undefined>(undefined);
	const { data: qryData } = useQuery<
		CmsGetVenuesQuery,
		CmsGetVenuesQueryVariables
	>(QueryGetVenues, {
		variables: {
			pageNumber: 1,
			perPage: 10,
			name: venueName,
		},
		notifyOnNetworkStatusChange: true,
	});
	const [callVenueDetails, { data: oneVenueData }] = useLazyQuery<
		CmsGetOneVenueNameQuery,
		CmsGetOneVenueNameQueryVariables
	>(QueryGetOneVenueName, {
		variables: {
			id: props.value,
		},
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		if (props.value) callVenueDetails();
	}, [props.value]);

	const { Option } = Select;
	return (
		<Select
			allowClear
			style={{ width: '100%' }}
			placeholder="Select a Venue"
			size="middle"
			className="bg-select"
			filterOption={false}
			showSearch
			value={props.value}
			onChange={props.onChange}
			onSearch={(s) => setVenueName(s)}
		>
			{oneVenueData?.getVenue !== null && (
				<Option value={oneVenueData?.getVenue?.id || ''}>
					{oneVenueData?.getVenue?.name}
				</Option>
			)}
			{qryData?.pagination?.venues?.result?.map((venue, i) => {
				return (
					<Option key={`venuname ${i}`} value={venue?.id || ''}>
						{venue?.name}
					</Option>
				);
			})}
		</Select>
	);
};

export default VenueSelectDropDown;
