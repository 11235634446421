export interface RouteType {
	[key: string]: RouteInt;
}

export interface RouteInt {
	path: string;
	name: string;
	params?: [string];
}

export function genPath(params: RouteInt): string {
	const fPath = `${params.path}${
		params.params?.map((par) => `/:${par}`) || ''
	}`;
	return fPath;
}

export const ROUTES: RouteType = {
	ROOT: { name: 'Intro Video', path: '/' },
	LOGIN: { name: 'Login', path: '/login' },
	INTRO_VIDEO: { name: 'Intro Video', path: '/intro-video' },
	CREATE_INTRO_VIDEO: {
		name: 'Create Intro Video',
		path: '/intro-video/create',
	},
	EDIT_INTRO_VIDEO: {
		name: 'Edit Intro Video',
		path: '/intro-video/edit',
		params: ['id'],
	},
	STORIES: { name: 'Stories', path: '/stories' },
	CREATE_STORY: { name: 'Create Story', path: '/stories/create' },
	EDIT_STORY: { name: 'Edit Story', path: '/stories/edit', params: ['id'] },
	SLIDERS: { name: 'Slider', path: '/slider' },
	CREATE_SLIDER: { name: 'Slider', path: '/slider/create' },
	EDIT_SLIDER: { name: 'Slider', path: '/slider/edit', params: ['id'] },
	THINGS_TO_DO: { name: 'Things To Do', path: '/things-to-do' },
	CREATE_THINGS_TO_DO: {
		name: 'Create Things To Do Category',
		path: '/things-to-do/create',
	},
	EDIT_THINGS_TO_DO: {
		name: 'Edit Things To Do Category',
		path: '/things-to-do/edit',
		params: ['id'],
	},
	NEWS: { name: 'News', path: '/news' },
	CREATE_NEWS: { name: 'Create News', path: '/news/create' },
	EDIT_NEWS: { name: 'Edit News', path: '/news/edit', params: ['id'] },
	NEWS_COMMENTS: { name: 'News Comments', path: '/news/comments' },
	NEWS_CATEGORIES: {
		name: 'News Categories',
		path: '/news/categories',
	},
	EDIT_NEWS_CATEGORY: {
		name: 'Edit News Category',
		path: '/news/categories/edit',
		params: ['id'],
	},
	CREATE_NEWS_CATEGORY: {
		name: 'Create News Category',
		path: '/news/categories/create',
	},
	EDIT_FEATURED_LIST: {
		name: 'Edit Featured List',
		path: '/news/featured-list',
	},
	EVENTS: { name: 'Events', path: '/events' },
	CREATE_EVENT: { name: 'Create Event', path: '/events/create' },
	EDIT_EVENT: { name: 'Edit Event', path: '/events/edit', params: ['id'] },
	VIEW_PENDING_EVENTS: {
		name: 'Validate Pending Events',
		path: '/events/pending',
	},
	ACTIVATE_EVENT: {
		name: 'Validate Event',
		path: '/events/pending/edit',
		params: ['id'],
	},
	EVENT_PHOTOGRAPHER: {
		name: 'Event Photographer Assigning',
		path: '/events/photographers',
	},
	EVENTS_GENRES: {
		name: 'Event Genres',
		path: '/events/genres',
	},
	VENUES: { name: 'Venues', path: '/venues' },
	CREATE_VENUE: { name: 'Create Venue', path: '/venues/create' },
	EDIT_VENUE: { name: 'Edit Venue', path: '/venues/edit', params: ['id'] },
	REPORT_VENUE: {
		name: 'Report Venue',
		path: '/venues/report',
		params: ['id'],
	},
	OFFERS: { name: 'Offers', path: '/offers' },
	VIEW_PENDING_OFFERS: {
		name: 'Validate Pending Offers',
		path: '/offers/pending',
	},
	CREATE_OFFERS: { name: 'Create Offers', path: '/offers/create' },
	EDIT_OFFERS: { name: 'Edit Offers', path: '/offers/edit', params: ['id'] },
	OFFERS_CATEGORIES: { name: 'Offers Categories', path: '/offers/categories' },
	CREATE_OFFER_CATEGORY: {
		name: 'Create Offer Category',
		path: '/offers/categories/create',
	},
	EDIT_OFFER_CATEGORY: {
		name: 'Edit Offer Category',
		path: '/offers/categories/edit',
		params: ['id'],
	},
	DISCOVER_CITY: { name: 'Discover the City', path: '/discover-the-city' },
	CREATE_DISCOVER_CITY: {
		name: 'Create Discover the City',
		path: '/discover-the-city/create',
	},
	EDIT_DISCOVER_CITY: {
		name: 'Edit Discover the City',
		path: '/discover-the-city/edit',
		params: ['id'],
	},
	BEST_PLACES: { name: 'Best Places', path: '/best-places' },
	CREATE_BEST_PLACES: {
		name: 'Create Best Places',
		path: '/best-places/create',
	},
	EDIT_BEST_PLACES: {
		name: 'Edit Best Places',
		path: '/best-places/edit',
		params: ['id'],
	},
	PHOTOGRAPHERS: { name: 'Photographers', path: '/users/photographers' },
	PHOTOS: { name: 'Photos', path: '/photos' },
	EDIT_PHOTOS: { name: 'Edit Photos', path: '/photos/edit', params: ['id'] },
	VIDEOS: { name: 'Videos', path: '/videos' },
	CREATE_VIDEOS: { name: 'Create Videos', path: '/videos/create' },
	EDIT_VIDEOS: { name: 'Edit Videos', path: '/videos/edit', params: ['id'] },
	VIDEOS_CATEGORY: {
		name: 'Videos Categories',
		path: '/videos/categories',
	},
	EDIT_VIDEOS_CATEGORY: {
		name: 'Edit Videos Category',
		path: '/videos/categories/edit',
		params: ['id'],
	},
	CREATE_VIDEOS_CATEGORY: {
		name: 'Create Videos Category',
		path: '/videos/categories/create',
	},
	USERS: { name: 'Users', path: '/users' },
	PHOTOGRAPHER_HOME: { name: 'Home', path: '/' },
	PHOTOGRAPHER_PHOTOS: {
		name: 'Photos',
		path: '/photographer',
		params: ['id'],
	},
	PHOTOGRAPHERS_REQUESTS: {
		name: 'Photographers Requests',
		path: '/users/photographers-requests',
	},
	PHOTOGRAPHER_PROFILE: {
		name: 'Photographer Profile',
		path: '/profile',
	},
	PHOTOGRAPHER_EVENTSELFASSIGN: {
		name: 'Photographer Event Assigning',
		path: '/events/assign',
	},
	REGION_SETTINGS: {
		name: 'Region Settings',
		path: '/settings',
	},
	POPUP_AD: { name: 'Popup Ad', path: '/popup-ad' },
	CREATE_POPUP_AD: {
		name: 'Create Popup Ad',
		path: '/popup-ad/create',
	},
	EDIT_POPUP_AD: {
		name: 'Edit Popup Ad',
		path: '/popup-ad/edit',
		params: ['id'],
	},
};
