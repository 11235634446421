import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { CustomNotification } from 'src/components/custom-notification';
import MediaUpload from 'src/components/ui/media-upload';
import ApproveCard from 'src/components/ui/photos/approve-card';
import SortableCard from 'src/components/ui/photos/sortable-card';
import {
	CmsGetEventPhotosQuery,
	CmsGetEventPhotosQueryVariables,
	CmsUpdateEventDateMutation,
	CmsUpdateEventDateMutationVariables,
} from 'src/lib/generated/_generated';

import { MutationUpdateEventDate } from 'src/lib/gql/mutations';
import { QueryGetEventPhotos } from 'src/lib/gql/queries';
import { MediaFoldersNames } from 'src/lib/interface';

enum Tabs {
	APPROVE,
	REORDER,
}

const EditPhotos = () => {
	interface Params {
		id: string;
		[key: string]: string | undefined;
	}

	const params = useParams<Params>();

	const {
		data: qryData,
		refetch: refetchQuery,
		loading,
	} = useQuery<CmsGetEventPhotosQuery, CmsGetEventPhotosQueryVariables>(
		QueryGetEventPhotos,
		{
			variables: {
				id: params.id ?? '',
			},
			fetchPolicy: 'cache-and-network',
		},
	);

	const [updateEventDate] = useMutation<
		CmsUpdateEventDateMutation,
		CmsUpdateEventDateMutationVariables
	>(MutationUpdateEventDate, {
		onError(error) {
			CustomNotification({
				pageName: 'Photos',
				pagePrefix: 'Photo',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const [approveOrReorder, setApproveOrReorder] = useState<Tabs>(Tabs.APPROVE);

	return (
		<Row>
			{loading && (
				<Col span={24}>
					<Row justify="end">
						<Spin
							indicator={<LoadingOutlined />}
							size="large"
							spinning={true}
						/>
					</Row>
				</Col>
			)}
			<Row
				style={{
					width: '100%',
					display: 'block',
					marginBottom: '2rem',
				}}
			>
				<Card title={qryData?.getEventDate?.event?.name}>
					<div>
						Venue: <b>{qryData?.getEventDate?.eventParent?.venue?.name}</b>
					</div>
					<div>
						Date: <b>{qryData?.getEventDate?.eDate}</b>
					</div>
				</Card>
			</Row>
			<Col span={24}>
				<Row
					justify="center"
					style={{
						marginBottom: '2rem',
					}}
				>
					<Button
						className={[
							'btn-main',
							approveOrReorder === Tabs.APPROVE ? 'active' : undefined,
						].join(' ')}
						onClick={() => {
							setApproveOrReorder(Tabs.APPROVE);
						}}
					>
						Approve
					</Button>
					<Button
						className={[
							'btn-main',
							approveOrReorder === Tabs.REORDER ? 'active' : undefined,
						].join(' ')}
						onClick={() => {
							setApproveOrReorder(Tabs.REORDER);
						}}
					>
						Reorder
					</Button>
				</Row>

				<Row>
					<p className="sub-header margin-0">Album Cover</p>
				</Row>

				<Row
					style={{
						width: '100%',
						display: 'block',
						marginBottom: '1rem',
					}}
				>
					<MediaUpload
						folder={MediaFoldersNames.misc}
						onMediaUploaded={(media) => {
							updateEventDate({
								variables: {
									id: qryData?.getEventDate?.id || '-1',
									albumCoverId: media.id,
								},
							});
						}}
						media={qryData?.getEventDate?.albumCover || undefined}
						accept="IMAGE"
						placeholderText={'Drag and drop an Album cover image'}
					/>
				</Row>

				{approveOrReorder === Tabs.APPROVE ? (
					<ApproveCard
						refetchQry={refetchQuery}
						eventDate={qryData?.getEventDate || undefined}
					/>
				) : (
					<SortableCard
						refetchQry={refetchQuery}
						eventDate={qryData?.getEventDate || undefined}
					/>
				)}
			</Col>
			{/* // )} */}
		</Row>
	);
};

export default EditPhotos;
