import { LinkOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback, useRef, useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import MediaUpload from 'src/components/ui/media-upload';
import MultiDateSelector from 'src/components/ui/multi-date';

import { Media, MediaFoldersNames } from 'src/lib/interface';
import SelectTags from '../select-tags';

const { Panel } = Collapse;

interface Props {
	type: 'create' | 'edit';
	panelHeader?: number;
	bannerTitle: string; // Banner Title (Create New Story / Story 1 / Story 2... etc)
	storyId?: string;
	storyMedia?: Media;
	storyName?: string; // Story Name
	storyLink?: string; //Optional - Link for story
	storyPosition?: number; //Position of Story
	// storyAppearIn?: string[]; //Appears in  Pages
	storyDates?: string[]; //Selected Dates
	storyHTags?: string[];
	canDelete?: boolean;
	onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
	onDelete?: (storyItemId: string | undefined) => void; // On Delete Click
	onSave: ({
		dates,
		link,
		mediaId,
		position,
		hTags,
	}: {
		dates: Date[];
		link: string;
		mediaId: string;
		position: number | null;
		hTags: string[];
	}) => void;
}

const StoryCard = (props: Props) => {
	// const [selectedDay, setSelectedDay] = useState<Date[] | null>(null);
	const selectedDays = useRef<Date[]>([]);
	const [mediaId, setMediaId] = useState<string | null>(null);
	const storyTags = useRef<string[]>([]);

	const tagSelectionChange = useCallback((val: string[]) => {
		storyTags.current = val;
	}, []);

	if (mediaId == null && props.storyMedia?.id) {
		setMediaId(props.storyMedia?.id);
	}

	if (storyTags.current.length === 0) {
		storyTags.current = props.storyHTags || [];
	}
	const mediaCallback = useCallback((media) => {
		setMediaId(media.id);
	}, []);

	const callParentSubmit = ({ link, weight }) => {
		// console.log(prop);
		if (selectedDays.current.length === 0) {
			CustomNotification({
				pageName: 'Stories',
				errorName: 'Date',
				notificationType: 'error',
			});
			return;
		}
		if (mediaId === null || mediaId === undefined) {
			CustomNotification({
				pageName: 'Stories',
				errorName: 'Image',
				notificationType: 'error',
			});
			return;
		}
		props.onSave({
			dates: selectedDays.current,
			link: link,
			mediaId: mediaId,
			position: weight ? Number.parseInt(weight) : null,
			hTags: storyTags.current,
		});
	};

	return (
		<Form
			onFinish={callParentSubmit}
			style={{
				width: '100%',
			}}
		>
			<Collapse
				defaultActiveKey={1}
				expandIconPosition="right"
				className="site-collapse-custom-collapse"
			>
				<Panel
					key={props?.panelHeader || '1'}
					header={props.bannerTitle}
					className="site-collapse-custom-panel"
				>
					<Row style={{ width: '100%' }}>
						<Col span={24} md={24}>
							<SelectTags
								initialValues={storyTags.current}
								onSelectionChange={tagSelectionChange}
							/>
						</Col>

						<Col span={24} md={24}>
							<MediaUpload
								folder={MediaFoldersNames.story}
								onMediaUploaded={mediaCallback}
								media={props.storyMedia || undefined}
								accept="BOTH"
							/>
						</Col>

						{/* Input Fields */}
						<Col span={24} md={24}>
							{/* <Input
								type="text"
								placeholder="Name / Title"
								className="input-fields"
								value={props.storyName}
							/> */}
							<label className="input-fields-label">Link</label>

							<Form.Item
								initialValue={props.storyLink}
								name="link"
								style={{
									margin: '8px 0',
								}}
							>
								<Input
									prefix={<LinkOutlined />}
									type="text"
									placeholder="Link"
									className="input-fields"
									// value={props.storyLink}
								/>
							</Form.Item>

							<label className="input-fields-label">Position</label>

							<Form.Item
								initialValue={props.storyPosition}
								name="weight"
								hasFeedback
								rules={[
									{
										validator: (rule, value: string, back) => {
											if (value !== undefined) {
												if (value.length > 4) {
													back('Too large');
													return;
												}
												if (value !== '' && parseInt(value) < 1) {
													back('Minimum value is 1');
													return;
												}
											}
											back(undefined);
											return;
										},
									},
								]}
								style={{
									margin: '8px 0',
								}}
							>
								<Input
									type="number"
									placeholder="Position / Sequence No."
									min="1"
									className="input-fields"

									// value={props.storyPosition}
								/>
							</Form.Item>
							<Text
								style={{ display: 'block', marginTop: '1em' }}
								strong={true}
							>
								Dates
							</Text>
							<Text
								style={{ display: 'block', fontSize: '12px', opacity: '0.50' }}
							>
								You can click on multiple dates to select the days to run the
								story for that duration
							</Text>

							<MultiDateSelector
								initialDates={props.storyDates || []}
								onChange={(dates) => (selectedDays.current = dates)}
								onInit={(dates) => (selectedDays.current = dates)}
							/>
							<hr style={{ opacity: '0.25' }} />
							<Row justify={'end'}>
								<Form.Item>
									<Button
										className="btn-main save"
										htmlType="submit"
										style={{
											marginRight: '8px',
										}}
									>
										Save
									</Button>
								</Form.Item>

								{props.canDelete ? (
									<Button
										className="btn-main delete"
										onClick={() =>
											props?.onDelete && props?.onDelete(props?.storyId)
										}
										style={{
											marginRight: '8px',
										}}
									>
										Delete
									</Button>
								) : (
									<Button
										className="btn-main"
										style={{
											marginRight: '8px',
										}}
										onClick={() => {
											window.history.back();
										}}
									>
										Cancel
									</Button>
								)}
							</Row>
						</Col>
					</Row>
				</Panel>
			</Collapse>
		</Form>
	);
};

export default StoryCard;
