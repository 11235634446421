export const bufferToFile = async (
	buffer: string,
	type: string,
	filename: string,
) => {
	const response = await fetch(buffer);
	const blob = await response.blob();
	const file = new File([blob], filename, {
		type: type,
	});

	return file;
};

export const downloadFile = (file: File) => {
	const url = URL.createObjectURL(file);

	const link = document.createElement('a');
	link.href = url;

	link.download = file.name;

	document.body.appendChild(link);

	link.click();

	URL.revokeObjectURL(url);
	document.body.removeChild(link);
};
