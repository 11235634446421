import {
	CheckCircleFilled,
	CloseCircleFilled,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
	StarFilled,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Select, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import LiveView, { LiveViewContentType } from 'src/components/live-view';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
// import { CmsGetAllArticleTypes } from 'src/lib/gql/generated/CmsGetAllArticleTypes';

import {
	MutationDeleteArticle,
	MutationUpdateArticle,
} from 'src/lib/gql/mutations';
import {
	QueryArticleCategories,
	QueryArticleTypes,
	// QueryGetArticleTypes,
	QueryPaginatedArticles,
} from 'src/lib/gql/queries';
import { ArticlesTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';
import SelectTags from 'src/components/ui/select-tags';
import useQueryParams from 'src/hooks/use-query';
import {
	CmsDeleteArticleMutation,
	CmsDeleteArticleMutationVariables,
	CmsGetArticleCategoriesQuery,
	CmsGetArticleTypesQuery,
	CmsGetPaginatedArticlesQuery,
	CmsGetPaginatedArticlesQueryVariables,
	CmsUpdateArticleMutation,
	CmsUpdateArticleMutationVariables,
} from 'src/lib/generated/_generated';
import { downloadFileArticlesList } from 'src/lib/axios';

import DatePicker from 'src/components/ui/date-picker';

const News = () => {
	const query = useQueryParams();

	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [articleTitle, setArticleTitle] = useState<string>('');
	const [selectedHTags, setSelectedHTags] = useState<string[]>(
		query.get('tags')?.split('_') || [],
	);
	const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
	const [selectedTypeId, setSelectedTypeId] = useState<string>();
	const [selectedFromDate, setSelectedFromDate] = useState<string | null>(null);
	const [selectedToDate, setSelectedToDate] = useState<string | null>(null);

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<
		CmsGetPaginatedArticlesQuery,
		CmsGetPaginatedArticlesQueryVariables
	>(QueryPaginatedArticles, {
		variables: {
			pageNumber: pageNumber,
			perPage: perPage,
			title: articleTitle,
			articleHTags: selectedHTags,
			...(selectedCategoryId && {
				articleCategoryId: selectedCategoryId,
			}),
			...(selectedTypeId && {
				articleTypeId: selectedTypeId,
			}),
		},
	});

	const { data: qryTypes } = useQuery<CmsGetArticleTypesQuery>(
		QueryArticleTypes,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	const { data: qryCategories } = useQuery<CmsGetArticleCategoriesQuery>(
		QueryArticleCategories,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	const [DeleteNews] = useMutation<
		CmsDeleteArticleMutation,
		CmsDeleteArticleMutationVariables
	>(MutationDeleteArticle, {
		onError(error) {
			CustomNotification({
				pageName: 'News/Articles',
				pagePrefix: 'Article',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteArticle = useCallback(
		async (id: string) => {
			if (!confirm('Confirm delete?')) return;
			const rsp = await DeleteNews({
				variables: {
					id: id,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'News/Articles',
					pagePrefix: 'Article',
					notificationType: 'deleted',
				});

				refetchQuery();
			}
		},
		[qryData?.pagination?.articles?.result],
	);

	const [UpdateNews] = useMutation<
		CmsUpdateArticleMutation,
		CmsUpdateArticleMutationVariables
	>(MutationUpdateArticle, {
		onError(error) {
			CustomNotification({
				pageName: 'News/Articles',
				pagePrefix: 'Article',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateArticle = useCallback(
		async (
			id: string,
			enabled: boolean | undefined,
			pos: number | null | undefined,
			featured: boolean | undefined,
		) => {
			const rsp = await UpdateNews({
				variables: {
					id: id,
					enabled: enabled,
					weight: pos,
					featured: featured,
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'News/Articles',
					pagePrefix: 'Article',
					notificationType: 'updated',
				});

				refetchQuery();
			}
		},
		[qryData?.pagination?.articles?.result],
	);

	const p_Articles = useMemo(() => {
		return qryData?.pagination?.articles?.result?.map((article, i) => ({
			id: article?.id || '-1',
			key: (i + 1).toString() || '',
			date: format(parseISO(article?.date), 'LLL dd-yyyy'),
			tags: [],
			name: article?.title || '',
			weight: article?.weight || null,
			enabled: article.enabled || false,
			featured: article.featured || false,
			type: article.articleType?.name || '',
			category: article.articleCategory?.name || '',
			counters: article.counters || [],
			slug: article.slug || '',
		}));
	}, [qryData]);

	// Fields
	const columns = [
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => {
				return a.weight - b.weight;
			},

			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updateArticle(id, undefined, weight, undefined);
						}}
					/>
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			ellipsis: true,
			...tableColumnTextFilterConfig<ArticlesTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			width: 100,
			align: 'center',

			sorter: (a, b) => {
				return new Date(a.date).valueOf() - new Date(b.date).valueOf();
			},
			render: (date: string) => date,
		},
		{
			title: 'Type',
			dataIndex: 'category',
			width: 100,
			align: 'center',
			filters: qryCategories?.getAllArticleCategories?.map((category) => ({
				text: category.name,
				value: category.id,
			})),
			filterMultiple: false,
			// onFilter: (value, record) => {
			// 	return record.categoryId === value;
			// },
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					<Select
						style={{ width: '100%' }}
						value={selectedKeys[0]}
						onChange={(value) => setSelectedKeys(value ? [value] : [])}
						allowClear
					>
						{qryCategories?.getAllArticleCategories?.map((category) => (
							<Select.Option key={category.id} value={category.id}>
								{category.name}
							</Select.Option>
						))}
					</Select>
					<div style={{ marginTop: 8 }}>
						<Button
							type="primary"
							onClick={() => {
								confirm();
								setSelectedCategoryId(selectedKeys[0]);
							}}
							size="small"
							style={{ width: 90, marginRight: 8 }}
						>
							OK
						</Button>
						<Button
							onClick={() => {
								clearFilters();
								setSelectedCategoryId(undefined);
							}}
							size="small"
							style={{ width: 90 }}
						>
							Reset
						</Button>
					</div>
				</div>
			),
		},
		{
			title: 'Category',
			dataIndex: 'type',
			width: 100,
			align: 'center',
			filters: qryTypes?.getArticleTypes?.map((type) => ({
				text: type.name,
				value: type.id,
			})),
			filterMultiple: false,
			// onFilter: (value, record) => {
			// 	return record.typeId === value;
			// },
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					<Select
						style={{ width: '100%' }}
						value={selectedKeys[0]}
						onChange={(value) => setSelectedKeys(value ? [value] : [])}
						allowClear
					>
						{qryTypes?.getArticleTypes?.map((type) => (
							<Select.Option key={type.id} value={type.id}>
								{type.name}
							</Select.Option>
						))}
					</Select>
					<div style={{ marginTop: 8 }}>
						<Button
							type="primary"
							onClick={() => {
								confirm();
								setSelectedTypeId(selectedKeys[0]);
							}}
							size="small"
							style={{ width: 90, marginRight: 8 }}
						>
							OK
						</Button>
						<Button
							onClick={() => {
								clearFilters();
								setSelectedTypeId(undefined);
							}}
							size="small"
							style={{ width: 90 }}
						>
							Reset
						</Button>
					</div>
				</div>
			),
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
				{
					text: 'Featured',
					value: 'Featured',
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => {
				if (value === 'Featured') return record.featured;
				else return record.enabled === value;
			},
			render: (props: {
				enabled: boolean;
				id: string;
				slug: string;
				featured: boolean;
			}) => {
				const { enabled, id, slug, featured } = props;

				return (
					<Space>
						<LiveView type={LiveViewContentType.NEWS} url={slug} />
						<StarFilled
							onClick={() => {
								updateArticle(id, undefined, undefined, !featured);
							}}
							style={{
								color: featured ? 'var(--highlighted)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
							title="Featured?"
						/>
						<Link to={`${ROUTES.EDIT_NEWS.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateArticle(id, !enabled, undefined, undefined);
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteArticle(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
		{
			title: 'Page view',
			width: 80,
			align: 'center',
			render: ({ counters }) => {
				return <p>{counters[0]?.value || 0}</p>;
			},
		},
	];

	return (
		<Row justify={'space-between'}>
			<Row>
				<Text className="sub-header">News / Articles</Text>
			</Row>

			<Row>
				<Row>
					<Col>
						<Button
							onClick={() => {
								downloadFileArticlesList({
									endpoint: '/csv-export/articles',
									body: {
										title: articleTitle,
										articleTypeId: selectedTypeId,
										articleHTags:
											selectedHTags && selectedHTags.length > 0
												? selectedHTags
												: null,
										articleCategoryId: selectedCategoryId,
										fromDate: selectedFromDate,
										toDate: selectedToDate,
									},
								});
							}}
						>
							Export Aticles Data
						</Button>
					</Col>
					<Col
						style={{
							textAlign: 'right',
							maxWidth: '180px',
						}}
					>
						<DatePicker
							onChange={(value) => {
								setSelectedFromDate(
									value ? format(new Date(value), 'yyyy-MM-dd') : null,
								);
							}}
							placeholder="From Date"
							style={{
								width: 'clamp(10rem, 60% ,100%)',
								marginBottom: '8px',
							}}
						/>

						<DatePicker
							onChange={(value) => {
								setSelectedToDate(
									value ? format(new Date(value), 'yyyy-MM-dd') : null,
								);
							}}
							placeholder="To Date"
							style={{
								width: 'clamp(10rem, 60% ,100%)',
							}}
						/>
					</Col>
				</Row>
			</Row>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Link to={ROUTES.CREATE_NEWS.path}>
							<Button className="btn-main">
								Create New Article
								<PlusOutlined
									style={{
										position: 'relative',
										top: '1.5px',
									}}
								/>
							</Button>
						</Link>

						<SelectTags
							style={{
								maxWidth: '300px',
								width: '100%',
							}}
							initialValues={selectedHTags}
							onSelectionChange={(tags: string[]) => {
								setSelectedHTags(tags);
							}}
							onInit={(tags) => setSelectedHTags(tags)}
						/>
					</div>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable<ArticlesTable>
						data={p_Articles as ArticlesTable[]}
						columnData={columns}
						other={{
							scroll: {
								x: 500,
							},
						}}
						onChange={(pagination, filters) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);
							setArticleTitle(filters?.name?.toString().toLowerCase() || '');
						}}
						pagination={{
							total:
								qryData?.pagination?.articles?.paginationInfo?.totalResult || 0,
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default News;
