import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';

import { MutationApprovePhotographer } from 'src/lib/gql/mutations';
import { QueryGetUsers } from 'src/lib/gql/queries';
import {
	OffersTable,
	PhotographerRequestTable,
	UsersTable,
} from 'src/lib/interface';
import InfoTable from '../components/info-table';
import {
	CmsApprovePhotographerMutation,
	CmsApprovePhotographerMutationVariables,
	CmsGetPaginatedUsersQuery,
	CmsGetPaginatedUsersQueryVariables,
	Role,
} from 'src/lib/generated/_generated';

const PhotographerRequests = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetPaginatedUsersQuery, CmsGetPaginatedUsersQueryVariables>(
		QueryGetUsers,
		{
			variables: {
				type: Role.PhotographerPending,
				pageNumber: pageNumber,
				perPage: perPage,
				showAll: true,
			},
		},
	);

	const [approvePhotographer] = useMutation<
		CmsApprovePhotographerMutation,
		CmsApprovePhotographerMutationVariables
	>(MutationApprovePhotographer, {
		onError(error) {
			CustomNotification({
				pageName: 'Photographer Request',
				pagePrefix: 'Photographer',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const p_Users: PhotographerRequestTable[] = [];

	qryData?.pagination?.users?.result?.forEach((user, i) => {
		return p_Users.push({
			id: user?.id || '',
			about: user?.about || '',
			key: (i + 1).toString(),
			name: `${user?.fName || ''} ${user?.lName || ''}`,
			dob: user?.dob || '',
			email: user?.email || '',
			enabled: user?.enabled || false,
			gender: user?.gender || '',
			userType: user?.userType || '',
			createDateTime: user?.createDateTime || '',
			cameraType: user?.photographerInfo?.cameraType || '',
			drive: user?.photographerInfo?.drive || false,
			freeTime: user?.photographerInfo?.freeTime || '',
			phone: user?.phone || '',
			photographerRequestId: user?.photographerInfo?.id || '',
		});
	});

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<OffersTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Gender',
			dataIndex: 'gender',
			key: 'gender',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Male',
					value: 'male',
				},
				{
					text: 'Female',
					value: 'female',
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => {
				return record.gender.toLowerCase() === value.toLowerCase();
			},
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: 200,
			...tableColumnTextFilterConfig<UsersTable>(),
			onFilter: (value, record) => {
				return record.email
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Date of Birth',
			dataIndex: 'dob',
			key: 'dob',
			align: 'center',
			width: 200,
		},
	];

	return (
		<Row>
			<Col
				span={24}
				style={{
					marginBottom: '2rem',
				}}
			>
				<Text
					className="sub-header"
					style={{
						fontSize: 'clamp(16px, 1vw, 1.1vw)',
					}}
				>
					Users
				</Text>
			</Col>

			<Col span={24}>
				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Users}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);
						}}
						pagination={{
							total:
								qryData?.pagination?.users?.paginationInfo?.totalResult || 0,
						}}
						expandable={{
							expandedRowRender: (ph: PhotographerRequestTable) => {
								return (
									<>
										<Card>
											<p>Camera : {ph.cameraType}</p>
											<p>Drive : {ph.drive ? 'DRIVE' : 'NO DRIVE'}</p>
											<p>Free : {ph.freeTime}</p>
											<Button
												onClick={async () => {
													if (!confirm('Confirm approve?')) return;
													const rsp = await approvePhotographer({
														variables: {
															id: ph.id,
														},
													});
													if (rsp.data) {
														CustomNotification({
															pageName: 'Photographer Request',
															pagePrefix: 'Request',
															notificationType: 'custom-success',
															customDescription: 'approved',
														});

														refetchQuery();
													}
												}}
												type="primary"
											>
												Approve
											</Button>
										</Card>
									</>
								);
							},
						}}
						other={{
							scroll: {
								x: 300,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default PhotographerRequests;
