import { Button, Col, Input, Row, message } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useState } from 'react';
import { addMonths, differenceInMonths } from 'date-fns';
import DatePicker from 'src/components/ui/date-picker';
import { useLazyQuery } from '@apollo/client';
const { RangePicker } = DatePicker;
import { QueryGetReports, QueryGetReportsToEmail } from 'src/lib/gql/queries';
import { useParams } from 'react-router-dom';
import {
	GetVenueReportToDownloadQuery,
	GetVenueReportToDownloadQueryVariables,
	GetVenueReportToEmailQuery,
	GetVenueReportToEmailQueryVariables,
} from 'src/lib/generated/_generated';
import { bufferToFile, downloadFile } from 'src/utils/fileUtils';

const ReportVenue = () => {
	interface Params {
		id: string;
		[key: string]: string | undefined;
	}
	const params = useParams<Params>();
	const [getReport, { loading }] = useLazyQuery<
		GetVenueReportToDownloadQuery,
		GetVenueReportToDownloadQueryVariables
	>(QueryGetReports);
	const [getReportToEmail, { loading: isLoadingToEmail }] = useLazyQuery<
		GetVenueReportToEmailQuery,
		GetVenueReportToEmailQueryVariables
	>(QueryGetReportsToEmail);
	const [dates, setDates] = useState<Date[]>([]);
	const [email, setEmail] = useState('');

	const generateReport = async () => {
		const [startDate, endDate] = dates;
		if (email) {
			try {
				await getReportToEmail({
					variables: {
						refId: params.id,
						startDate: startDate.toISOString(),
						endDate: endDate.toISOString(),
						email,
					},
				});
				message.success(`Report successfully sent to ${email}.`);
				setEmail('');
				setDates([]);
			} catch {
				message.error('Request failed');
			}
			return;
		}

		try {
			const result = await getReport({
				variables: {
					refId: params.id || '',
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString(),
				},
			});

			if (!result?.data?.getVenueReportToDownload?.buffer) return;

			const { buffer, fileName } = result?.data?.getVenueReportToDownload;

			const file = await bufferToFile(
				`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${buffer}`,
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				fileName as string,
			);

			downloadFile(file);
			message.success(`Report successfully downloaded.`);
		} catch {
			message.error('Request failed');
		}
	};

	const handleDateChange = (dateRange) => {
		if (!dateRange) {
			setDates([]);
			return;
		}
		if (dateRange && dateRange.length === 2) {
			const [startDate, endDate] = dateRange;
			const diffInMonths = differenceInMonths(endDate, startDate);
			if (diffInMonths < 1) {
				const newEndDate = addMonths(startDate, 1);
				setDates([startDate, newEndDate]);
			} else {
				setDates(dateRange);
			}
		} else {
			setDates(dateRange);
		}
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<Text
						className="sub-header"
						style={{ display: 'block', marginBottom: 25 }}
					>
						Report Venue
					</Text>
				</Col>
				<Col>
					<RangePicker
						onChange={handleDateChange}
						value={[dates[0], dates[1]]}
					/>
				</Col>
				<Col
					style={{
						marginLeft: '16px',
					}}
				>
					<Input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email(optional)"
					/>
				</Col>
			</Row>
			<br />

			<Row className="">
				<Col>
					<Button
						disabled={dates.length !== 2 || loading || isLoadingToEmail}
						onClick={generateReport}
					>
						Generate report
					</Button>
				</Col>
			</Row>
		</>
	);
};

export default ReportVenue;
