import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import BestPlacesCard from 'src/components/ui/best-places/best-places-card';
import { ROUTES } from 'src/config/route';
import { BestPlacesForm } from 'src/lib/form-interfaces';
import {
	CmsCreateVenueTypeMutation,
	CmsCreateVenueTypeMutationVariables,
} from 'src/lib/generated/_generated';

import { MutationCreateVenueType } from 'src/lib/gql/mutations';

const CreateBestPlaces = () => {
	const navigate = useNavigate();

	const [CreateBestPlaces] = useMutation<
		CmsCreateVenueTypeMutation,
		CmsCreateVenueTypeMutationVariables
	>(MutationCreateVenueType, {
		onError(error) {
			CustomNotification({
				pageName: 'Best Places',
				pagePrefix: 'Venue Type',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(async (data: BestPlacesForm) => {
		const rsp = await CreateBestPlaces({
			variables: {
				name: data.name,
				hTag: data.hTag,
				slug: data.slug,
				meta: data.meta,
				coverImage: data.coverImageId,
				weight: data.weight ? parseInt(data.weight) : null,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Best Places',
				pagePrefix: 'Venue type',
				notificationType: 'created',
			});

			navigate(
				`${ROUTES.EDIT_BEST_PLACES.path}/${rsp.data.createVenueType?.id}`,
			);
		}
	}, []);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Create Place</Text>
			</Col>

			{/* Props

        bestMedia?: string; // Best places Image
        bestName?: string; // Best places Name
        bestPosition?: number; //Position of Best places
        bestTag?: string; // Best places - City Tags
        bestSlug?: string; // Best places - Slug URL
        bestMetaDescription?: string; // City - Meta Description
        canDelete?: boolean; // Can Delete
        onFormSubmit?: React.MouseEventHandler<HTMLElement>; //On Save Click
        onDelete?: React.MouseEventHandler<HTMLElement>; // On Delete Click
      */}
			<BestPlacesCard onSave={updateQry} />
		</Row>
	);
};

export default CreateBestPlaces;
