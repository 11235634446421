import { getApp } from '@firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export const getToken = async (): Promise<string | null> => {
	const app = getApp();
	const auth = getAuth(app);
	if (auth) {
		const user = auth.currentUser;
		if (user) {
			try {
				const token = await user.getIdToken();
				return token;
			} catch {
				return null;
			}
		}
	}
	return null;
};

export const watchAuth = (updateFn) => {
	const app = getApp();
	const auth = getAuth(app);

	auth.onAuthStateChanged(async (user) => {
		if (user) {
			const token = await getToken();
			updateFn(token);
		} else {
			localStorage.removeItem('token');
			updateFn(null);
		}
	});
};

export const watchToken = (updateFn) => {
	const app = getApp();
	const auth = getAuth(app);
	if (auth)
		auth.onIdTokenChanged(async (user) => {
			if (user) {
				const token = await getToken();
				updateFn(token);
			} else {
				localStorage.removeItem('token');
				updateFn(null);
			}
		});
};

export const logoutUser = async () => {
	const auth = getAuth();
	await auth.signOut();
	localStorage.removeItem('token');
	localStorage.removeItem('authUser');
};

export const loginUser = async (credentials: {
	email: string;
	password: string;
}) => {
	const auth = getAuth();
	const data = await signInWithEmailAndPassword(
		auth,
		credentials.email,
		credentials.password,
	);
	const token = (await data.user?.getIdToken()) || null;
	if (token) {
		localStorage.setItem('token', token);
	} else localStorage.removeItem('token');
};
