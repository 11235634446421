import { notification } from 'antd';
import { NOTIFICATION } from 'src/lib/localization';

interface NotificationProps {
	pageName: string;
	pagePrefix?: string;
	errorName?: string;
	customDescription?: string;
	notificationType:
		| 'updated'
		| 'deleted'
		| 'created'
		| 'error'
		| 'custom-success'
		| 'custom-error';
}

export const CustomNotification = ({
	pageName,
	pagePrefix,
	errorName,
	notificationType,
	customDescription,
}: NotificationProps) => {
	switch (notificationType) {
		case 'updated': {
			return notification['success']({
				message: NOTIFICATION.formatString(NOTIFICATION.title, {
					pageName: `${pageName}`,
				}),
				description: NOTIFICATION.formatString(NOTIFICATION.updated, {
					pagePrefix: `${pagePrefix}`,
				}),
				duration: 2,
			});
		}
		case 'created': {
			return notification['success']({
				message: NOTIFICATION.formatString(NOTIFICATION.title, {
					pageName: `${pageName}`,
				}),
				description: NOTIFICATION.formatString(NOTIFICATION.created, {
					pagePrefix: `${pagePrefix}`,
				}),
				duration: 2,
			});
		}
		case 'deleted': {
			return notification['success']({
				message: NOTIFICATION.formatString(NOTIFICATION.title, {
					pageName: `${pageName}`,
				}),
				description: NOTIFICATION.formatString(NOTIFICATION.deleted, {
					pagePrefix: `${pagePrefix}`,
				}),
				duration: 2,
			});
		}

		case 'error': {
			return notification['error']({
				message: NOTIFICATION.formatString(NOTIFICATION.title, {
					pageName: `${pageName}`,
				}),
				description: NOTIFICATION.formatString(NOTIFICATION.error, {
					pageError: `${errorName}`,
				}),
				duration: 10,
			});
		}

		case 'custom-success': {
			return notification['success']({
				message: NOTIFICATION.formatString(NOTIFICATION.title, {
					pageName: `${pageName}`,
				}),
				description: `${customDescription}`,
				duration: 2,
			});
		}

		case 'custom-error': {
			return notification['error']({
				message: NOTIFICATION.formatString(NOTIFICATION.title, {
					pageName: `${pageName}`,
				}),
				description: `${customDescription}`,
				duration: 10,
			});
		}

		default: {
			return;
		}
	}
};
